<template>
  <div class="c-config-input-type u-block">
    <div class="c-config-input-info c-config-input-info-full u-margin-0">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
    </div>

    <md-field class="u-margin-0">
      <md-textarea
        :value="input.value"
        :disabled="disabled"
        :name="input.key"
        type="text"
        @input="setValue($event)"
      />
    </md-field>
  </div>
</template>

<script>
export default {
  name: 'TextAreaFormField',
  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  methods: {
    setValue($event) {
      this.$emit('update', {
        value: $event,
      })
    },
  }
};
</script>
