import { apiService } from '../api'
import { replacePathParams } from '@/helpers/urlHelpers'

const ENDPOINTS = {
  TOOLTIPS_ALL_SPORTS: '/tooltips',
  TOOLTIPS_SINGLE_SPORT: '/tooltips/:id',
  TOOLTIPS_WEBSITE: '/tooltips/website',
  TOOLTIPS_DELETE: '/tooltips/delete-tooltips',
  TOOLTIPS_UPLOAD: '/tooltips/import',
  SPORTS: '/sports',
  CHECK_TOOLTIP_AVAILABLE: '/tooltips/check-tooltip-phrase-key',
}

class TooltipsService {
  async getSingleSportTooltipsBySportId(id) {
    return apiService.get(replacePathParams(ENDPOINTS.TOOLTIPS_SINGLE_SPORT, { id }));
  }

  async getAllSportsWithTooltips(limit = 200) {
    return apiService.get(`${ENDPOINTS.TOOLTIPS_ALL_SPORTS}?limit=${limit}`);
  }

  async create(data) {
    return apiService.post(ENDPOINTS.TOOLTIPS_ALL_SPORTS, data);
  }

  async update(id, data) {
    return apiService.patch(replacePathParams(ENDPOINTS.TOOLTIPS_SINGLE_SPORT, { id }), data);
  }

  async deleteTooltips(data) {
    return await apiService.post(ENDPOINTS.TOOLTIPS_DELETE, data);
  }

  async delete(id) {
    return apiService.delete(replacePathParams(ENDPOINTS.TOOLTIPS_SINGLE_SPORT, { id }));
  }

  async getSports() {
    return await apiService.get(ENDPOINTS.SPORTS);
  }

  async toggleTooltipSportForWebsite(data) {
    return await apiService.post(ENDPOINTS.TOOLTIPS_WEBSITE, data);
  }

  async uploadTooltips(data) {
    return await apiService.upload(`${ENDPOINTS.TOOLTIPS_UPLOAD}`, data);
  }

  async checkTooltipAndTranslationAvailable(phraseKey) {
    return apiService.get(`${ENDPOINTS.CHECK_TOOLTIP_AVAILABLE}?key=${phraseKey}`);
  }
}

export const tooltipsService = new TooltipsService();
