<template>
  <base-modal-component>
    <template v-slot:header>
      <span class="md-subheading u-typography-color">
        <div>{{ alertMessage }}</div>
      </span>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Ok
        </generic-button>
      </div>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../modal/BaseModalComponent';
import GenericButton from "../../shared/buttons/GenericButton";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    propData: {
      reqired: true,
      default: 1
    },
    pageData: {
      required: false,
      type: Object,
      default: null
    },
    getDataFunction: {
      required: false,
      type: Function,
      default: () => {}
    },
    alertMessage: {
      required: false,
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
