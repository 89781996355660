import { apiService } from '../api'

const ENDPOINTS = {
  ONLINE_WORDS: '/online-word',
}

class WordsService {
  async getOnlineAll(page, limit, section, term) {
    return await apiService.get(ENDPOINTS.ONLINE_WORDS + `?page=${page}&limit=${limit}&section=${section}&term=${term}`);
  }

  async deleteOnline(id) {
    return await apiService.delete(ENDPOINTS.ONLINE_WORDS + `/${id}`);
  }
}
export const wordService = new WordsService()
