<template>
  <h3>Redirections component is under way</h3>
</template>

<script>

export default {
  name: "Redirections",

  props: {
  },

  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
