<template>
  <div class="l-main">
    <div class="l-main__ctn">
      <div class="md-layout u-gap-20 md-layout-shadow">
        <div class="md-layout-item">
          <md-field>
            <label>Choose file:</label>
            <md-file
              :ref="file"
              :disabled="isSubmitting"
              accept=".csv"
              @md-change="handleFileSelect($event)"
            />
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label>Select language</label>
            <md-select v-model="currentLanguageId" :disabled="isSubmitting">
              <md-option
                v-for="language in languages"
                :key="language.id"
                :value="language.id"
              >
                {{ language.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item c-progress-spinner">
          <md-progress-spinner v-if="isSubmitting" :md-diameter="30" md-mode="indeterminate" />

          <template v-if="!isSubmitting">
            <generic-button
              v-if="!isSubmitting"
              variation="red"
              :disabled="!file || !currentLanguageId"
              @click="handleImportSeoRules()"
            >
              Import
            </generic-button>
          </template>
        </div>
       

        <p v-if="error && error.messages" class="md-error">
          {{ error.messages }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { seoRulesService } from '@/services/seo-rules'
import { responseService } from '@/services/response'
import GenericButton from '@/shared/buttons/GenericButton'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "ImportSeoRulesComponent",

  components: {
    GenericButton,
  },

  props: {
    website: {
      type: Object,
      default: null,
    },

    languages: {
      type: Array,
      required: true
    },

    defaultLanguageId: {
      type: Number,
      required: false,
      default: null
    },
  },

  data() {
    return {
      currentLanguageId: null,
      file: null,
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters("response", ["success", "error"]),
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    handleFileSelect(file) {
      this.file = file[0]
    },

    async handleImportSeoRules() {
      this.isSubmitting = true;
      try {
        await seoRulesService.import(this.website.id, this.currentLanguageId, this.file);
        this.handleSuccess({message: 'Successfully updated SEO rule'});
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .md-layout-shadow {
    box-shadow: 4px 3px 6px -6px $grey-dark-01;
  }

  .c-progress-spinner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
  }
</style>
