<template>
  <div class="c-config-input-type c-config-input-type--column">
    <div class="c-config-input-info">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
    </div>

    <div class="c-table-wrapper c-table--online-websites c-config-column-content">
      <base-table-component :prop-data="tableData" :is-ticket-banner="true" draggable @dragSort="handleDragSort">
        <template v-slot:action>
          <div class="header-table-section">
            Banners
          </div>
          <div class="add-button">
            <div>
              List of banners
            </div>
            <div class="add-section">
              <img
                src="../../assets/add-button.svg"
                class="add-button__new"
                alt=""
                @click="createOrUpdateTicketBannerItem()"
              ><span>Add new banner</span>
            </div>
          </div>
        </template>
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              icon
              transparent
              text-inherit
              :disabled="disabled"
              @click="createOrUpdateTicketBannerItem(slotProps.selected.ticketBannerItem)"
            >
              <md-tooltip md-direction="top"> Edit </md-tooltip>
              <img src="../../assets/edit-button.svg" alt="">
            </generic-button>
          </span>

          <span class="c-table__btn-wrap">
            <generic-button
              icon
              transparent
              text-inherit
              :disabled="disabled"
              @click="openDeleteModal(slotProps.selected.ticketBannerItem)"
            >
              <md-tooltip md-direction="top"> Delete </md-tooltip>
              <img src="../../assets/delete-button.svg" alt="">
            </generic-button>
          </span>
        </template>
      </base-table-component>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import GenericButton from '../buttons/GenericButton'
import BaseTableComponent from '@/shared/table/BaseTableComponent'
import CreateTicketBannerItemModal from './modals/ticket-banner'
import DeleteModalComponent from '@/shared/delete-modal/DeleteModalComponent'
import forEach from 'lodash/forEach'

export default {
  name: 'TicketBannerFormField',

  components: {
    GenericButton,
    BaseTableComponent,
  },

  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    languages: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      inputValues: [],
    }
  },

  computed: {
    defaultInputValue() {
      try {
        return JSON.parse(get(this.input, 'defaultValue', []))
      } catch (e) {
        return []
      }
    },

    tableData() {
      const ticketBannerItems = [];
      this.inputValues.forEach(inputValue => {
        ticketBannerItems.push({
          ticketBannerItem: inputValue,
          rowData: {
            'Banner name': inputValue.default.bannerName,
            'Image path': inputValue.default.imagePath,
          }
        });
      });

      return ticketBannerItems;
    }
  },

  created() {
    this.inputValues = get(this.input, 'value', this.defaultInputValue) || []
  },

  methods: {
    createOrUpdateTicketBannerItem(ticketBannerItem) {
      if (!ticketBannerItem) {
        ticketBannerItem = {
          default: {
            bannerName: '',
            bannerUrl: '',
            isInternalUrl: false,
            imagePath: ''
          }
        }
        forEach(this.languages, lang => ticketBannerItem[lang] = {
          bannerUrl: '', isInternalUrl: false, imagePath: ''
        })
      }

      forEach(this.languages, lang => {
        if (ticketBannerItem[lang]) {
          return
        }
        ticketBannerItem[lang] = {
          bannerUrl: '', isInternalUrl: false, imagePath: ''
        }
      })

      this.$modal.show(
        CreateTicketBannerItemModal,
        {
          ticketBannerItem,
          successFunction: this.addOrUpdateTicketBannerItem,
          languages: this.languages
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: selected.default.bannerName,
          propData: selected.default.imagePath,
          deleteFunction: this.deleteTicketBannerItem,
        },
        { height: "auto", scrollable: false, width: "400px" }
      );
    },

    addOrUpdateTicketBannerItem(ticketBannerItem) {
      const oldTicketBannerItemIndex = this.inputValues.findIndex(
        inputValue => inputValue.default.imagePath === ticketBannerItem.default.imagePath);

      if (oldTicketBannerItemIndex !== -1) {
        this.inputValues.splice(oldTicketBannerItemIndex, 1, ticketBannerItem);
      } else {
        this.inputValues.push(ticketBannerItem);
      }

      this.setValue();
    },

    async deleteTicketBannerItem(imagePath) {
      this.inputValues = this.inputValues.filter(inputValue => inputValue.default.imagePath !== imagePath);
      this.setValue();
    },

    setValue() {
      this.$emit('update', {
        value: JSON.stringify(this.inputValues),
        submitForm: true
      });
    },

    handleDragSort(data) {
      this.inputValues = data.map(tableItem => tableItem.ticketBannerItem);

      this.setValue();
    }
  },
}
</script>
<style lang="scss">
.c-table__cell {
  width: 25% !important;
}

.c-table__cell:first-child {
  width: 200px !important;
}
</style>
