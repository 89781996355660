function getInitialState() {
    return {
      websites: []
    }
  }
  
  const state = getInitialState()
  
  const getters = {
    websites: state => state.websites
  }
  
  const mutations = { }
  
  export const website = {
    namespaced: true,
    state,
    mutations,
    getters
  }
  