<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled"
            @click="goToHome"
          > 
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li
          class="c-breadcrumbs__item"
          @click="goToCompanies"
        >
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Companies</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">{{ companyName }}</a>
        </li>
      </ul>
    </div>

    <div class="l-main__ctn u-box">
      <div class="u-flex-row-end u-ph-md">
        <div class="c-search u-ml-auto u-mr-md">
          <md-field
            md-inline
            class="u-width-m u-mb-xs"
          >
            <label>Search</label>
            <md-input
              v-model="term"
              @keydown.enter="searchMarketsByTerm"
            />
            <span @click="searchMarketsByTerm()">
              <md-icon>search</md-icon>
            </span>
          </md-field>
        </div>

        <generic-button
          variation="red"
          @click="fetchMarketsFromRest"
        >
          Update markets
        </generic-button>
      </div>
      <div class="c-table-wrapper">
        <base-table-component :prop-data="filteredMarkets">
          <template v-slot:default="slotProps">
            <generic-button
              icon
              transparent
              @click="goToBetshops(slotProps.selected.rowData.id)"
            >
              <md-tooltip md-direction="top">
                Go to betshops
              </md-tooltip>
              <md-icon>arrow_forward_ios</md-icon>
            </generic-button>
          </template>
        </base-table-component>
      </div>

      <template v-if="!filteredMarkets.length && isFetchedMarkets">
        <md-empty-state
          md-icon="announcement"
          md-label="No Data"
        />
      </template>

      <paginate
        v-if="shouldShowPagination"
        :page-count="getMarketsPaginationData.pageCount"
        :page-range="limitPerPage"
        :prev-text="'<'"
        :next-text="'>'"
        :force-page="currentPage"
        :click-handler="getDataPerPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import GenericButton from '../../shared/buttons/GenericButton';

export default {
  components: {
    BaseTableComponent,
    GenericButton,
  },

  data() {
    return {
      currentPage: 1,
      limitPerPage: 10,
      term: '',
      defaultValues: {
        currentPage: 1,
        limitPerPage: 10
      }
    };
  },

  computed: {
    ...mapGetters('company', [
      'getFilteredMarketsForTable',
      'getMarketsPaginationData',
    ]),
    ...mapState('company', ['isFetchedMarkets', 'companyName']),

    filteredMarkets() {
      return this.getFilteredMarketsForTable(this.$route.params.companyId);
    },

    shouldShowPagination() {
      return this.getMarketsPaginationData.totalItems > this.limitPerPage;
    },
  },

  async created() {
    await this.fetchMarkets(this.currentPage, this.limitPerPage, this.term);
    await this.getCompanyBredCrumps(this.$route.params.companyId);
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'betshops') {
      this.SET_COMPANY_NAME(null);
    }

    next();
  },

  methods: {
    ...mapActions('company', [
      'getMarkets',
      'getMarketsFromRest',
      'getCompanyBredCrumps',
    ]),
    ...mapMutations('company', ['SET_COMPANY_NAME']),

    async fetchMarkets(page, limit, term) {
      await this.getMarkets({
        page,
        limit,
        term,
        companyId: this.$route.params.companyId,
      });
    },

    goToBetshops(marketId) {
      this.$router.push({
        name: 'betshops',
        params: { companyId: this.$route.params.companyId, marketId: marketId },
      });
      this.getCompanyBredCrumps(this.$route.params.companyId);
    },

    goToHome() {
      return this.$router.push({ name: 'home' });
    },

    goToCompanies() {
      this.$router.push({ name: 'companies' });
      this.SET_COMPANY_NAME(null);
    },

    getDataPerPage(
      currentPage = this.defaultValues.currentPage,
      limit = this.defaultValues.limitPerPage,
      term = this.term
    ) {
      this.currentPage = currentPage;
      this.fetchMarkets(currentPage, limit, term);
    },

    async fetchMarketsFromRest() {
      await this.getMarketsFromRest(this.$route.params.companyId);
    },

    searchMarketsByTerm() {
      this.getDataPerPage(
        this.defaultValues.currentPage,
        this.defaultValues.limitPerPage,
        this.term
      );
    },
  },
};
</script>
