<template>
  <div class="md-layout md-gutter u-p-lg">
    <div class="md-layout-item md-size-15">
      <md-checkbox v-model="exportFields.metaTitle">
        Meta Title
      </md-checkbox>
      <md-checkbox v-model="exportFields.metaDescription">
        Meta Description
      </md-checkbox>
      <md-checkbox v-model="exportFields.pageTitle">
        H1 Page Title
      </md-checkbox>
      <md-checkbox v-model="exportFields.content">
        Seo Content
      </md-checkbox>

      <div class="l-seo-export__submit-button">
        <generic-button
          v-if="!isSubmitting"
          variation="red"
          @click="handleExportSeo"
        >
          Export
        </generic-button>
        <md-progress-spinner v-if="isSubmitting" :md-diameter="40" md-mode="indeterminate" />
      </div>
    </div>
    <div class="md-layout-item">
      <md-field class="u-width-m u-mb-xs">
        <label>Language</label>
        <md-select id="customPage" v-model="selectedLanguageId" md-dense>
          <md-option
            v-for="language in languages"
            :key="language.code"
            :value="language.id"
          >
            {{ language.name }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div class="md-layout-item">
      <md-field class="u-width-m u-mb-xs">
        <label>Category</label>
        <md-select v-model="selectedCategory" md-dense @md-selected="handleCategorySelect">
          <md-option
            v-for="category in categories"
            :key="category.value"
            :value="category.value"
          >
            {{ category.label }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div class="md-layout-item">
      <md-field v-if="displaySportPicker" class="u-width-m u-mb-xs">
        <label>Sports</label>
        <md-select v-model="selectedSportId" md-dense @md-selected="handleSportSelect">
          <md-option
            v-for="sport in sports"
            :key="sport.name"
            :value="sport.id"
          >
            {{ sport.name }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div class="md-layout-item">
      <md-field v-if="displayRegionPicker" class="u-width-m u-mb-xs">
        <label>Region</label>
        <md-select v-model="selectedRegionId" md-dense>
          <md-option
            v-for="region in regions"
            :key="region.name"
            :value="region.id"
          >
            {{ region.name }}
          </md-option>
        </md-select>
      </md-field>
    </div>
  </div>
</template>

<script>
import { seoRulesService } from '@/services/seo-rules'
import GenericButton from '@/shared/buttons/GenericButton'
import { store } from '@/store/store'
import moment from 'moment'

export default {
  name: "ExportSeoRulesComponent",

  components: {
    GenericButton,
  },

  props: {
    website: {
      type: Object,
      default: null,
    },

    languages: {
      type: Array,
      required: true
    },

    defaultLanguageId: {
      type: Number,
      required: false,
      default: null
    },
  },

  data() {
    return {
      selectedLanguageId: this.languages[0].id,
      selectedCategory: 'all',
      selectedSportId: -1,
      selectedRegionId: -1,
      categories: [
        { label: 'All', value: 'all' },
        { label: 'Sports', value: 'sport' },
        { label: 'Regions', value: 'region' },
        { label: 'Leagues', value: 'league' },
        { label: 'Pages', value: 'page' },
        { label: 'Casino Pages', value: 'casino-category' },
        { label: 'Templates', value: 'template' },
      ],
      exportFields: {
        metaTitle: true,
        metaDescription: true,
        pageTitle: true,
        content: true
      },
      sports: [],
      regions: [],
      isSubmitting: false,
    };
  },

  computed: {
    displaySportPicker() {
      // TODO: When we get additional info how exactly should sport and region exports work, uncomment this and implement sport and region exporting on API
      // return ['sport', 'region', 'league'].includes(this.selectedCategory);
      return false;
    },

    displayRegionPicker() {
      // TODO: When we get additional info how exactly should sport and region exports work, uncomment this and implement sport and region exporting on API
      // return ['region', 'league'].includes(this.selectedCategory) && this.selectedSportId >= 0;
      return false;
    }
  },

  methods: {
    async handleCategorySelect() {
      // TODO: When we get additional info how exactly should sport and region exports work, uncomment this and implement sport and region exporting on API
      // async handleCategorySelect(category) {
      // if (['sport', 'region', 'league'].includes(category)) {
      //   await this.fetchSports();
      // }
      //
      // this.selectedSportId = -1;
      // this.selectedRegionId = -1;
    },

    handleSportSelect(sportId) {
      this.selectedRegionId = -1;

      if (!this.displayRegionPicker) {
        return;
      }

      this.fetchRegions(sportId)
    },

    async fetchSports() {
      if (this.sports.length) {
        return;
      }

      this.sports = [{ id: -1, name: 'All' }];
      const sportsResponse = await seoRulesService.getSports();
      this.sports.push(...sportsResponse.data);
    },

    async fetchRegions(sportId) {
      this.regions = [{ id: -1, name: 'All' }];
      const regionsResponse = await seoRulesService.getRegions(sportId);
      this.regions.push(...regionsResponse.data);
    },

    async handleExportSeo() {
      this.isSubmitting = true;
      try {
        const response = await seoRulesService.exportSeo(
            this.website.id,
            this.selectedLanguageId,
            this.exportFields,
            this.selectedCategory,
            this.selectedSportId,
            this.selectedRegionId
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.getExportFileName())
        document.body.appendChild(link)
        link.click()
      } catch(e) {
        store.dispatch('response/setGlobalError', 'There are no available SEO rules for given criteria!');
      }

      this.isSubmitting = false;
    },

    getExportFileName() {
      const selectedLanguage = this.languages.find(language => language.id === this.selectedLanguageId);
      let fileName = `seo-export-${this.website.name}-${selectedLanguage.code}`;

      if (this.selectedCategory !== 'all') {
        fileName += `-${this.selectedCategory}`
      }

      if (this.selectedSportId >= 0) {
        const selectedSport = this.sports.find(sport => sport.id === this.selectedSportId);
        fileName += `-${selectedSport.name}`
      }

      if (this.selectedRegionId >= 0) {
        const selectedRegion = this.regions.find(region => region.id === this.selectedRegionId);
        fileName += `-${selectedRegion.name}`
      }

      fileName += '-' + moment().format('YYYYMMDDHHmmss') + '-v4.csv';
      fileName = fileName.replace(' ', '-')


      return fileName.toLowerCase();
    }
  }
}
</script>

<style lang="scss" scoped>
.md-checkbox {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.l-seo-export__submit-button {
  margin-top: $lg !important;
}
</style>
