class PaginationService {
  getPaginationData({itemCount, totalItems, pageCount}) {
    return {
      itemCount,
      totalItems,
      pageCount
    }
  }
}

export const paginationService = new PaginationService();
