<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        {{ createOrUpdate }} Online Configuration
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
        @submit.prevent="validateUser"
      >
        <md-field :class="getValidationClass($v.onlineConfigurationData.key)">
          <label for="name">Key</label>
          <md-input
            v-model="onlineConfigurationData.key"
            v-focus
            name="name"
            type="name"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.onlineConfigurationData.key.required"
            class="md-error"
          >Key is required</span>
        </md-field>
        <error-response-messages :field-name="'key'" />


        <md-field>
          <label>Section</label>
          <md-select id="section" v-model="onlineConfigurationData.sectionId" name="section">
            <md-option
              v-for="section in sections"
              :key="section.id"
              :value="section.id"
            >
              {{ section.name }}
            </md-option>
          </md-select>
        </md-field>

        <md-field>
          <label>Subsection</label>
          <md-select id="subsection" v-model="onlineConfigurationData.subsectionId" name="subsection">
            <md-option
              v-for="subsection in subsections"
              :key="subsection.id"
              :value="subsection.id"
            >
              {{ subsection.name }}
            </md-option>
          </md-select>
        </md-field>

        <md-field>
          <label>Type</label>
          <md-select id="type" v-model="onlineConfigurationData.typeId" name="type">
            <md-option
              v-for="type in types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </md-option>
          </md-select>
        </md-field>

        <md-field :class="getValidationClass($v.onlineConfigurationData.label)">
          <label for="label">Label</label>
          <md-input
            v-model="onlineConfigurationData.label"
            v-focus
            name="label"
            type="label"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.onlineConfigurationData.label.required"
            class="md-error"
          >Label is required</span>
        </md-field>
        <error-response-messages :field-name="'label'" />

        <md-field :class="getValidationClass($v.onlineConfigurationData.description)">
          <label for="label">Description</label>
          <md-input
            v-model="onlineConfigurationData.description"
            v-focus
            name="description"
            type="description"
            class="md-input"
            autocomplete="off"
          />
        </md-field>
        <error-response-messages :field-name="'description'" />

        <md-field :class="getValidationClass($v.onlineConfigurationData.defaultValue)">
          <label for="label">Default Value</label>
          <md-input
            v-model="onlineConfigurationData.defaultValue"
            v-focus
            name="defaultValue"
            type="defaultValue"
            class="md-input"
            autocomplete="off"
          />
        </md-field>
        <error-response-messages :field-name="'defaultValue'" />

        <md-field :class="getValidationClass($v.onlineConfigurationData.values)">
          <label for="label">Values</label>
          <md-textarea
            v-model="onlineConfigurationData.values"
            v-focus
            md-autogrow
            name="values"
            type="values"
            class="md-input"
            autocomplete="off"
          />
        </md-field>
        <error-response-messages :field-name="'values'" />
      </form>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="transparent"
          @click="showDialog = !showDialog"
        >
          Values example
        </generic-button>
      </div>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <div v-if="onlineConfigurationData.role === 2" class="u-mr-xs">
        <generic-button
          variation="red"
          @click="validateUser(true)"
        >
          Create and set permissions
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateUser"
      >
        {{ createOrUpdate }}
      </generic-button>

      <div v-if="showDialog" class="u-margin-top-20">
        <md-tabs>
          <md-tab md-label="String Form Field">
            <p>Default value should be string</p>
            <p>Values are not required for this type of input</p>
          </md-tab>

          <md-tab md-label="Radio Form Field">
            <p>Default value should be string, the key of Values object that will be selected as value for select input</p>
            <p>Values should be JSON object with string keys and values</p>
            <code>{"0": "disabled", "1": "enabled"}</code>
          </md-tab>

          <md-tab md-label="Page Multi Language Html CSS Form Field">
            <p>Default value should be stringified JSON, with following structure:</p>
            <code>{"default":{"sr":{"html":"SR HTML","css":"SR CSS"},"es":{"html":"ES HTML","css":"ES CSS"}},"second page":{"sr":{"html":"SR HTML","css":"SR CSS"},"es":{"html":"ES HTML","css":"ES CSS"}}}</code>
          </md-tab>
        </md-tabs>
      </div>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { validationBaseMixin } from '../../../../../../mixins/ValidationBaseMixin'
import GenericButton from '../../../../../../shared/buttons/GenericButton'
import ErrorResponseMessages from '../../../../../../shared/error-response-messages/ErrorResponseMessages'
import BaseModalComponent from '../../../../../../shared/modal/BaseModalComponent'
import { responseService } from '../../../../../../services/response'
import cloneDeep from 'lodash/cloneDeep'


export default {
  name: 'CreateOnlineConfigurationModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],

  props: {
    onlineConfiguration: {
      type: Object,
      required: true,
    },
    onlineConfigurationId: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      onlineConfigurationData: {},
      showDialog: false,
      messages: {
        error: null,
        success: null,
      },
    };
  },

  computed: {
    ...mapGetters("response", ["success", "error"]),
    ...mapGetters('onlineConfiguration', ['sections', 'subsections', 'types']),

    createOrUpdate() {
      return !this.onlineConfigurationId ? 'Create' : 'Update';
    },
  },

  created() {
    this.onlineConfigurationData = cloneDeep(this.onlineConfiguration)
  },

  validations: {
    onlineConfigurationData: {
      key: {
        required
      },
      label: {
        required
      },
    },
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),
    ...mapActions('onlineConfiguration', ['createOnlineConfiguration', 'updateOnlineConfiguration']),

    async handleCreateOnlineConfiguration() {
      try {
        await this.createOnlineConfiguration(this.onlineConfigurationData);
        this.handleSuccess({ message: "Online Configuration created!" });
        this.close();
      } catch (err) {

        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    async handleUpdateOnlineConfiguration() {
      try {
        await this.updateOnlineConfiguration({ id: this.onlineConfigurationId, updateData: this.onlineConfigurationData });
        this.handleSuccess({ message: "Online Configuration Updated!" });
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        !this.onlineConfigurationId ? this.handleCreateOnlineConfiguration() : this.handleUpdateOnlineConfiguration();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
