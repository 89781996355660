class ThemeService {
  handleThemeToggle(data) {
    localStorage.setItem('nightMode', data);
    this.toggleBodyClass(data);
  }

  isNightMode() {
    return JSON.parse(localStorage.getItem('nightMode'));
  }

  getCurrentTheme() {
    return JSON.parse(localStorage.getItem('nightMode'))
      ? 'meridian-theme-dark'
      : 'meridian-theme-light';
  }

  toggleBodyClass() {
    document.body.classList.toggle('meridian-theme-dark');
    document.body.classList.toggle('meridian-theme-light');
  }
}
export const themeService = new ThemeService();
