<template>
  <div class="c-config-input-type u-block" :class="{'error': invalidField}">
    <div class="c-config-input-info c-config-input-info-full u-margin-0">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
      <div v-if="invalidField" class="error-message">Invalid Json</div>
    </div>

    <md-field class="u-margin-0">
      <md-textarea
        :value="value"
        :disabled="disabled"
        :name="input.key"
        type="text"
        @input="setValue($event)"
      />
    </md-field>
  </div>
</template>

<script>
export default {
  name: 'JsonFormField',
  data: () => {
    return {
      value: '',
      invalidField: false,
    }
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  methods: {
    setValue($event) {
      try {
        JSON.parse($event)
        this.$emit('update', {
          value: $event,
        })
        this.invalidField = false;

      } catch (e) {
        this.$emit('update', {
          isValid: false
        })

        this.invalidField = true;
      }
    },
  },
  mounted() {
    try {
      this.value = JSON.stringify(this.input.value, null, 2);
    } catch (e) {
      this.value = this.input.value;
    }
  }
};
</script>
<style lang="scss">
.error-message {
  color: red;
  padding: 5px 0;
}

</style>

