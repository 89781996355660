import { apiService } from '../api';
import axios from 'axios';
import config from '../../../config/local'

const ENDPOINTS = {
  GET: '/big-jackpot',
  DELETE: '/big-jackpot',
  CREATE: '/big-jackpot',
  UPDATE: '/big-jackpot',
}

const ENDPOINTS_EXTERNAL = {
  FINISH_JACKPOT: '/jackpot/finish-active-jackpot',
}

class BigJackpotService {
  constructor() {
    this.jackpotClient = axios.create({});
  }
  async get() {
    return await apiService.get(ENDPOINTS.GET);
  }

  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(data, id) {
    return await apiService.patch(`${ENDPOINTS.UPDATE}/${id}`, data);
  }

  async finishActiveJackpot() {
    return this.jackpotClient.post(`${config.JACKPOT_URL}${ENDPOINTS_EXTERNAL.FINISH_JACKPOT}`);
  }
}

export const bigJackpotService = new BigJackpotService()
