import { validationMixin } from 'vuelidate'

export const validationBaseMixin = {
  mixins: [ validationMixin ],
  methods: {
    getValidationClass (fieldName) {
      if (!fieldName) {
        return;
      }

      return {
        'md-invalid': fieldName.$invalid && fieldName.$dirty
      }
    },
  }
}