<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ modalTitle }}
        <div class="c-delete-msg__wrapper">
          <span class="c-delete-msg">{{ modalMessage }}</span>
        </div>
        <div v-if="modalMsgAlert">
          {{ modalMsgAlert }}
        </div>
      </h4>
    </template>

    <template v-slot:body>
      <p v-if="modalDescription">
        {{ modalDescription }}
      </p>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="cancel()"
        >
          No
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="accept()"
      >
        Yes
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../modal/BaseModalComponent';
import GenericButton from "../../shared/buttons/GenericButton";
import { mapActions } from "vuex";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    acceptFunction: {
      required: true,
      type: Function
    },
    cancelFunction: {
      required: true,
      type: Function
    },
    modalTitle: {
      required: true,
      type: String,
    },
    modalMessage: {
      required: true,
      type: String,
    },
    modalMsgAlert: {
      required: false,
      type: String,
      default: '',
    },
    modalDescription: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions("response", ["handleSuccess"]),

    cancel() {
      this.cancelFunction();
      this.$emit('close');
    },

    accept() {
      this.acceptFunction()
      this.$emit('close');
    },
  },
};
</script>
