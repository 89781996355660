<template>
  <base-modal-component>
    <template v-slot:header>
      <div class="u-flex-column">
        <h4 class="u-typography-color md-title">
          Import Tooltip Translations
        </h4>
      </div>
      <div class="u-text-align-right">
        <div class="u-flex">
          <generic-button
            variation="grey"
            class="u-mr-md"
            @click="close"
          >
            Close
          </generic-button>
        </div>
      </div>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>

      <base-table-component
        :prop-data="languages"
      >
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              :class="{hidden: showLoader && currentLanguageForImportCSV === slotProps.selected.language.id}"
              icon
              transparent
              text-inherit
              :disabled="showLoader"
            >
              <md-tooltip
                md-direction="top"
              >
                Import CSV
              </md-tooltip>
              <label
                class="u-cursor-pointer"
                for="file-translations"
                @click="handleClickOnUploadBtn(slotProps.selected.language.id)"
              >
                <md-icon>arrow_upward</md-icon>
              </label>
              <input
                id="file-translations"
                ref="file"
                class="md-file-input"
                type="file"
                accept=".csv"
                @change="handleOnFileChange($event)"
              >
            </generic-button>
            <md-progress-spinner
              v-if="showLoader && currentLanguageForImportCSV === slotProps.selected.language.id"
              :md-diameter="40"
              md-mode="indeterminate"
            />
          </span>
        </template>
      </base-table-component>
    </template>
  </base-modal-component>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import GenericButton from '@/shared/buttons/GenericButton';
import BaseModalComponent from '@/shared/modal/BaseModalComponent';
import {languageService} from '@/services/language';
import {paginationService} from '@/services/pagination';
import BaseTableComponent from '@/shared/table/BaseTableComponent.vue';

export default {
  name: 'ImportTranslationsModal',
  components: {
    GenericButton,
    BaseModalComponent,
    BaseTableComponent,
  },

  props: {
    handleSuccessCallback: {
      type: Function,
      required: false,
      default: () => {
      },
    },
  },

  data() {
    return {
      showLoader: false,
      currentPage: 1,
      limitPerPage: 10,
      paginationData: {},
      languages: [],
      openConfirmModal: false,
      file: '',
      currentLanguageForImportCSV: null,
      messages: {
        error: null,
        success: null,
      },
    };
  },

  computed: {
    ...mapGetters('response', ['success', 'error']),
  },

  created() {
    this.fetchCurrentPage();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'handleError', 'resetError']),

    fetchLanguages(page, limit) {
      languageService.getOnLineAllWithPagination(page, limit).then(response => {
        if (response.data.items.length === 0 && this.currentPage > 1) {
          this.fetchCurrentPage(this.currentPage - 1);

          return;
        }
        this.paginationData = paginationService.getPaginationData(response.data);
        this.languages = languageService.formatDataForTable(response.data.items);
      });
    },

    fetchCurrentPage(page = 1, limit = this.limitPerPage) {
      this.currentPage = page;
      this.fetchLanguages(page, limit);
    },

    handleClickOnUploadBtn(id) {
      this.currentLanguageForImportCSV = id;
    },

    async handleOnFileChange(event) {
      const formData = new FormData();
      formData.set('file', event.target.files[0]);
      this.showLoader = true;
      try {
        await languageService.uploadOnlineTooltipsTranslationsFile(formData, this.currentLanguageForImportCSV);
        this.handleSuccess({message: `Translations imported!`});
      } catch (e) {
        this.handleError({ messages: e?.data?.message || 'Error with importing' });
      } finally {
        this.showLoader = false;
      }
    },

    close() {
      this.resetError();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.md-checkbox {
  display: flex;
  align-items: center;
}

.u-mr-md {
  margin-right: $md !important;
}

.c-header__info {
  .c-header__info-label {
    display: inline-block;
    width: 150px;
    color: red;
  }

  .c-header__info-type {
    display: inline-block;
    color: blue;
    text-transform: capitalize;
    font-weight: bold;
    width: 150px;
  }
}

.md-file-input {
  display: none;
}

.custom-file-upload {
  margin: 0;
  cursor: pointer;
  padding: 10px;
}

.md-button-file-upload {
  outline: none;
}

.hidden {
  display: none;
}

.u-cursor-pointer {
  cursor: pointer;
  padding: 30px;
}

</style>
