<template>
  <div class="c-config-input-type u-block">
    <div style="margin-bottom: 10px;" class="c-config-input-info">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
    </div>

    <div style="margin-left: -16px" class="u-grid__4fr c-landing-items">
      <md-card
        v-for="(inputValue, index) in inputValues"
        :key="`${input.id}-${inputValue.pageName}`"
      >
        <md-card-header class="u-flex-justify-between c-landing-item-header">
          <p class="md-title">
            {{ inputValue.pageName }}
          </p>
          <generic-button
            class="u-color-white"
            icon
            transparent
            text-inherit
            :disabled="disabled"
            @click="deletePage(index)"
          >
            <md-tooltip md-direction="top">
              Delete
            </md-tooltip>
            <md-icon style="color: white !important;">
              delete
            </md-icon>
          </generic-button>
        </md-card-header>

        <md-card-content class="c-landing-item-content">
          <md-field>
            <label>Image URL</label>
            <md-input
              v-model="inputValue.imageUrl"
              type="text"
              required
              :disabled="disabled"
              @input="setValue()"
            />
          </md-field>

          <md-field>
            <label>Title key</label>
            <md-input
              v-model="inputValue.titleKey"
              type="text"
              required
              :disabled="disabled"
              @input="setValue()"
            />
          </md-field>

          <md-field>
            <label>Description key</label>
            <md-input
              v-model="inputValue.descriptionKey"
              type="text"
              required
              :disabled="disabled"
              @input="setValue()"
            />
          </md-field>
        </md-card-content>
      </md-card>
    </div>

    <div class="c-landing-input">
      <md-field style="width: 90% !important;">
        <md-select id="customPage" v-model="newPageSelect" name="customPage" :disabled="disabled">
          <md-option
            v-for="page in availableCustomPages"
            :key="page.id"
            :value="page.name"
          >
            {{ page.name }}
          </md-option>
        </md-select>
        <label for="customPage">Page</label>
      </md-field>
      <generic-button
        class="c-landing-btn"
        text-inherit
        transparent
        fab
        variation="red"
        :disabled="addNewPageButtonDisabled"
        @click="addNewLandingPageItem()"
      >
        <md-icon>add</md-icon>
      </generic-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { get, snakeCase, isEmpty } from 'lodash'
import GenericButton from '../buttons/GenericButton'

export default {
  name: 'PageMultiLanguageHtmlCssFormField',

  components: {
    GenericButton,
  },

  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    languages: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      inputValues: [],
      newPageSelect: {}
    }
  },

  computed: {
    ...mapGetters('customPages', ['customPages', 'customPagesFetched']),

    defaultInputValue() {
      try {
        return JSON.parse(get(this.input, 'defaultValue', []))
      } catch (e) {
        return []
      }
    },

    addNewPageButtonDisabled() {
      return isEmpty(this.newPageSelect) || this.disabled
    },

    availableCustomPages() {
      return this.customPages.filter(customPage => {
        return !this.inputValues?.some(inputValue => {
          return inputValue.pageName === customPage.name
        })
      })
    }
  },

  created() {
    this.inputValues = get(this.input, 'value', this.defaultInputValue) || []

    if (!this.customPagesFetched) {
      this.getCustomPages();
    }
  },

  methods: {
    ...mapActions('customPages', ['getCustomPages']),

    addNewLandingPageItem() {
      if (isEmpty(this.newPageSelect)) {
        return;
      }

      this.inputValues.push({
        pageName: this.newPageSelect,
        imageUrl: '',
        titleKey: snakeCase(this.newPageSelect),
        descriptionKey: snakeCase(this.newPageSelect) + '_description',
      })

      this.newPageSelect = {};
      this.setValue();
    },

    setValue() {
      this.$emit('update', {
        value: JSON.stringify(this.inputValues),
        isValid: this.isValid()
      })
    },

    isValid() {
      return this.inputValues.every(inputValue => !isEmpty(inputValue.imageUrl) && !isEmpty(inputValue.titleKey) && !isEmpty(inputValue.descriptionKey));
    },

    deletePage(index) {
      this.inputValues.splice(index, 1);
      this.$emit('update', {
        value: JSON.stringify(this.inputValues),
        isValid: this.isValid()
      })
    },
  },
}
</script>
<style lang="scss" scoped>

.c-landing-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c43d2f;
  padding: 5px 10px !important;

  p {
    margin: 0 !important;
    font-size: 19px !important;
    color: white;
  }
}

.c-landing-item-content {
  padding: 15px;
}

.c-landing-input {
  display: flex;
  justify-content: space-between;
}

.c-landing-items {
  margin-bottom: 10px;
  row-gap:  32px;
}

.c-landing-btn {
  align-self: center;
  justify-self: center
}

</style>
