import axios from 'axios'
import { assign, isEmpty } from 'lodash'
import config from '../../../config/local'
import { authService } from '../auth';

class ApiService {
  constructor() {
    this.setRequestInterceptor()
    this.setResponseInterceptor()
    this.setHeaderForApi()
  }

  async post(url, data) {
    try {
      const response = await axios.post(`${config.BASE_URL}${url}`, data)

      return response
    } catch(error) {
      throw error
    }
  }

  async uploadFile(url, data, cb) {
    try {
      const response = await axios.post(`${config.BASE_URL}${url}`, data, cb)

      return response
    } catch(error) {
      throw error
    }
  }

  async put(url, data) {
    try {
      const response = await axios.put(`${config.BASE_URL}${url}`, data)

      return response
    } catch(error) {
      throw error
    }
  }

  async patch(url, data) {
    try {
      const response = await axios.patch(`${config.BASE_URL}${url}`, data)

      return response
    } catch(error) {
      throw error
    }
  }

  async delete(url) {
    try {
      const response = await axios.delete(`${config.BASE_URL}${url}`)

      return response
    } catch(error) {
      throw error
    }
  }

  async testDelete(url, params) {
    try {
      const response = await axios.delete(`${config.BASE_URL}${url}`, params)

      return response
    } catch(error) {
      throw error
    }
  }

  async get(url, additionalParams = {}) {
    let baseUrl = `${config.BASE_URL}${url}`;
    if (additionalParams && !isEmpty(additionalParams)) {
      baseUrl += '?'
      for (var paramKey in additionalParams) {
        if (additionalParams.hasOwnProperty(paramKey)) {
          baseUrl += `${paramKey}=${additionalParams[paramKey]}&`
        }
      }

      baseUrl = baseUrl.slice(0, -1);
    }

    try {
      const response = await axios.get(`${baseUrl}`)

      return response
    } catch(error) {
      throw error
    }
  }

  async exportCsv(url) {
    try {
      const response = await axios.get(`${config.BASE_URL}${url}`, { responseType: 'arraybuffer' });

      return response;
    } catch (error) {
      throw error
    }
  }

  async upload(url, data) {
    try {
      const response = await axios.post(
        `${config.BASE_URL}${url}`,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      return response
    } catch(error) {
      throw error
    }
  }

  setResponseInterceptor() {
    axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response.status === 401) {
          authService.logoutUserAndRedirectToLogin();

          throw error.response;
        }
        if (error.response) {
          throw error.response;
        }
      }
    )
  }

  setRequestInterceptor() {
    axios.interceptors.request.use(request => {

      if (request.url.includes(config.REST_URL)) {
        return request;
      }

      request.headers.common['Activation-Token'] = localStorage.getItem('activation-token')
      return request;
    })
  }

  setAuthHeaders(token) {
    assign(axios.defaults.headers, {
      'Authorization':  `Bearer ${token}`
    })
  }

  isLoggedIn() {
    return !!localStorage.getItem('token')
  }

  setHeaderForApi() {
    if (!this.isLoggedIn()) {
      return;
    }
    this.setAuthHeaders(localStorage.getItem('token'))
  }
}

export const apiService = new ApiService()
