<template>
  <div style="display: flex; flex-direction: column; min-height: 0; flex: 1; height: 100%;">
    <template v-if="getSelectedDomainId">
      <template v-if="tableData.length">
        <div class="c-table-wrapper l-history-logs__table">
          <base-table-component
            :prop-data="tableData"
          >
            <template v-slot:default="slotProps">
              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="handleOpen(slotProps.selected.historyLog)"
                >
                  <md-tooltip md-direction="top"> Details </md-tooltip>
                  <md-icon>visibility</md-icon>
                </generic-button>
              </span>
            </template>
          </base-table-component>
        </div>
      </template>

      <paginate
        v-if="totalPages > 1"
        v-model="currentPage"
        :page-count="totalPages"
        :prev-text="'<'"
        :next-text="'>'"
        :page-range="limit"
        :click-handler="handlePaginate"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />

      <template v-if="!tableData.length">
        <div class="l-empty-container u-full-height">
          <md-empty-state
            md-icon="announcement"
            md-label="No Data"
          />
        </div>
      </template>
    </template>
    <template v-if="!getSelectedDomainId">
      <div class="l-empty-container u-full-height">
        <md-empty-state
          md-icon="announcement"
          md-label="You must create a domain first"
        />
      </div>
    </template>
  </div>
</template>

<script>

import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import GenericButton from "@/shared/buttons/GenericButton.vue";
import BaseTableComponent from "@/shared/table/BaseTableComponent.vue";
import HistoryLogModal from "./modals/HistoryLogModalComponent.vue";
import {mapActions, mapGetters} from "vuex";
import {historyLogsService} from "@/services/history-logs";
import moment from "moment/moment";

export default {
  name: "HelpHistoryLogsComponent",
  components: {
    BaseTableComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      historyLogs: [],
      totalPages: 1,
      currentPage: 1,
      limit: 100,
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['getSelectedDomainId', 'getSelectedWebsiteId']),

    tableData() {
      const historyLogs = [];

      this.historyLogs.forEach(historyLog => {
        historyLogs.push({
          historyLog: historyLog,
          rowData: {
            'User': historyLog.user.email,
            'Title': `<span class="${ historyLog.type.split('.').pop()}"></span>${historyLog.title}`,
            'Performed': moment(historyLog.createdAt).format('DD/MM/YYYY HH:mm')
          }
        });
      });

      return historyLogs;
    }
  },

  watch: {
    getSelectedDomainId() {
      this.getHistoryLogs();
    }
  },

  created() {
    this.getHistoryLogs();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),

    async getHistoryLogs() {
      if (!this.getSelectedDomainId) {
        return;
      }

      let response = await historyLogsService.getHistoryLogs({
        websiteId: this.getSelectedWebsiteId,
        type: 'help',
        page: this.currentPage,
        limit: this.limit,
      });

      this.historyLogs = response.data.items;
      this.totalPages = response.data.pageCount;
    },

    handleOpen(historyLog) {
      this.$modal.show(
        HistoryLogModal,
        { historyLog: historyLog },
        { height: "auto", width: "80%", scrollable: true }
      );
    },

    handlePaginate(currentPage) {
      this.currentPage = currentPage
      this.getHistoryLogs()
    }
  },
};
</script>

<style lang="scss">
.l-empty-container {
  margin-bottom: 0;
}

.md-empty-state {
  max-width: 80% !important;
  justify-content: flex-start;
}

.l-history-logs__table {
  .c-table__cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      &.reorder:before {
        width: 10px;
        content: '⇅';
        display: inline-block;
        margin-right: 8px;
        color: green;
        font-weight: bold;
      }

      &.delete:before {
        width: 10px;
        content: '❌';
        display: inline-block;
        margin-right: 8px;
        color: red;
      }

      &.duplicate:before {
        width: 10px;
        content: '⧉';
        display: inline-block;
        margin-right: 8px;
        color: blue;
        font-weight: bold;
      }

      &.update:before {
        width: 10px;
        content: '✏️';
        display: inline-block;
        margin-right: 8px;
        color: purple;
        font-weight: bold;
      }

      &.create:before {
        width: 10px;
        content: '✓';
        display: inline-block;
        margin-right: 8px;
        color: green;
        font-weight: bold;
      }
    }
  }
}

</style>
