<template>
  <div>
    <div v-if="!isCreateOrEdit" class="c-table-wrapper">
      <div v-if="hasAvailableWebsites" class="add-button">
        <div class="add-section">
          <div v-if="helpDomains && helpDomains.length" class="c-search">
            <md-field class="u-width-m u-mb-xs u-mr-md">
              <label>Search</label>
              <md-input v-model="searchQuery" />
            </md-field>
          </div>
        </div>
        <div v-if="isCreateDomainEnabled && isAdmin()" class="add-button">
          <div class="add-section">
            <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="openCreateDomain()">
            <span>Add Domain</span>
          </div>
        </div>
      </div>
      <base-table-component v-if="helpDomains !== null && filteredDomains.length " :prop-data="filteredDomains" :action-label="'EDIT DELETE'">
        <template v-slot:default="slotProps">
          <template>
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                @click="openCreateDomain(slotProps.selected)"
              >
                <md-tooltip md-direction="top"> Edit </md-tooltip>
                <img src="../../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>

            <span class="c-table__btn-wrap">
              <generic-button
                :disabled="!isAdmin()"
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected)"
              >
                <md-tooltip md-direction="top"> Delete </md-tooltip>
                <img src="../../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </template>
      </base-table-component>

      <template v-if="helpDomains !== null && !filteredDomains.length">
        <div class="l-empty-container u-full-height">
          <md-empty-state
            md-icon="announcement"
            md-label="No Data"
          />
        </div>
      </template>
    </div>
    <div v-if="isCreateOrEdit">
      <CreateEditSettings
        :domain="selectedDomain"
        :all-websites="allWebsites"
        @close="handleFormClose"
        @success="handleFormSuccess"
      />
    </div>
  </div>
</template>

<script>
import BaseTableComponent from '@/shared/table/BaseTableComponent';
import GenericButton from "../../../../../shared/buttons/GenericButton";
import DeleteModalComponent from "../../../../../shared/delete-modal/DeleteModalComponent";
import CreateEditSettings from "./edit";
import {websiteService} from "@/services/websites";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {helpService} from "@/services/help";
import {mapActions, mapGetters} from "vuex";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";

export default {
  name: "HelpSettingsComponent",

  components: {
    BaseTableComponent,
    GenericButton,
    CreateEditSettings
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],

  data() {
    return {
      searchQuery: '',
      isCreateOrEdit: false,
      allWebsites: [],
      selectedDomain: null,
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['helpDomains']),

    allowedDomains() {
      return this.helpDomains
        ? this.helpDomains.filter(domain => this.canReadHelpWebsite(domain.websiteId))
        : [];
    },

    filteredDomains() {
      const domains = this.searchQuery
        ? this.allowedDomains.filter(domain => {
          return domain.website.name.includes(this.searchQuery)
        })
        : this.allowedDomains;

      return this.formatDomainsTableData(domains);
    },

    isCreateDomainEnabled() {
      return this.allWebsites.some(allowedWebsite => this.allowedDomains.findIndex(allowedDomain => allowedDomain.websiteId === allowedWebsite.id) === -1);
    },

    hasAvailableWebsites() {
      return this.allWebsites.some(website => !this.allowedDomains.includes(domain => domain.websiteId === website.id))
    }
  },

  async created() {
    this.deleteHelpDomain = await this.historyLoggerDecorator(helpService.deleteHelpDomain);

    await this.getWebsites();
    await this.getHelpDomains();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),
    ...mapActions('helpDomains', ['getHelpDomains']),

    async deleteDomain(id) {
      try {
        await this.deleteHelpDomain(id);
        this.handleSuccess({message: 'Domain deleted'});
        this.$emit('deleteDomain');
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },
    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: selected.domain.website.name,
          propData: selected.domain.id,
          deleteDescription: 'Deleting domain will also delete all categories and pages defined for this domain!',
          deleteFunction: this.deleteDomain,
        },
        {height: "auto", scrollable: false, width: "400px"}
      )
    },
    openCreateDomain(domain) {
      this.isCreateOrEdit = true;
      this.selectedDomain = domain;
    },

    async getWebsites(page = 1, limit = 1) {
      const response = await websiteService.getWebsites(page, limit);
      this.allWebsites = response.data;
    },

    formatDomainsTableData(domains) {
      const formattedDomains = [];

      domains.forEach(domain => {
        formattedDomains.push({
          domain: domain,
          rowData: {
            'Domain': domain.website.name,
            'Website Id': domain.websiteId,
          }
        });
      });

      return formattedDomains;
    },

    handleFormClose() {
      this.isCreateOrEdit = false;
      this.selectedDomain = {};
    },

    handleFormSuccess() {
      this.isCreateOrEdit = false;
      this.selectedDomain = {};
      this.$emit('domainUpdated');
    }
  },
};
</script>

<style lang="scss" scoped></style>
