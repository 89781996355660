import { apiService }  from '../api'
import { replacePathParams } from '@/helpers/urlHelpers'

const ENDPOINTS = {
  ICONS_ALL: '/icon-links',
  ICONS_SINGLE: '/icon-links/:id',
}

class IconsService {
  async getAllIcons() {
    return apiService.get(ENDPOINTS.ICONS_ALL);
  }

  async create(data) {
    return apiService.post(ENDPOINTS.ICONS_ALL, data);
  }

  async delete(id) {
    return apiService.delete(replacePathParams(ENDPOINTS.ICONS_SINGLE, { id }));
  }

  async update(id, data) {
    return apiService.put(replacePathParams(ENDPOINTS.ICONS_SINGLE, { id }), data);
  }
}

export const iconsService = new IconsService();