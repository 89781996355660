function getInitialState() {
  return {
    users: []
  }
}

const state = getInitialState()

const getters = {
  users: state => state.users
}

const mutations = {
  FETCHED_USERS (state, data) {
    state.users = data;
  }
}

export const user = {
  namespaced: true,
  state,
  mutations,
  getters
}
