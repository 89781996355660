import { casinoCategoriesService } from '@/services/casino-category'
import get from "lodash/get";

function getInitialState() {
  return {
    casinoCategories: [],
    casinoCategoriesFetched: false,
  }
}

const state = getInitialState()

const actions = {
  // SECTIONS
  async getCasinoCategories({ commit, dispatch }) {
    try {
      commit('SET_CASINO_CATEGORIES_FETCHED', true)
      const { data } = await casinoCategoriesService.getAll();

      commit('SET_CASINO_CATEGORIES', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
      commit('SET_CASINO_CATEGORIES_FETCHED', false);
    }
  },

  async createCasinoCategory({ commit, dispatch }, createData) {
    try {
      const { data } = await casinoCategoriesService.create(createData);
      commit('CREATE_CASINO_CATEGORY', data);
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  async updateCasinoCategory({ commit, dispatch }, { id, updateData }) {
    try {
      const { data } = await casinoCategoriesService.update(id, updateData);
      commit('UPDATE_CASINO_CATEGORY', { casinoData: data, id });
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  async deleteCasinoCategory({ commit, dispatch }, id) {
    try {
      await casinoCategoriesService.delete(id);
      commit('DELETE_CASINO_CATEGORY', id);
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },
}

const getters = {
  casinoCategories: casinoCategoriesState => casinoCategoriesState.casinoCategories,
  casinoCategoriesFetched: casinoCategoriesService => casinoCategoriesService.casinoCategoriesFetched,
}

const mutations = {
  SET_CASINO_CATEGORIES(state, data) {
    state.casinoCategories = data;
  },

  SET_CASINO_CATEGORIES_FETCHED(state, data) {
    this.casinoCategoriesFetched = data;
  },

  CREATE_CASINO_CATEGORY(state, data) {
    state.casinoCategories.push(data);
  },

  UPDATE_CASINO_CATEGORY(state, data) {
    state.casinoCategories = state.casinoCategories.map(casinoCategory => {
      if (casinoCategory.id === data.id) {
        return data.casinoData;
      }

      return casinoCategory;
    })
  },

  DELETE_CASINO_CATEGORY(state, id) {
    state.casinoCategories = state.casinoCategories.filter(casinoCategory => casinoCategory.id !== id)
  },
}

export const casinoCategories = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
