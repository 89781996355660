<template>
  <div>
    <md-tabs v-if="selectedTab" class="c-tabs c-tabs--medium" :md-active-tab="selectedTab.key">
      <md-tab
        v-for="tab in seoTabs"
        :id="tab.key"
        :key="tab.key"
        :md-label="tab.label"
        :md-disabled="tab.disabled"
        @click="changeActiveTab(tab)"
      />
    </md-tabs>

    <component
      :is="selectedTab.component"
      :website="website"
    />
  </div>
</template>

<script>
import ContentEditComponent from './sub-pages/content-edit/index';
import BulkDataComponent from './sub-pages/bulk-data/index';
import TemplatesComponent from './sub-pages/templates/index';
import RedirectionsComponent from './sub-pages/redirections/index';
import RobotsComponent from './sub-pages/robots/index';
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  name: "SeoRules",

  components: {
    ContentEditComponent,
    BulkDataComponent,
    TemplatesComponent,
    RedirectionsComponent,
    RobotsComponent
  },

  mixins: [onlinePermissionsMixin],

  props: {
    website: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      permissionName: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`,

      selectedTab: { label: 'Content Edit', component: ContentEditComponent },
      seoTabs: [
        { label: 'Content Edit', component: 'ContentEditComponent', key: 'content-edit' },
        { label: 'Bulk Data', component: 'BulkDataComponent', key: 'bulk-data' },
        { label: 'Templates', component: 'TemplatesComponent', key: 'templates' },
        { label: 'Redirections', component: 'RedirectionsComponent', key: 'redirections', disabled: true },
        { label: 'Robots', component: 'RobotsComponent', key: 'robots', disabled: true },
      ]
    };
  },

  created() {
    this.seoTabs.map(tab => {
      if (tab.key === 'bulk-data') {
        tab.disabled = !this.canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)
      }

      return tab;
    })
  },

  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
