<template>
  <h3>Robots component is under way</h3>
</template>

<script>

export default {
  name: "Robots",

  props: {
  },

  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
