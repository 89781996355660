<template>
  <div class="u-box c-modal">
    <div class="c-modal-header u-flex-justify-between">
      <h4 class="md-title u-typography-color">
        Language list for key: {{ word.key }}
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </div>

    <md-table>
      <md-table-row>
        <md-table-head class="c-table-header-title">
          Language
        </md-table-head>
        <md-table-head class="c-table-header-title">
          Translation
        </md-table-head>
        <md-table-head class="c-table-header-title">
          Exceptions
        </md-table-head>
      </md-table-row>
      <md-table-row v-for="(translationGroup, index) in formattedTranslations" :key="index">
        <md-table-cell>{{ translationGroup.languageName }}</md-table-cell>
        <md-table-cell>{{ translationGroup.translations ? translationGroup.translations.phrase : '' }}</md-table-cell>
        <md-table-cell>
          <div v-if="translationGroup.exceptions">
            <p v-for="exception in translationGroup.exceptions" :key="exception.id">
              {{ exception.website.url }} - {{ exception.phrase }}
            </p>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-divider class="u-width" />
  </div>
</template>

<script>
import {forEach} from "lodash";
import find from "lodash/find";
import filter from "lodash/filter";
import GenericButton from "../../../../../../shared/buttons/GenericButton";


export default {
  name: "ListTranslationModal",
  components: {
    GenericButton
  },
  props: {
    translations: {
      type: Array,
      required: true,
    },
    exceptions: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    word: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      formattedTranslations: []
    }

  },

  created() {
    forEach(this.languages, language => {
      const findTranslations = find(this.translations, (translation) => translation.language.id === language.id );
      const findExceptions = filter(this.exceptions, (exception) => exception.languageId === language.id );
      if(!findTranslations && (!findExceptions || !findExceptions.length)) {
        return
      }
      this.formattedTranslations.push({
        languageName: language.name,
        translations: findTranslations,
        exceptions: findExceptions,
      })
    })
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
