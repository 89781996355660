import { apiService } from '../api'
import { replacePathParams } from '@/helpers/urlHelpers'
import { stringify } from 'querystring'

const ENDPOINTS = {
  SEO_RULES_SEARCH: '/seo-rules/language/:languageId/search',
  SEO_RULES_FIND: '/seo-rules/websiteId/:websiteId/language/:languageId/type/:entityType/:entityId',
  SEO_RULES_CREATE: '/seo-rules',
  SEO_RULES_UPDATE: '/seo-rules/:seoRuleId',
  SEO_RULES_IMPORT: '/seo-rules/websiteId/:websiteId/language/:languageId/import',
  SEO_RULES_EXPORT: `/seo-rules/websiteId/:websiteId/language/:languageId/export`,
  SPORTS: '/sports',
  REGIONS: '/sports/:sportId/regions',
}

class SeoRulesService {
  async search(languageId, searchConstraints) {
    const queryParams = stringify(searchConstraints);

    let endpoint = replacePathParams(ENDPOINTS.SEO_RULES_SEARCH, { languageId });

    if (queryParams) {
      endpoint += `?${ queryParams }`;
    }

    return apiService.get(endpoint);
  }

  async getSingleSeoRule(websiteId, languageId, entityType, entityId) {
    return apiService.get(replacePathParams(ENDPOINTS.SEO_RULES_FIND, { websiteId, languageId, entityType, entityId }));
  }

  async create(data) {
    return apiService.post(ENDPOINTS.SEO_RULES_CREATE, data);
  }

  async update(seoRuleId, data) {
    return apiService.patch(replacePathParams(ENDPOINTS.SEO_RULES_UPDATE, { seoRuleId }), data)
  }

  async import(websiteId, languageId, data) {
    const formData = new FormData();
    formData.append('file', data);

    return await apiService.upload(replacePathParams(ENDPOINTS.SEO_RULES_IMPORT, { websiteId, languageId }), formData)
  }

  async getSports() {
    return await apiService.get(ENDPOINTS.SPORTS)
  }

  async getRegions(sportId) {
    return await apiService.get(replacePathParams(ENDPOINTS.REGIONS, { sportId }));
  }

  async exportSeo(websiteId, languageId, exportFields, category, sportId, regionId) {
    let url = replacePathParams(ENDPOINTS.SEO_RULES_EXPORT, { websiteId, languageId })

    url += '?' + stringify({
      ...exportFields,
      category,
      sportId,
      regionId
    })

    return await apiService.exportCsv(url);
  }
}

export const seoRulesService = new SeoRulesService()
