<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        Add new ticket banner
      </h4>
      <generic-button icon transparent @click="close()">
        <md-icon> clear </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p v-if="error && error.messages" class="md-error">
        {{ error.messages }}
      </p>
      <form novalidate>
        <md-field :class="getValidationClass($v.ticketBannerItem.default.bannerName)">
          <label for="name">Banner name</label>
          <md-input
            v-model="ticketBannerItem.default.bannerName"
            v-focus
            name="bannerName"
            type="text"
            class="md-input"
            autocomplete="off"
          />
          <span v-if="!$v.ticketBannerItem.default.bannerName.required" class="md-error">Ticket banner name is
            required</span>
        </md-field>
        <error-response-messages :field-name="'bannerName'" />
        <md-field :class="getValidationClass($v.ticketBannerItem.default.bannerUrl)">
          <label>Banner url</label>
          <md-input
            v-model="ticketBannerItem.default.bannerUrl"
            name="bannerUrl"
            type="text"
            class="md-input"
            autocomplete="off"
          />
          <span v-if="!$v.ticketBannerItem.default.bannerUrl.required" class="md-error">Ticket banner url is
            required</span>
        </md-field>
        <label for="name" class="u-margin-10">Is internal url</label>
        <md-checkbox v-model="ticketBannerItem.default.isInternalUrl" class="md-checkbox u-margin-10" />
        <div class="c-form__betshop-avatar">
          <div :class="['c-avatar']">
            <img
              v-if="ticketBannerUrl() || getTicketBannerImagePath['default']"
              class="c-avatar__img"
              :src="getTicketBannerImagePath['default'] ? getTicketBannerImagePath['default'] : ticketBannerUrl()"
              :disabled="!bannerToUpload['default']"
              alt="ticket-banner"
            >
            <input
              type="file"
              name="imagePath"
              class="c-avatar__input"
              accept="image/*"
              @change="handleTicketBannerChange($event.target.files)"
            >
          </div>
        </div>
        <md-tabs class="c-tabs c-tabs--small" :md-active-tab="currentLanguage">
          <md-tab
            v-for="language in languages"
            :id="language"
            :key="language"
            :md-label="language"
            @click="changeLanguage(language)"
          />
        </md-tabs>
        <div v-for="language in languages" :key="language">
          <div v-if="language === currentLanguage">
            <md-field>
              <label>Banner url</label>
              <md-input
                v-model="ticketBannerItem[language].bannerUrl"
                name="bannerUrl"
                type="text"
                class="md-input"
                autocomplete="off"
              />
            </md-field>
            <label for="name" class="u-margin-10">Is internal url</label>
            <md-checkbox v-model="ticketBannerItem[language].isInternalUrl" class="md-checkbox u-margin-10" />
            <div class="c-form__betshop-avatar">
              <div :class="['c-avatar']">
                <img
                  v-if="ticketBannerUrl(language) || getTicketBannerImagePath[language]"
                  class="c-avatar__img"
                  :src="getTicketBannerImagePath[language] ? getTicketBannerImagePath[language] : ticketBannerUrl(language)"
                  :disabled="!bannerToUpload[language]"
                  alt="ticket-banner"
                >
                <input
                  ref="file"
                  type="file"
                  name="imagePath"
                  class="c-avatar__input"
                  accept="image/*"
                  @change="handleTicketBannerChange($event.target.files, language)"
                >
                <div>
                  <generic-button
                    v-if="ticketBannerUrl(language)"
                    class="c-avatar__button"
                    variation="red"
                    @click="handleTicketBannerDelete(language)"
                  >
                    Delete
                  </generic-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button variation="grey" @click="close">
          Close
        </generic-button>
      </div>
      <generic-button variation="red" @click="validateForm">
        Submit
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import GenericButton from "@/shared/buttons/GenericButton";
import ErrorResponseMessages from "@/shared/error-response-messages/ErrorResponseMessages";
import BaseModalComponent from "@/shared/modal/BaseModalComponent";
import { validationBaseMixin } from "@/mixins/ValidationBaseMixin";
import { logoFileExtensions } from "../../../../const/index";
import { checkFileExtensions } from "../../../../const/validator";
import { configurationsService } from "../../../../services/configurations";
import config from "../../../../../config/local";
import { responseService } from "../../../../services/response";
import get from "lodash/get";
import forEach from "lodash/forEach";
import map from "lodash/map";

export default {
  name: "CreateTicketBannerItemModal",
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent,
  },
  mixins: [validationBaseMixin],
  props: {
    ticketBannerItem: {
      type: Object,
      required: true,
    },
    successFunction: {
      type: Function,
      required: true,
    },
    languages: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
      bannerFileError: null,
      bannerUploadFileExtensions: logoFileExtensions,
      previewTicketBannerUrl: this.languagesForBannerUrls(),
      bannerToUpload: this.languagesForBannerUrls(),
      currentLanguage: this.languages[0],
      formIsInvalid: false
    };
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),

    getTicketBannerImagePath() {
      return this.previewTicketBannerUrl;
    },
  },

  validations: {
    ticketBannerItem: {
      default: {
        bannerName: {
          required,
        },
        bannerUrl: {
          required,
        }
      }
    },
  },
  methods: {
    ...mapActions("response", [
      "handleSuccess",
      "handleError",
      "resetError",
      "setGlobalError",
    ]),

    ticketBannerUrl(lang = 'default') {
      const imageUrl = get(this.ticketBannerItem[lang], "imagePath", '');
      return imageUrl && `${config.BASE_URL}/${imageUrl}`;
    },

    async validateForm() {
      this.$v.$touch();
      if(this.$v.$invalid || this.isInvalidImageForm()) {
       return
      }
      await this.saveBannerImages();
      this.successFunction(this.ticketBannerItem);
      this.close();
    },

    isInvalidImageForm() {
      this.formIsInvalid = false;
      // eslint-disable-next-line no-unused-vars
      for (const [language, banner] of Object.entries(this.ticketBannerItem)) {
        if (banner.bannerUrl && (!this.previewTicketBannerUrl[language] && !this.ticketBannerUrl(language)) ) {
          this.setGlobalError("Image is required.");
          this.formIsInvalid = true;
          return this.formIsInvalid
        }
      }
    },

    close() {
      if(this.isInvalidImageForm()) {
        return
      }
      this.$emit("close");
      this.resetError();
    },

    handleTicketBannerChange(files, language = 'default') {
      this.validateImageFile(files.item(0));
      if (this.imageFileError) {
        this.setGlobalError("Only image files are allowed. (jpg, jpeg, png and gif)");
        return;
      }
      this.bannerToUpload[language] = files.item(0);
      this.$refs.file[0].value = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.bannerToUpload[language]);
      reader.onload = (event) => {
        this.previewTicketBannerUrl[language] = event.target.result;

      };
    },

    async handleTicketBannerDelete(language) {
      try {
        const imageName = this.ticketBannerItem[language].imagePath.split('/').pop();
        await configurationsService
          .deleteLanguageTicketBanner(imageName)
          .then(() => {
            this.ticketBannerItem[language].imagePath = ''; 
            this.previewTicketBannerUrl[language] = '';
            this.successFunction(this.ticketBannerItem);
          });
        this.handleSuccess({ message: `Ticket banner for ${language} deleted successfully.` });
      } catch (error) {
        this.handleError(responseService.getErrorMessage(error.data.message));
      }

    },

    validateImageFile(value) {
      this.imageFileError = checkFileExtensions(
        value,
        this.bannerUploadFileExtensions
      );
    },

    languagesForBannerUrls() {
      let previewBannerUrls = { default: '' };
      forEach(this.languages, lang => previewBannerUrls[lang] = '')

      return previewBannerUrls;
    },

    async saveBannerImages() {
     return Promise.all(map(Object.keys(this.bannerToUpload), (banner) => {
          if(this.bannerToUpload[banner]) {
            return this.handleTicketBannerUpdate(banner)
          }

          return Promise.resolve(true);
      }));
    },

    async handleTicketBannerUpdate(language = 'default') {
      const formData = new FormData();
      formData.append("file", this.bannerToUpload[language], this.bannerToUpload[language].name);
      try {
        this.resetError();
        await configurationsService
          .saveTicketBannerImage(formData)
          .then(
            (res) =>
              { 
                this.ticketBannerItem[language].imagePath = res.data.image.imagePath 
              }
          );
        this.handleSuccess({ message: "Ticket banner uploaded successfully." });
        this.bannerToUpload[language] = null;
      } catch (error) {
        this.handleError(responseService.getErrorMessage(error.data.message));
      }
    },
    changeLanguage(language) {
      this.currentLanguage = language;
    },
  },
};
</script>

<style lang="scss" scoped></style>
 