import { apiService } from '../api'
import * as qs from 'qs';

const ENDPOINTS = {
	GET_REPORT: '/terminal/payment-report',
	GET_CURRENCIES: '/terminal/payment-currencies'
}

class TerminalService {
	async getPaymentReport(data) {
		const queryParams = qs.stringify({
            ...data,
            fromTime: data.fromTime ? data.fromTime : undefined,
            toTime: data.toTime ? data.toTime : undefined,
        });
		return await apiService.get(ENDPOINTS.GET_REPORT + `?${queryParams}`);
	}

	async getPaymentCurrencies() {
		return await apiService.get(ENDPOINTS.GET_CURRENCIES);
	}
}

export const terminalService = new TerminalService();