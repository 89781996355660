<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        Betradar Tracker Configuration Item
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
      >
        <div class="c-config-input-type">
          <md-field :class="getValidationClass($v.betradarConfigurationItem.sportName)">
            <label for="name">Sport</label>
            <md-input
              v-model="betradarConfigurationItem.sportName"
              v-focus
              name="sportName"
              type="text"
              class="md-input"
              autocomplete="off"
            />
            <span
              v-if="!$v.betradarConfigurationItem.sportName.required"
              class="md-error"
            >Sport name is required</span>
          </md-field>
          <error-response-messages :field-name="'sportName'" />
        </div>

        <div class="c-config-input-type">
          <md-field :class="getValidationClass($v.betradarConfigurationItem.sportId)">
            <label for="name">Sport ID</label>
            <md-input
              v-model="betradarConfigurationItem.sportId"
              name="sportId"
              type="text"
              class="md-input"
              autocomplete="off"
            />
            <span
              v-if="!$v.betradarConfigurationItem.sportId.required"
              class="md-error"
            >Sport ID is required</span>
          </md-field>
          <error-response-messages :field-name="'sportId'" />
        </div>

        <div class="c-config-input-type">
          <div class="c-config-input-info">
            <label>Is betradar tracker enabled on live match</label>
          </div>

          <div class="c-config-input-actions">
            <md-radio v-model="betradarConfigurationItem.isLiveMatchEnabled" :value="true">
              Enabled
            </md-radio>
            <md-radio v-model="betradarConfigurationItem.isLiveMatchEnabled" :value="false">
              Disabled
            </md-radio>
          </div>
        </div>

        <div class="c-config-input-type">
          <div class="c-config-input-info">
            <label>Is betradar tracker enabled on standard match</label>
          </div>

          <div class="c-config-input-actions">
            <md-radio v-model="betradarConfigurationItem.isStandardMatchEnabled" :value="true">
              Enabled
            </md-radio>
            <md-radio v-model="betradarConfigurationItem.isStandardMatchEnabled" :value="false">
              Disabled
            </md-radio>
          </div>
        </div>

        <div class="c-config-input-type">
          <div class="c-config-input-info">
            <label>Betradar live event configuration</label>
          </div>
          <div class="c-config-input-actions">
            <textarea
              v-model="betradarConfigurationItem.liveMatchConfiguration"
              class="c-config-textarea"
              rows="5"
            />
          </div>
        </div>

        <div class="c-config-input-type">
          <div class="c-config-input-info">
            <label>Betradar standard event configuration</label>
          </div>
          <div class="c-config-input-actions">
            <textarea
              v-model="betradarConfigurationItem.standardMatchConfiguration"
              class="c-config-textarea"
              rows="5"
            />
          </div>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateForm"
      >
        Continue
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import GenericButton from '@/shared/buttons/GenericButton'
import ErrorResponseMessages from '@/shared/error-response-messages/ErrorResponseMessages'
import BaseModalComponent from '@/shared/modal/BaseModalComponent'
import { validationBaseMixin } from '@/mixins/ValidationBaseMixin'

export default {
  name: 'CreateBetradarConfigurationItemModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],
  props: {
    betradarConfigurationItem: {
      type: Object,
      required: true,
    },
    successFunction: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
    };
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),
  },

  validations: {
    betradarConfigurationItem: {
      sportName: {
        required
      },
      sportId: {
        required
      },
    },
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    validateForm () {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.successFunction(this.betradarConfigurationItem);
        this.close();
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.c-config-textarea {
  width: 100%; margin-left: 10px;resize: none;
}
</style>
