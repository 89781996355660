<template>
  <div class="l-main">
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-websites">
        <base-table-component :prop-data="tableData">
          <template v-slot:action>
            <div class="header-table-section">
              Custom Pages
            </div>
            <div class="add-button">
              <div>
                List of available custom page
              </div>
              <div class="add-section">
                <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateCustomPage()"><span>Add New Page</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                @click="createOrUpdateCustomPage(slotProps.selected.page)"
              >
                <md-tooltip md-direction="top"> Edit </md-tooltip>
                <img src="../../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>

            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.page)"
              >
                <md-tooltip md-direction="top"> Delete </md-tooltip>
                <img src="../../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { customPagesService } from '@/services/custom-pages'
import BaseTableComponent from '../../../../../shared/table/BaseTableComponent'
import DeleteModalComponent from '../../../../../shared/delete-modal/DeleteModalComponent'
import CreateCustomPageModal from '../../../configurations/components/custom-pages/create'
import get from 'lodash/get'
import GenericButton from '../../../../../shared/buttons/GenericButton'
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";

export default {
  name: "OnlineCustomPagesList",

  components: {
    BaseTableComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      onlyDevelopersAllowed: true,
    }
  },

  computed: {
    ...mapGetters('customPages', ['customPages']),

    tableData() {
      return customPagesService.prepareCustomPagesDataFromResponse(this.customPages);
    }
  },

  created() {
    this.getCustomPages();
  },

  methods: {
    ...mapActions('customPages', [
      'getCustomPages',
      'createCustomPage',
      'updateCustomPage',
      'deleteCustomPage'
    ]),
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },


    openDeleteModal(selected) {
      this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: selected.name,
            propData: selected.id,
            deleteFunction: this.deleteCustomPage,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },

    createOrUpdateCustomPage(customPage = {}) {
      this.$modal.show(
          CreateCustomPageModal,
          {
            customPage: {
              name: get(customPage, 'name', ''),
            },
            customPageId: get(customPage, 'id'),
          },
          { height: "auto", scrollable: true }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
