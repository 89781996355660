<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        {{ createOrUpdate }} Website
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
        @submit.prevent="validateUser"
      >
        <md-field :class="getValidationClass($v.websiteData.name)">
          <label for="name">Name</label>
          <md-input
            v-model="websiteData.name"
            v-focus
            name="name"
            type="name"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.websiteData.name.required"
            class="md-error"
          >The name is required</span>
        </md-field>
        <error-response-messages :field-name="'name'" />

        
        <md-field :class="getValidationClass($v.websiteData.url)">
          <label for="url">Url</label>
          <md-input
            v-model="websiteData.url"
            name="url"
            type="url"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.websiteData.url.required"
            class="md-error"
          >The url is required</span>
        </md-field>
        <error-response-messages :field-name="'url'" />

        <md-field :class="getValidationClass($v.websiteData.betshopId)">
          <label for="betshopId">Betshop id</label>
          <md-input
            v-model="websiteData.betshopId"
            name="betshopId"
            type="betshopId"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.websiteData.betshopId.required"
            class="md-error"
          >The betshopId is required</span>
        </md-field>
        <error-response-messages :field-name="'betshopId'" />
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <div v-if="websiteData.role === 2" class="u-mr-xs">
        <generic-button
          variation="red"
          @click="validateUser(true)"
        >
          Create and set permissions
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateUser"
      >
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GenericButton from "../../../../shared//buttons/GenericButton";
import ErrorResponseMessages from "../../../../shared/error-response-messages/ErrorResponseMessages";
import { responseService } from "../../../../services/response";
import BaseModalComponent from "../../../../shared/modal/BaseModalComponent";
import { required } from "vuelidate/lib/validators";
import { validationBaseMixin } from '../../../../mixins/ValidationBaseMixin';
import { websiteService } from '../../../../services/websites';


export default {
  name: 'CreateWebsiteModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],

  props: {
    getWebsites: {
      type: Function,
      required: true
    },
    websiteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
    };
  },
  computed: {
    ...mapGetters("websites", ["website"]),
    ...mapGetters("response", ["success", "error"]),

    createOrUpdate() {
      return !this.websiteData.id ? 'Create' : 'Update';
    },
  },

  validations: {
    websiteData: {
      name: {
        required
      },
      url: {
        required
      },
      betshopId: {
        required
      }
    }
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    async createWebsite() {
      try {
        await websiteService.create(this.websiteData);
        this.handleSuccess({ message: "Website Created!" });
        this.getWebsites();
        this.close();
      } catch (err) {

        this.handleError(responseService.getErrorMessage(err.data.message[0]));
      }
    },

    async updateWebsite() {
      try {
        await websiteService.update(this.websiteData);
        this.handleSuccess({ message: "Website Updated!" });
        this.getWebsites();
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message[0]));
      }
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        !this.websiteData.id ? this.createWebsite() : this.updateWebsite();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>