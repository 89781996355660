import { onlineConfigurationService } from '../../services/online-configuration'

function getInitialState() {
  return {
    configurations: [],
    sections: [],
    subsections: [],
    types: []
  }
}

const state = getInitialState()

const actions = {
  // SECTIONS
  async getOnlineConfigurationSections({ commit, dispatch }) {
    try {
      const { data } = await onlineConfigurationService.getOnlineConfigurationSections()
      if (data.length === 0) {
        return
      }

      commit('SET_ONLINE_CONFIGURATIONS_SECTIONS', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async createOnlineConfigurationSection({ commit, dispatch }, createData) {
    try {
      const { data } = await onlineConfigurationService.createOnlineConfigurationSection(createData)
      commit('CREATE_ONLINE_CONFIGURATIONS_SECTION', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async updateOnlineConfigurationSection({ commit, dispatch }, { id, updateData }) {
    try {
      const { data } = await onlineConfigurationService.updateOnlineConfigurationSection(id, updateData)
      commit('UPDATE_ONLINE_CONFIGURATIONS_SECTION', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async deleteOnlineConfigurationSection({ commit, dispatch }, id) {
    try {
      await onlineConfigurationService.deleteOnlineConfigurationSection(id)
      commit('DELETE_ONLINE_CONFIGURATIONS_SECTION', id)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  // TYPES
  async getOnlineConfigurationTypes({ commit, dispatch }) {
    try {
      const { data } = await onlineConfigurationService.getOnlineConfigurationTypes()
      if (data.length === 0) {
        return
      }

      commit('SET_ONLINE_CONFIGURATIONS_TYPES', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async createOnlineConfigurationType({ commit, dispatch }, createData) {
    try {
      const { data } = await onlineConfigurationService.createOnlineConfigurationType(createData)
      commit('CREATE_ONLINE_CONFIGURATIONS_TYPE', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async updateOnlineConfigurationType({ commit, dispatch }, { id, updateData }) {
    try {
      const { data } = await onlineConfigurationService.updateOnlineConfigurationType(id, updateData)
      commit('UPDATE_ONLINE_CONFIGURATIONS_TYPE', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async deleteOnlineConfigurationType({ commit, dispatch }, id) {
    try {
      await onlineConfigurationService.deleteOnlineConfigurationType(id)
      commit('DELETE_ONLINE_CONFIGURATIONS_TYPE', id)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  // SUBSECTIONS
  async getOnlineConfigurationSubsections({ commit, dispatch }) {
    try {
      const { data } = await onlineConfigurationService.getOnlineConfigurationSubsections()
      if (data.length === 0) {
        return
      }

      commit('SET_ONLINE_CONFIGURATIONS_SUBSECTIONS', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  // CONFIGURATIONS

  async getWebsiteConfiguration({ commit, dispatch }, websiteId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await onlineConfigurationService.getWebsiteConfigurations(websiteId);
      if (data.length === 0) {
        return
      }
      commit('SET_WEBSITE_CONFIGURATIONS', data)
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async submitOnlineConfiguration({ commit, dispatch }, { websiteId, formData }) {
    try {
      const {data} = await onlineConfigurationService.createOrUpdate(websiteId, formData)

      commit('SET_WEBSITE_CONFIGURATIONS', data)
      dispatch('response/handleSuccess', { message: "Configuration updated successfully." }, { root: true });
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  // CONFIGURATIONS SETTINGS

  async getOnlineConfiguration({ commit, dispatch }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await onlineConfigurationService.getOnlineConfigurations();
      commit('SET_ONLINE_CONFIGURATIONS', data)
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async createOnlineConfiguration({ commit, dispatch }, createData) {
    try {
      const { data } = await onlineConfigurationService.createOnlineConfiguration(createData)
      commit('CREATE_ONLINE_CONFIGURATIONS', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
      throw e
    }
  },

  async updateOnlineConfiguration({ commit, dispatch }, { id, updateData }) {
    try {
      const { data } = await onlineConfigurationService.updateOnlineConfiguration(id, updateData)
      commit('UPDATE_ONLINE_CONFIGURATIONS', data)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
      throw e
    }
  },

  async deleteOnlineConfiguration({ commit, dispatch }, id) {
    try {
      await onlineConfigurationService.deleteOnlineConfiguration(id)
      commit('DELETE_ONLINE_CONFIGURATIONS', id)
    } catch (e) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
      throw e
    }
  },
}

const getters = {
  configurations: configState => configState.configurations,
  sections: configState => configState.sections,
  subsections: configState => configState.subsections,
  types: configState => configState.types,
}

const mutations = {
  SET_WEBSITE_CONFIGURATIONS(state, data) {
    state.configurations = data
  },

  // SECTIONS

  SET_ONLINE_CONFIGURATIONS_SECTIONS(state, data) {
    state.sections = data
  },

  CREATE_ONLINE_CONFIGURATIONS_SECTION(state, data) {
    state.sections.push(data)
  },

  UPDATE_ONLINE_CONFIGURATIONS_SECTION(state, data) {
    state.sections = state.sections.map(section => {
      if (section.id === data.id) {
        return data
      }
      return section
    })
  },

  DELETE_ONLINE_CONFIGURATIONS_SECTION(state, id) {
    state.sections = state.sections.filter(section => section.id !== id)
  },

  // TYPES

  SET_ONLINE_CONFIGURATIONS_TYPES(state, data) {
    state.types = data
  },

  CREATE_ONLINE_CONFIGURATIONS_TYPE(state, data) {
    state.types.push(data)
  },

  UPDATE_ONLINE_CONFIGURATIONS_TYPE(state, data) {
    state.types = state.types.map(type => {
      if (type.id === data.id) {
        return data
      }
      return type
    })
  },

  DELETE_ONLINE_CONFIGURATIONS_TYPE(state, id) {
    state.types = state.types.filter(type => type.id !== id)
  },

  // SUBSECTIONS

  SET_ONLINE_CONFIGURATIONS_SUBSECTIONS(state, data) {
    state.subsections = data
  },

  // CONFIGURATION SETTINGS

  SET_ONLINE_CONFIGURATIONS(state, data) {
    state.configurations = data
  },

  CREATE_ONLINE_CONFIGURATIONS(state, data) {
    state.configurations.push(data)
  },

  UPDATE_ONLINE_CONFIGURATIONS(state, data) {
    state.configurations = state.configurations.map(config => {
      if (config.id === data.id) {
        return data
      }
      return config
    })
  },

  DELETE_ONLINE_CONFIGURATIONS(state, id) {
    state.configurations = state.types.filter(config => config.id !== id)
  },

}

export const onlineConfiguration = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
