import { apiService } from '../api'
import { replacePathParams } from '../../helpers/urlHelpers';
import { restApiService } from '../rest-api';

const ENDPOINTS = {
  GET_TERRITORY_HIERARCHY_FROM_REST: '/api/territory-hierarchy/v1',
  SAVE_COMPANIES: '/company/save',
  GET_COMPANIES: '/company/get-all?page=:page&limit=:limit&term=:term',
  GET_BETSHOPS: '/markets/:marketId/betshops?page=:page&limit=:limit&term=:term',
  SAVE_MARKETS: '/markets/save',
  GET_MARKETS: '/markets',
  GET_SINGLE_MARKET: '/markets/:marketId',
  SAVE_MARKET_TEMPLATE: '/markets/:marketId/template',
  SAVE_MARKET_LOGO: '/market-logo?marketId=:marketId',
  SAVE_BETSHOP_LOGO: '/betshop-logo?betshopId=:betshopId&betshopLogoEnabled=:betshopLogoEnabled',
  UPDATE_BETSHOP_LOGO: '/betshop-logo/:betshopLogoId',
  SAVE_BETSHOPS: '/betshops/save',
  GET_COMPANY_BREDCRUMPS: '/company/:companyId/bredcrumps',
  GET_MARKET_BREDCRUMPS: '/markets/:marketId/bredcrumps',
  GET_MARKET_TICKET_DESCRIPTIONS: '/market-ticket-description/:marketId/',
  GET_BETSHOP_TICKET_DESCRIPTIONS: '/betshop-ticket-description/:betshopId/',
  GET_MARKET_TICKET_TOP_SECTION: '/market-ticket-top-section/:marketId/',
  GET_BETSHOP_TICKET_TOP_SECTION: '/betshop-ticket-top-section/:betshopId/',
  SAVE_MARKET_TICKET_DESCRIPTIONS: '/market-ticket-description/save/:marketId',
  SAVE_BETSHOP_TICKET_DESCRIPTIONS: '/betshop-ticket-description/save/:betshopId',
  SAVE_MARKET_TICKET_TOP_SECTION: '/market-ticket-top-section/save/:marketId',
  SAVE_BETSHOP_TICKET_TOP_SECTION: '/betshop-ticket-top-section/save/:betshopId',
  SAVE_BETSHOPS_TEMPLATE: '/betshops/:betshopId/template',
  GET_COMPANIES_MARKETS: '/company/markets?companyIds=:companyIds',
  GET_MARKETS_BETSHOPS: '/company/market/betshops?marketIds=:marketIds',
}

class CompanyService {
  async getCompaniesFromRest() {
    return await restApiService.get(`${ENDPOINTS.GET_TERRITORY_HIERARCHY_FROM_REST}/companies`);
  }

  async getMarketsFromRest(companyId) {
    return await restApiService.get(`${ENDPOINTS.GET_TERRITORY_HIERARCHY_FROM_REST}/companies/${companyId}/markets`);
  }

  async getBetshopsFromRest(marketId) {
    return await restApiService.get(`${ENDPOINTS.GET_TERRITORY_HIERARCHY_FROM_REST}/markets/${marketId}/betshops`);
  }

  async getMarketLanguages(companyId, marketId) {
    return await restApiService.get(`${ENDPOINTS.GET_TERRITORY_HIERARCHY_FROM_REST}/companies/${companyId}/markets/${marketId}`);
  }

  async saveLanguages(marketId, data) {
    return await apiService.post(`${ENDPOINTS.GET_MARKETS}/${marketId}/languages`, data);
  }

  async saveCompanies(data) {
    return await apiService.post(ENDPOINTS.SAVE_COMPANIES, data);
  }

  async saveMarkets(data) {
    return await apiService.post(ENDPOINTS.SAVE_MARKETS, data);
  }

  async saveBetshops(data) {
    return await apiService.post(ENDPOINTS.SAVE_BETSHOPS, data);
  }

  async getCompanies(page = 1, limit = 20, term = '') {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_COMPANIES, { page, limit, term }));
  }

  async getMarketsByCompanyId({ page, limit, term, companyId }) {
    return await apiService.get(`/company/${companyId}/markets?page=${page}&limit=${limit}&term=${term}`);
  }

  async getBetshopsByMarketId(marketId, page = 1, limit = 20, term = '') {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_BETSHOPS, { marketId, page, limit, term }));
  }

  async getCompanyBredCrumps(companyId) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_COMPANY_BREDCRUMPS, { companyId }));
  }

  async getMarketBredCrumps(marketId) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_MARKET_BREDCRUMPS, { marketId }));
  }
}

export const companyService = new CompanyService()
