<template>
  <div class="l-main">
    <div class="c-website__component">
      <md-tabs
        class="c-tabs c-tabs--medium"
        :md-active-tab="selectedSubsection.name"
      >
        <md-tab
          v-for="subsection in availableSubsections"
          :id="subsection.name"
          :key="`subsection-${subsection.id + Math.random()}`"
          :md-label="subsection.name.replace('-', ' ')"
          @click="changeSubsection(subsection)"
        />
      </md-tabs>

      <div class="l-main__ctn u-overflow-auto">
        <template v-for="input in form">
          <component
            :is="input.type"
            :key="`config-${input.id}`"
            :input="input"
            :languages="languages"
            :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`)"
            @update="handleUpdate(input, $event)"
          />
        </template>

        <div class="u-flex-justify-end u-mr-md u-mb-md u-sticky-bottom u-sticky-submit-wrapper">
          <generic-button
            variation="red"
            :disabled="!isValid || !canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`)"
            @click="handleSubmit()"
          >
            Submit
          </generic-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {jsonParse} from '@/helpers/transformers';
import get from "lodash/get";
import GenericButton from "../../../shared/buttons/GenericButton";
import StringFormField from "../../../shared/form-fields/StringFormField";
import TextAreaFormField from "../../../shared/form-fields/TextAreaFormField";
import JsonFormField from "@/shared/form-fields/JsonFormField.vue";
import RadioFormField from "../../../shared/form-fields/RadioFormField";
import PageMultiLanguageHtmlCssFormField from "../../../shared/form-fields/PageMultiLanguageHtmlCssFormField";
import LandingPageItemsFormField from "../../../shared/form-fields/LandingPageItemsFormField";
import BetradarTrackerFormField from "../../../shared/form-fields/BetradarTrackerFormField";
import TicketBannerFormField from "../../../shared/form-fields/TicketBannerFormField.vue";
import SocialShareMetaTagsFormField from '@/shared/form-fields/SocialShareMetaTagsFormField.vue';
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";
import AreYouSureModal from "@/shared/are-you-sure-modal/AreYouSureModalComponent";
import PageMultiLanguageHtmlCssApplicationCssFormField from "../../../shared/form-fields/PageMultiLanguageHtmlCssApplicationCssFormField";

export default {
  name: "GenericOnlineConfiguration",

  components: {
    GenericButton,
    StringFormField,
    TextAreaFormField,
    JsonFormField,
    RadioFormField,
    PageMultiLanguageHtmlCssFormField,
    LandingPageItemsFormField,
    BetradarTrackerFormField,
    TicketBannerFormField,
    SocialShareMetaTagsFormField,
    PageMultiLanguageHtmlCssApplicationCssFormField,
  },

  mixins: [onlinePermissionsMixin],

  props: {
    website: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      permissionName: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`,
      languages: [],
      currentLanguage: "",
      selectedSubsection: {},
      form: [],
      isValid: false,
      formChanged: false,
    };
  },

  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    ...mapGetters("onlineConfiguration", [
      "configurations",
      "sections",
      "subsections",
      "types",
    ]),

    availableLanguages() {
      return this.languages;
    },

    availableConfigurations() {
      return this.configurations.filter(
        (configuration) => configuration.sectionId === this.section.id
      );
    },

    availableSubsections() {
      const availableSubsectionIds = this.availableConfigurations.map(
        (configuration) => configuration.subsectionId
      );
      const uniqueSubsectionIds = [...new Set(availableSubsectionIds)];

      return uniqueSubsectionIds.map((subsectionId) =>
        this.subsections.find((subsection) => subsection.id === subsectionId)
      );
    },
  },

  watch: {
    section: function () {
      if (this.selectedSubsection === this.availableSubsections[0]) {
        return this.displayChangePagePrompt();
      }

      this.selectedSubsection = this.availableSubsections[0];
    },

    selectedSubsection: function () {
      this.displayChangePagePrompt();
    },

    configurations: function () {
      this.prepareForm();
    },
  },

  destroyed() {
    this.displayChangePagePrompt();
  },

  created() {
    this.getAvailableLanguages();
    this.selectedSubsection = this.availableSubsections[0];
    this.prepareForm();
  },

  methods: {
    ...mapActions("onlineConfiguration", ["submitOnlineConfiguration"]),

    changeLanguage(language) {
      this.currentLanguage = language;
    },

    changeSubsection(subsection) {
      this.selectedSubsection = subsection;
    },

    getAvailableLanguages() {
      const additionalLanguages =
        jsonParse(get(this, "website.betshop.market.additionalLanguages")) ||
        [];
      const defaultLanguage = get(
        this,
        "website.betshop.market.defaultLanguage",
        "en"
      );

      this.languages = [...new Set([defaultLanguage, ...additionalLanguages])];
      this.currentLanguage = this.languages[0];
    },

    handleUpdate(input, update) {
      this.formChanged = true;

      input.value = update.value;
      input.isValid = update.isValid !== false;

      this.isValid = this.form.every((input) => {
        return input.isValid !== false;
      });

      if (update.submitForm) {
        this.handleSubmit();
      }
    },

    prepareForm() {
      this.form = [];
      this.isValid = false;
      this.formChanged = false;

      const typeMapper = {
        BooleanFormField: "RadioFormField",
      };

      this.availableConfigurations
        .filter(
          (configuration) =>
            configuration.subsectionId === this.selectedSubsection.id
        )
        .forEach((configuration) => {
          configuration.type = this.types.find(
            (type) => type.id === configuration.typeId
          ).name;

          if (typeMapper[configuration.type]) {
            configuration.type = typeMapper[configuration.type];
          }

          const value =
            configuration.websiteOnlineConfigurations.length > 0
              ? configuration.websiteOnlineConfigurations[0].value
              : configuration.defaultValue;

          configuration.value = jsonParse(value);

          this.form.push(configuration);
        });
    },

    async handleSubmit() {
      const formData = this.formatDataForSubmit();
      await this.submitOnlineConfiguration({websiteId: this.website.id, formData});
      this.formChanged = false;
    },

    formatDataForSubmit() {
      return this.form.map((formField) => ({
        websiteId: this.website.id,
        onlineConfigurationId: formField.id,
        value:
          typeof formField.value === "object"
            ? JSON.stringify(formField.value)
            : formField.value,
      }));
    },

    displayChangePagePrompt() {
      if (!this.formChanged) {
        return this.prepareForm();
      }

      this.$modal.show(
        AreYouSureModal,
        {
          acceptFunction: async () => {
            await this.handleSubmit();
            this.prepareForm();
          },
          cancelFunction: this.prepareForm,
          modalTitle: 'Do you want to save changes?',
          modalMessage: '',
          modalMsgAlert: '',
          modalDescription: 'If you choose not to save, your changes will be lost',
        },
        {height: "auto", scrollable: false, width: "400px"}
      );
    }
  },
};
</script>
