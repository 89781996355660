import { replacePathParams } from '../../helpers/urlHelpers';
import { apiService } from '../api';

const ENDPOINTS = {
  CONFIGURATION_IMAGES:'/configuration-images',
  DELETE_CONFIGURATION_IMAGES: '/configuration-images/:imagePath'
}

class ConfigurationsService {
  async saveTicketBannerImage(formData) {
    return await apiService.post(ENDPOINTS.CONFIGURATION_IMAGES, formData);
  }

  async deleteLanguageTicketBanner(imagePath) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE_CONFIGURATION_IMAGES, {imagePath}))
  }
}

export const configurationsService = new ConfigurationsService()
