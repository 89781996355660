import { apiService } from '../api'
import { userRoles } from '../../const/roles';

const ENDPOINTS = {
  GET: '/users',
  CREATE: '/user/create',
  CHANGE_PASSWORD: '/user/change-password',
  DELETE: '/user',
  UPDATE: '/user'
}

class UserService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async changePassword(data) {
    return await apiService.post(ENDPOINTS.CHANGE_PASSWORD, data);
  }

  async getUsers(page, limit) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}`);
  }

  async getSingleUser(userId) {
    return await apiService.get(`${ENDPOINTS.GET}/${userId}`);
  }

  async deleteUser(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  async update(data) {
    return await apiService.put(`${ENDPOINTS.UPDATE}/${data.id}`, data);
  }

  formatUserDataData() {
    return { role: 2, password: null, email: null };
  }

  prepareUsersDataFromResponse(items) {
    const users = [];
    items.forEach(user => {
      users.push({'rowData': {
        id: user.id,
        email: user.email,
        role: user.role === 1 ? userRoles.admin : userRoles.manager,
        permissions: user.permissions,
        }
      });
    });

    return users;
  }
}

export const userService = new UserService()
