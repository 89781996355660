import { apiService } from '../api'

const ENDPOINTS = {
  SEO_PAGES: '/seo-page',
}

class CustomPagesService {
  async create(data) {
    return apiService.post(ENDPOINTS.SEO_PAGES, data)
  }

  async update(id, data) {
    return apiService.put(`${ ENDPOINTS.SEO_PAGES }/${ id }`, data)
  }

  async delete(id) {
    return apiService.delete(`${ ENDPOINTS.SEO_PAGES }/${ id }`);
  }

  async getAll() {
    return apiService.get(ENDPOINTS.SEO_PAGES);
  }

  prepareCustomPagesDataFromResponse(items) {
    const customPages = [];

    items.forEach(customPage => {
      customPages.push({
        page: customPage,
        rowData: {
          name: customPage.name,
        }
      });
    });

    return customPages;
  }
}

export const customPagesService = new CustomPagesService()
