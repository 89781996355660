import Vue from 'vue'
import Vuex from 'vuex'
import { account } from './modules/account';
import { response } from './modules/response';
import { company } from './modules/company';
import { theme } from './modules/theme';
import { user } from './modules/user';
import { website } from './modules/website';
import { onlineConfiguration } from './modules/online-configuration';
import { customPages } from './modules/custom-pages';
import { casinoCategories } from './modules/casino-categories';
import { helpLinks } from './modules/help-links';
import { helpDomains } from './modules/help-domains';
import { terminal } from './modules/terminal';

Vue.use(Vuex)

const state = {}
const mutations = {}
const actions = {}
const getters = {}

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    account,
    response,
    company,
    theme,
    user,
    website,
    onlineConfiguration,
    customPages,
    casinoCategories,
    helpLinks,
    helpDomains,
    terminal
  }
})
