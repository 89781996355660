import {apiService} from '../api'
import {replacePathParams} from '@/helpers/urlHelpers';

const ENDPOINTS = {
  HELP_LINK: '/help-domain-link',
  HELP_LINK_SINGLE: '/help-domain-link/:helpLinkId',

  HELP_DOMAIN: '/help/help-domain',
  HELP_DOMAIN_SINGLE: '/help/help-domain/:helpDomainId',
  HELP_DOMAIN_HELP_CONTACT_SECTION: '/help/help-domain/:helpDomainId/help-contact-section',
  HELP_DOMAIN_HELP_CONTACT_FORM: '/help/help-domain/:helpDomainId/help-contact-form',

  HELP_CATEGORY: '/help/help-domain/:helpDomainId/help-category',
  HELP_CATEGORY_SINGLE: '/help/help-domain/:helpDomainId/help-category/:helpCategoryId',
  HELP_CATEGORY_REORDER: '/help/help-domain/:helpDomainId/help-category/reorder',

  HELP_PAGE: '/help/help-domain/:helpDomainId/help-page',
  HELP_PAGE_SINGLE: '/help/help-domain/:helpDomainId/help-page/:helpPageId',
  HELP_PAGE_REORDER: '/help/help-domain/:helpDomainId/help-category/:helpCategoryId/help-page/reorder',
  HELP_PAGE_DUPLICATE: '/help/help-domain/:helpDomainId/help-category/:helpCategoryId/help-page/:helpPageId/duplicate',
}

class HelpService {
  async createHelpLink(data) {
    return apiService.post(ENDPOINTS.HELP_LINK, data)
  }

  async updateHelpLink(helpLinkId, data) {
    return apiService.put(replacePathParams(ENDPOINTS.HELP_LINK_SINGLE, {helpLinkId}), data);
  }

  async deleteHelpLink(helpLinkId) {
    return apiService.delete(replacePathParams(ENDPOINTS.HELP_LINK_SINGLE, {helpLinkId}));
  }

  async getHelpLinks() {
    return apiService.get(ENDPOINTS.HELP_LINK);
  }

  async createHelpDomain(data) {
    return apiService.post(ENDPOINTS.HELP_DOMAIN, data)
  }

  async getHelpDomains() {
    return apiService.get(ENDPOINTS.HELP_DOMAIN)
  }

  async updateHelpDomain(helpDomainId, data) {
    return apiService.put(replacePathParams(ENDPOINTS.HELP_DOMAIN_SINGLE, {helpDomainId}), data)
  }

  async deleteHelpDomain(helpDomainId) {
    return apiService.delete(replacePathParams(ENDPOINTS.HELP_DOMAIN_SINGLE, {helpDomainId}))
  }

  async getHelpCategories(helpDomainId) {
    return apiService.get(replacePathParams(ENDPOINTS.HELP_CATEGORY, {helpDomainId}));
  }

  async deleteHelpCategory(helpCategory) {
    return apiService.delete(
      replacePathParams(ENDPOINTS.HELP_CATEGORY_SINGLE, {
        helpDomainId: helpCategory.helpDomainId,
        helpCategoryId: helpCategory.id
      })
    )
  }

  async reorderHelpCategories(helpDomainId, data) {
    const url = ENDPOINTS.HELP_CATEGORY_REORDER.replace(':helpDomainId', helpDomainId);
    return apiService.post(url, data);
  }


  async reorderHelpPages(helpDomainId, helpCategoryId, data) {
    const url = ENDPOINTS.HELP_PAGE_REORDER
      .replace(':helpDomainId', helpDomainId)
      .replace(':helpCategoryId', helpCategoryId);

    return apiService.post(url, data);
  }

  async createHelpPage(data, helpDomainId) {
    return apiService.post(
      replacePathParams(ENDPOINTS.HELP_PAGE, {helpDomainId}),
      data
    )
  }

  async deleteHelpPage(helpDomainId, helpPageId) {
    return apiService.delete(
      replacePathParams(ENDPOINTS.HELP_PAGE_SINGLE, {helpDomainId, helpPageId})
    )
  }

  async updateHelpPage(data, helpDomainId, helpPageId) {
    return apiService.put(
      replacePathParams(ENDPOINTS.HELP_PAGE_SINGLE, {helpDomainId, helpPageId}),
      data)
  }

  async duplicateHelpPage(helpDomainId, helpCategoryId, helpPageId) {
    return apiService.post(
      replacePathParams(ENDPOINTS.HELP_PAGE_DUPLICATE, {
        helpDomainId,
        helpCategoryId,
        helpPageId
      })
    )
  }

  async createHelpCategory(data, helpDomainId) {
    return apiService.post(
      replacePathParams(ENDPOINTS.HELP_CATEGORY, {helpDomainId}),
      data
    );
  }

  async updateHelpCategory(data, helpDomainId, helpCategoryId) {
    return apiService.put(
      replacePathParams(ENDPOINTS.HELP_CATEGORY_SINGLE, {helpDomainId, helpCategoryId}),
      data
    );
  }

  async getContactSection(helpDomainId) {
    return apiService.get(
      replacePathParams(ENDPOINTS.HELP_DOMAIN_HELP_CONTACT_SECTION, {helpDomainId})
    );
  }

  async createContactSection(data, helpDomainId) {
    return apiService.post(
      replacePathParams(ENDPOINTS.HELP_DOMAIN_HELP_CONTACT_SECTION, {helpDomainId}),
      data
    );
  }

  async updateContactSection(data, helpDomainId) {
    return apiService.put(
      replacePathParams(ENDPOINTS.HELP_DOMAIN_HELP_CONTACT_SECTION, {helpDomainId}),
      data
    );
  }

  async getContactForm(helpDomainId) {
    return apiService.get(
      replacePathParams(ENDPOINTS.HELP_DOMAIN_HELP_CONTACT_FORM, {helpDomainId})
    );
  }

  async createContactForm(data, helpDomainId) {
    return apiService.post(
      replacePathParams(ENDPOINTS.HELP_DOMAIN_HELP_CONTACT_FORM, {helpDomainId}),
      data
    );
  }

  async updateContactForm(data, helpDomainId) {
    return apiService.put(
      replacePathParams(ENDPOINTS.HELP_DOMAIN_HELP_CONTACT_FORM, {helpDomainId}),
      data
    );
  }

  formatDataForRow(links) {
    const allLinks = [];

    links.forEach(link => {
      allLinks.push({
        'rowData': {
          name: link.name,
          label: link.label,
          id: link.id
        }
      });
    });

    return allLinks;
  }
}

export const helpService = new HelpService()
