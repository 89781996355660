<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        Update event pair for {{ eventData.streamEvent.oldTitle }}
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <div class="c-modal-body--wrapper">
        <div class="c-modal-body--container-top">
          <div>
            <h3>Stream event</h3>
            <p class="c-modal-body--container-top__event-name">
              {{ eventData.streamEvent.oldTitle }}
            </p>
            <p>{{ eventData.streamEvent.tournament.name }}</p>
            <p>{{ eventData.streamEvent.startDate | date }}</p>
          </div>
          <div>
            <h3>Paired feed event</h3>
            <template v-if="selectedFeedEvent && selectedFeedEvent.id">
              <p class="c-modal-body--container-top__event-name">
                {{ selectedFeedEvent.oldName }}
              </p>
              <p>{{ selectedFeedEvent.leagueName }}</p>
              <p>{{ selectedFeedEvent.startTime | date }}</p>
              <p class="c-modal-body--container-top__selected-rating">
                <span>Rating: </span>{{ selectedFeedEvent.rating | percentage }}
              </p>
            </template>
            <div v-else>
              <p>No selected event</p>
            </div>
          </div>
        </div>

        <md-field class="c-modal-body--search">
          <label>Search</label>
          <md-input
            v-if="eventData.feedEvents && eventData.feedEvents.length"
            v-model="searchQuery"
          />
        </md-field>

        <div class="c-modal-body--container-bottom">
          <div class="c-table-wrapper l-history-logs__table">
            <md-table v-model="tableData" md-card>
              <md-table-empty-state
                md-label="No events found"
                :md-description="`There are no events matching current criteria`"
              />

              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                class="l-imgarena__table"
                :class="{selected: selectedFeedEvent && item.id === selectedFeedEvent.id, 'u-pointer-none': !canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.IMG_ARENA.key}`)}"
                @click="onSelect(item)"
              >
                <md-table-cell class="selected-icon">
                  <template v-if="item.id === initialPairedFeedId">
                    <md-icon class="icon">
                      checked
                    </md-icon>
                  </template>
                </md-table-cell>
                <md-table-cell md-label="Rating" md-sort-by="rowData.feedEvent" class="rating">
                  {{ item.rating | percentage }}
                </md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="rowData.streamId">
                  {{ item.oldName }}
                </md-table-cell>
                <md-table-cell md-label="League" md-sort-by="rowData.leagueName">
                  {{ item.leagueName }}
                </md-table-cell>
                <md-table-cell md-label="Start Time" md-sort-by="rowData.feedId">
                  {{ item.startTime | date }}
                </md-table-cell>
                <md-table-cell md-label="ID" md-sort-by="rowData.streamEvent" class="feed-id">
                  {{ item.id }}
                </md-table-cell>
                <md-table-cell>
                  <button
                    v-if="selectedFeedEvent && item.id === initialPairedFeedId && canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.IMG_ARENA.key}`)"
                    class="md-icon-button unpair-button"
                    @click.stop="handleUnpairing(item)"
                  >
                    <md-tooltip md-direction="top">
                      Edit pairing
                    </md-tooltip>
                    <md-icon>link_off</md-icon>
                  </button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        :disabled="selectedFeedEvent &&
          selectedFeedEvent.id === initialPairedFeedId
          || selectedFeedEvent === initialPairedFeedId
          || !canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.IMG_ARENA.key}`)
        "
        @click="handleUpdate"
      >
        update
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationBaseMixin} from '@/mixins/ValidationBaseMixin'
import GenericButton from '../../../../../../shared/buttons/GenericButton'
import BaseModalComponent from '../../../../../../shared/modal/BaseModalComponent'
import moment from "moment";
import {imgArenaService} from "@/services/img-arena";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  name: 'UpdateImgArenaPairModal',
  components: {
    GenericButton,
    BaseModalComponent
  },

  filters: {
    date(timeString) {
      return moment(timeString).format('DD/MM/YYYY HH:mm')
    },

    percentage(number) {
      return (number * 100).toFixed(2) + '%';
    }
  },

  mixins: [validationBaseMixin, onlinePermissionsMixin],

  props: {
    eventData: {
      type: Object,
      required: true,
    },
    handleSuccessCallback: {
      type: Function,
      required: false,
      default: () => {
      }
    },
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
      selectedFeedEvent: null,
      searchQuery: '',
      initialPairedFeedId: null,
    };
  },

  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    ...mapGetters("response", ["success", "error"]),

    tableData() {
      let feedEvents = this.eventData.feedEvents;

      if (this.searchQuery) {
        let searchQuery = this.searchQuery.toLowerCase();

        feedEvents = feedEvents.filter(feedEvent => {
          return feedEvent.oldName.toLowerCase().includes(searchQuery)
            || feedEvent.id.includes(searchQuery)
        });
      }

      feedEvents.sort((a, b) => {
        return b.rating - a.rating;
      })

      return feedEvents;
    }
  },

  created() {
    if (this.eventData.manuallyPairedEvent) {
      this.selectedFeedEvent = this.eventData.manuallyPairedEvent.feedEvent.id ? this.eventData.feedEvents.find(feedEvent => feedEvent.id == this.eventData.manuallyPairedEvent.feedEvent.id) : null
    } else {
      this.selectedFeedEvent = this.eventData.pairedFeedEvent || null
    }

    if (!this.selectedFeedEvent) {
      return;
    }

    this.initialPairedFeedId = this.selectedFeedEvent.id
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    close() {
      this.$emit("close");
    },

    async handleUpdate() {
      const payload = {
        streamId: this.eventData.streamEvent.id,
        streamEndDate: this.eventData.streamEvent.endDate,
        feedEventName: this.selectedFeedEvent && this.selectedFeedEvent.oldName ? this.selectedFeedEvent.oldName : '',
        streamEvent: this.eventData.streamEvent,
        feedEvent: this.selectedFeedEvent || {},
      }

      await imgArenaService.updatePairedEvents(payload)
      this.handleSuccess({message: 'Img Arena stream pairing successfully updated'});

      await this.handleSuccessCallback(this.selectedFeedEvent);
      this.$emit("close");
    },

    onSelect(feedEvent) {
      this.selectedFeedEvent = {
        id: feedEvent.id,
        name: feedEvent.name,
        oldName: feedEvent.oldName,
        leagueName: feedEvent.leagueName,
        startTime: feedEvent.startTime,
        rating: feedEvent.rating,
      }
    },

    handleUnpairing() {
      this.selectedFeedEvent = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.c-modal-body--wrapper {
  .meridian-theme-dark & {
    color: white;
  }

  display: flex;
  gap: 10px;
  height: calc(90vh - 182px);
  flex-direction: column;
}

.c-modal-footer {
  padding: 0 15px 15px;
}

.c-modal-body--search {
  width: 100%;
}

.c-modal-body--container-top {
  flex-shrink: 1;
  min-width: 30%;
  padding: 10px;
  position: sticky;
  display: flex;
  gap: 40px;

  .c-modal-body--container-top__selected-rating {
    color: white;
    font-weight: bold;
    font-size: 18px;
    background: darkgreen;
    border-radius: 5px;
    display: inline-block;
    padding: 2px 5px;
    margin: 0;
  }
}

p {
  padding: 0;
  margin: 2px;
}

.c-modal-body--container-top__event-name {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  color: gray;
  margin: 10px 0;
}

.c-modal-body--container-bottom {
  flex-grow: 1;
  padding: 0;
  max-height: 600px;
  overflow-y: scroll;
}

.md-table-row {
  cursor: pointer;

  &.selected {
    .meridian-theme-dark & {
      background: #19a34b29;
    }

    outline: 2px solid darkgreen;
    background: #F4FFF8;
    box-shadow: 5px 0px 0px 0px green, 5px 0px 0px 0px green;
  }
}

.rating, .feed-id, .selected-icon {
  width: 50px !important;
}

.selected-icon {
  .md-table-cell-container {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
  }
}

.unpair-button {
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  margin: 0 !important;

  &:hover {
    opacity: .8;
  }
}

</style>
