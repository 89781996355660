<template>
  <div id="wysywigWrapper" ref="wrapper" class="wrapper">
    <div ref="editorBox" class="checkbox-wrapper">
      <md-checkbox v-model="rawHtmlDisplay" class="checkbox-item" @change="scrollToEditor">
        Switch HTML Editor
      </md-checkbox>
      <md-checkbox v-if="splitPreview && rawHtmlDisplay" v-model="fullscreen" class="checkbox-item">
        Full Screen
      </md-checkbox>
    </div>
    <template v-if="!splitPreview">
      <wysiwyg
        v-if="!rawHtmlDisplay && !disabled"
        class="editor"
        :html="value"
        :disabled="disabled"
        @change="update"
      />
      <textarea
        v-if="rawHtmlDisplay || disabled"
        class="editor"
        :value="value"
        :disabled="disabled"
        @input="update($event.target.value)"
      />
    </template>

    <template v-if="splitPreview">
      <wysiwyg
        v-if="!rawHtmlDisplay && !disabled"
        class="editor"
        :html="value"
        :disabled="disabled"
        @change="update"
      />
      <div
        v-if="rawHtmlDisplay || disabled"
        ref="fullscreen"
        class="wysiwyg-display"
        :class="{ fullscreen: fullscreen }"
      >
        <div v-if="splitPreview && fullscreen" class="exit-button" @click="fullscreen = false">
          <span>x</span>
        </div>
        <div style="flex: 1;">
          <textarea
            :style="{'height': '100%'}"
            class="editor"
            :value="value"
            :disabled="disabled"
            @input="update($event.target.value)"
          />
        </div>
        <div class="c-item" style="flex: 1;">
          <div class="preview" :class="{'u-full-height': fullscreen, 'preview-disabled': disabled}" v-html="value" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'WysiwygComponent',
  props: {
    value: {
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    splitPreview: {
      required: false,
      default: false,
      type: Boolean,
    }
  },

  data() {
    return {
      rawHtmlDisplay: false,
      fullscreen: false,
    }
  },

  watch: {
    fullscreen(newVal) {
      if (newVal) {
        document.getElementById('modals-container').appendChild(this.$refs.fullscreen);

        return;
      }

      document.getElementById('wysywigWrapper').appendChild(this.$refs.fullscreen);
    }
  },

  methods: {
    update(newValue) {
      this.$emit('input', newValue);
    },

    scrollToEditor() {
      setTimeout(() => {
        if (this.splitPreview && this.rawHtmlDisplay) {
            this.$refs.editorBox.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    },
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.editor {
  min-height: 194px;
  width: 100%;
  resize: none;
  padding: 5px;
  border-radius: .4rem;
  overflow: scroll;
  margin-bottom: 1rem;
}

textarea {
  outline: red;
}

.md-checkbox {
  display: flex;
  align-items: center;
}

.wysiwyg-display {
  margin-bottom: 5rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 80%;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9); /* bela pozadina sa malo prozirnosti */
    z-index: 1000; /* da bude iznad ostalih elemenata */
    overflow-y: auto; /* omogućava skrolovanje ako je sadržaj veći od visine ekrana */
    padding: 3rem 1rem;
    box-sizing: border-box;
    backdrop-filter: blur(5px);
}

.checkbox-wrapper {
  display: flex;
  padding: 0;
}

.checkbox-item {
  padding: 0;
}

.exit-button {
  background-color: #c43d2f;
  color: white;
  font-size: 18px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: background-color 0.2s linear;

  span {
    margin-bottom: 2px;
  }

  &:hover{
    background-color: #aa372a;
  }
}

.c-item {
  overflow-y: scroll;
}

.preview {
  background-color: #16232A;
  border: 1px solid black;
  padding: 5px;
  border-radius: .4rem;
  height: 100%;
  color: white;

  &.preview-disabled {
    pointer-events: none;
  }
}


</style>

