import { apiService } from '../api'

const ENDPOINTS = {
  CASINO_CATEGORIES: '/casino-category',
}

class CasinoCategoriesService {
  async create(data) {
    return apiService.post(ENDPOINTS.CASINO_CATEGORIES, data)
  }

  async update(id, data) {
    return apiService.put(`${ ENDPOINTS.CASINO_CATEGORIES }/${ id }`, data)
  }

  async delete(id) {
    return apiService.delete(`${ ENDPOINTS.CASINO_CATEGORIES }/${ id }`);
  }

  async getAll() {
    return apiService.get(ENDPOINTS.CASINO_CATEGORIES);
  }

  prepareCasinoCategoriesDataFromResponse(items) {
    const casinoCategories = [];

    items.forEach(casinoCategory => {
      casinoCategories.push({
        page: casinoCategory,
        rowData: {
          name: casinoCategory.name,
        }
      });
    });

    return casinoCategories;
  }
}

export const casinoCategoriesService = new CasinoCategoriesService()
