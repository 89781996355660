<template>
  <div class="l-main">
    <md-tabs class="c-tabs c-tabs--small" :md-active-tab="currentLanguage.code">
      <md-tab
        v-for="language in languages"
        :id="language.code"
        :key="language.code"
        :md-label="language.code"
        @click="changeLanguage(language)"
      />
    </md-tabs>

    <div class="l-main__ctn">
      <h2 v-if="fetchedResults && seoTemplates && seoTemplates.length === 0">
        No results found
      </h2>

      <div class="c-table-wrapper">
        <base-table-component :prop-data="seoRulesTableData">
          <template v-slot:action>
            <div class="header-table-section">
              Seo Templates
            </div>
          </template>
          <template v-slot:default="slotProps">
            <generic-button
              icon
              transparent
              @click="editSeoRule(slotProps.selected.seoRule)"
            >
              <md-tooltip md-direction="top">
                Edit seo rule
              </md-tooltip>
              <md-icon>arrow_forward_ios</md-icon>
            </generic-button>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>
import GenericButton from '../../../../../shared/buttons/GenericButton';
import BaseTableComponent from '@/shared/table/BaseTableComponent'
import EditSeoRuleModal from '../content-edit/edit'
import { capitalize, get } from 'lodash'
import { jsonParse } from '@/helpers/transformers'
import { seoRulesService } from '@/services/seo-rules'
import { languageService } from '@/services/language'

export default {
  name: "Templates",

  components: {
    GenericButton,
    BaseTableComponent
  },

  props: {
    website: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      seoTemplates: [],
      fetchedResults: false,
      languages: [],
      currentLanguage: {},
      defaultLanguageId: null,
    };
  },

  computed: {
    seoRulesTableData() {
      if (!this.seoTemplates) {
        return [];
      }

      return this.seoTemplates.map(seoRule => ({
        seoRule: seoRule,
        rowData: {
          name: capitalize(seoRule.translation.replace('-', ' ')),
          'Page Title - H1': seoRule.pageTitle,
          'Meta Title': seoRule.metaTitle,
          'Meta Description': seoRule.metaDescription
        }
      }));
    },
  },

  async created() {
    await this.getAvailableLanguages();
    await this.getTemplates()
  },

  methods: {
    async getTemplates() {
      const searchConstraints = {
        templates: true,
        websiteId: this.website.id
      }

      const searchResponse = await seoRulesService.search(this.currentLanguage.id, searchConstraints)

      this.seoTemplates = searchResponse.data;

      this.fetchedResults = true;
    },

    changeLanguage(language) {
      this.currentLanguage = language;
      this.getTemplates();
    },

    async getAvailableLanguages() {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguages = jsonParse(get(this, 'website.betshop.market.additionalLanguages')) || [];
      const defaultLanguage = get(this, 'website.betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguage, ...additionalLanguages])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code))
      this.currentLanguage = this.languages[0];
      this.defaultLanguageId = this.languages.find(language => language.code === 'en')?.id
    },

    editSeoRule(seoRule) {
      this.$modal.show(
          EditSeoRuleModal,
          {
            seoRule,
            website: this.website,
            language: this.currentLanguage,
            handleSuccessCallback: this.getTemplates
          },
          { height: 'auto', scrollable: true, width: '80%' }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.c-search-filter-row {
  display: flex;

  .u-mr-md {
    margin-right: 16px !important;
  }
}

.c-search-additional-fields {
  display: flex;
  align-items: baseline;

  > div {
    margin-right: 10px;
  }
}
</style>
