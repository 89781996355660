<template>
  <div class="l-main">
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-websites">
        <template>
          <div class="header-table-section">
            Help Links
          </div>
          <div class="add-button">
            <div>
              List of help links
            </div>
            <div class="add-section">
              <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateHelpLink()"><span>Add New Link</span>
            </div>
          </div>
        </template>
        <base-table-component :prop-data="tableData">
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                @click="createOrUpdateHelpLink(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top"> Edit </md-tooltip>
                <img src="../../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>

            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top"> Delete </md-tooltip>
                <img src="../../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import BaseTableComponent from '../../../../../shared/table/BaseTableComponent'
import DeleteModalComponent from '../../../../../shared/delete-modal/DeleteModalComponent'
import CreateHelpLinkModal from '../../../help/sub-pages/help-links/create'
import get from 'lodash/get'
import GenericButton from '../../../../../shared/buttons/GenericButton'
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {helpService} from "@/services/help";

export default {
  name: "HelpLinkList",

  components: {
    BaseTableComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      onlyDevelopersAllowed: true,
      allLinksTableData: []
    }
  },

  computed: {
    ...mapGetters('helpLinks', ['helpLinks']),

    tableData() {
      return helpService.formatDataForRow(this.helpLinks);
    }
  },

  created() {
    this.getHelpLinks();
  },

  methods: {
    ...mapActions('helpLinks', [
      'getHelpLinks',
      'createHelpLink',
      'updateHelpLink',
      'deleteHelpLink'
    ]),
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    userCanSee(tab) {
      return this.userWriteableSections.includes(tab)
          || this.userReadableSections.includes(tab)
    },

    openDeleteModal(selected) {
      this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: selected.label,
            propData: selected.id,
            deleteFunction: this.deleteHelpLink,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },

    createOrUpdateHelpLink(helpLink = {}) {
      this.$modal.show(
        CreateHelpLinkModal,
          {
            helpLink: {
              name: get(helpLink, 'name', ''),
              label: get(helpLink, 'label', ''),
            },
            helpLinkId: get(helpLink, 'id'),
          },
          { height: "auto", scrollable: true }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
