<template>
  <md-app>
    <md-app-toolbar
      class="md-primary home-header"
      md-elevation="0"
    >
      <md-menu
        md-size="medium"
        md-align-trigger
        class="u-ml-auto"
      >
        <generic-button
          icon
          transparent
          md-menu-trigger
        >
          <md-icon>settings</md-icon>
        </generic-button>

        <md-menu-content>
          <div class="u-ph-md u-pv-xs">
            <md-switch
              v-model="nightMode"
              @change="handleThemeToggle"
            >
              Dark mode
            </md-switch>
          </div>
          <md-menu-item @click="changePassword">
            Change password
          </md-menu-item>
          <md-menu-item @click="logout">
            Logout
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-app-toolbar>

    <md-app-drawer
      :md-active="true"
      md-persistent="mini"
      class="c-drawer"
    >
      <button
        class="c-drawer__logo-wrapper"
        @click="goToHome"
      >
        <div class="c-drawer__logo">
          <img
            class="u-max-width-15"
            src="../../assets/betty-logo.svg"
          >
        </div>
      </button>
      <md-list>
        <md-list-item
          v-for="sidebarItem in sidebarItems"
          :key="sidebarItem.pathName"
          class="md-list-item-router md-list-item-container md-button-clean"
          :to="{ name: sidebarItem.pathName }"
          :md-expand="!!(sidebarItem.subItems && sidebarItem.subItems.length)"
          :md-expanded.sync="sidebarItem.expanded"
        >
          <span class="md-list-item-text">{{ sidebarItem.text }}</span>

          <md-list slot="md-expand">
            <md-list-item
              v-for="subItem in sidebarItem.subItems"
              :key="subItem.pathName"
              class="md-list-item-router md-list-item-container md-button-clean"
              :to="{ name: subItem.pathName }"
            >
              <span class="md-list-item-text md-list-item-text--subitems">{{ subItem.text }}</span>
            </md-list-item>
          </md-list>
        </md-list-item>
      </md-list>
    </md-app-drawer>

    <md-app-content class="l-home">
      <router-view />
      <snack-bar />
    </md-app-content>
  </md-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SnackBar from '../../shared/snackebar/SnackeBar';
import { authService } from '../../services/auth';
import ChangePasswordModal from '../change-password';
import GenericButton from '../../shared/buttons/GenericButton';
import {defaultOnLineSidebarItems} from "@/const/on-line-sidebar-items";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";

export default {
  components: {
    SnackBar,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      translationSelected: false,
      configurationsSelected: false,
      manageAppSelected: false,
      sidebarItems: [],
    };
  },

  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('theme', ['isNightMode']),

    nightMode: {
      get() {
        return this.isNightMode;
      },
      set(mode) {
        return mode;
      },
    },
  },

  created() {
    this.sidebarItems = this.filterSidebarItems();
    this.setInitialExpandedSidebarItem();

    if (this.$router.currentRoute.name === 'home' && !this.isAdmin()) {
      const firstAllowedRoutePathName = this.getFirstAllowedRoutePathName();
      this.$router.push({name: firstAllowedRoutePathName});
    }

    if (this.$router.currentRoute.name === 'home' && this.isAdmin()) {
      this.$router.push({name: 'websites'});
    }
  },

  methods: {
    ...mapActions('theme', ['handleThemeToggle']),

    logout() {
      authService.logout(this.user.email);
    },

    changePassword() {
      this.$modal.show(
        ChangePasswordModal,
        {},
        { height: 'auto', scrollable: true }
      );
    },

    goToHome() {
      const firstAllowedRoutePathName = this.getFirstAllowedRoutePathName();

      if (this.$router.currentRoute.name !== firstAllowedRoutePathName && !this.isAdmin()) {
        this.$router.push({name: firstAllowedRoutePathName});
      }

      if (this.$router.currentRoute.name !== 'websites' && this.isAdmin()) {
        this.$router.push({name: 'websites'});
      }
    },

    filterSidebarItems() {
      return defaultOnLineSidebarItems.filter(sidebarItem => {
        if (sidebarItem.onlyAdmins) {
          return this.isAdmin();
        }

        if (sidebarItem.onlyDevelopers) {
          return this.isDeveloper();
        }

        if (sidebarItem.requiredPermissions) {
          return sidebarItem.requiredPermissions.some(permission => this.canRead(permission))
        }

        return true;
      })
    },

    setInitialExpandedSidebarItem() {
      const currentRouteName = this.$route.name;

      this.sidebarItems.forEach(sidebarItem => {
        if (sidebarItem.subItems && sidebarItem.subItems.some(sidebarSubItem => sidebarSubItem.pathName === currentRouteName)) {
          sidebarItem.expanded = true;
        }
      });
    }
  },

};
</script>

<style lang="scss">
.l-home {
  height: calc(100vh - 64px);
  overflow: hidden;
  background-color: map-get($content-background, lightTheme) !important;
  padding: 0;

  @include dark-theme {
    background-color: map-get($content-background, darkTheme) !important;
  }

  @media (max-width: 960px){
    height: calc(100vh - 48px);
  }
}
</style>
