<template>
  <div>
    <base-modal-component>
      <template v-slot:header>
        <div class="u-flex-column">
          <h4 class="u-typography-color md-title">
            {{ update }} Tooltips
          </h4>
        </div>
      </template>

      <template v-slot:body>
        <md-field class="u-mb-md">
          <label>Translation Phrase Key</label>
          <md-input
            :value="singleTooltip.phrase_key"
            name="phraseKey"
            type="text"
            class="md-input"
            autocomplete="off"
            @input="handlePhraseKeyChange($event)"
          />
          <generic-button
            :disabled="!singleTooltip.phrase_key || (duplicatedPhraseKey.length !== 0)"
            @click="openTranslationPanel(0)"
          >
            Translations
          </generic-button>
        </md-field>
        <p v-if="duplicatedPhraseKey" class="md-error">
          Phrase Key {{ duplicatedPhraseKey }} already exists.
        </p>
        <md-field
          v-for="(templateId, index) in singleTooltip.template_ids"
          :key="'templateindex' + index"
          class="u-mb-md"
        >
          <label>Template Id</label>
          <md-input
            v-model="singleTooltip.template_ids[index]"
            name="templateId"
            type="number"
            class="md-input"
            autocomplete="off"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
          <generic-button
            v-if="index"
            class="u-mr-md"
            icon
            transparent
            @click="deleteTemplateId(index)"
          >
            <img src="../../../../../../../assets/delete-button.svg" alt="">
          </generic-button>
        </md-field>
        <p v-if="duplicatedTemplateIds" class="md-error">
          Template id {{ duplicatedTemplateIds }} already exists for this sport
        </p>
        <generic-button
          variation="grey"
          class="u-mr-md"
          @click="addNewTemplateId"
        >
          Add new Template Id
        </generic-button>
        <md-field
          v-for="(tooltip, index) in singleTooltip.tooltips"
          :key="'selectionIndex' + index"
          class="u-mb-md"
        >
          <label>Selection tooltip</label>
          <md-input
            :value="tooltip.selection_position"
            name="selection"
            type="text"
            class="md-input"
            autocomplete="off"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            @input="handlePositionChange(tooltip, $event)"
          />
          <generic-button
            v-if="index"
            class="u-mr-md"
            icon
            transparent
            @click="deleteSelection(index)"
          >
            <img src="../../../../../../../assets/delete-button.svg" alt="">
          </generic-button>
          <generic-button
            :disabled="!tooltip.selection_position || (duplicatedPhraseKey.length !== 0)"
            @click="openTranslationPanel(index + 1)"
          >
            Translations
          </generic-button>
        </md-field>
        <generic-button
          variation="grey"
          class="u-mr-md"
          @click="addNewSelection"
        >
          Add new Selection
        </generic-button>
        <p v-if="duplicatedSelectionIds" class="md-error">
          Duplicated {{ duplicatedSelectionIds }} selection id
        </p>
      </template>

      <template v-slot:footer>
        <div class="u-text-align-right">
          <div class="u-flex">
            <generic-button
              variation="grey"
              class="u-mr-md"
              @click="handleClose"
            >
              Close
            </generic-button>
            <generic-button
              variation="red"
              :disabled="formInvalid()"
              @click="submit"
            >
              {{ update }}
            </generic-button>
          </div>
        </div>
      </template>
    </base-modal-component>
    <div class="page-container md-layout-column">
      <md-drawer class="md-right" :md-active.sync="showTranslationPanel">
        <md-toolbar class="md-transparent  u-flex-justify-between" md-elevation="0">
          <span class="md-title">Translations</span>
          <div>
            <generic-button
              variation="grey"
              class="u-mr-md"
              @click="showTranslationPanel = false"
            >
              Cancel
            </generic-button>
            <generic-button
              variation="red"
              class="u-mr-md"
              @click="addToTemporaryTranslations"
            >
              {{ fetchedTooltip ? 'Save' : 'Add' }}
            </generic-button>
          </div>
        </md-toolbar>

        <md-list
          v-if="selectedWord.translation"
        >
          <md-list-item
            v-for="(language, index) in languages"
            :key="language.id"
            class="md-list-item-text"
          >
            <md-field>
              <label>{{ language.name }}</label>
              <md-input
                v-if="selectedWord.translation[index]"
                v-model="selectedWord.translation[index].phrase"
                name="translation"
                type="text"
                class="md-input"
                autocomplete="off"
              />
            </md-field>
          </md-list-item>
        </md-list>
      </md-drawer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { tooltipsService } from '@/services/tooltips';
import GenericButton from '@/shared/buttons/GenericButton'
import BaseModalComponent from '@/shared/modal/BaseModalComponent'
import { responseService } from '@/services/response'
import includes from "lodash/includes";
import filter from "lodash/filter";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { wordService } from "@/services/words";
import { translationService } from "@/services/translation";
import ConfirmationModalComponent from '@/shared/confirmation-modal/ConfirmationModalComponent';
import DeleteModalComponent from '@/shared/delete-modal/DeleteModalComponent.vue';

export default {
  name: 'EditTooltipsModal',
  components: {
    GenericButton,
    BaseModalComponent
  },

  props: {
    fetchedTooltip: {
      type: Object,
      required: false,
      default: () => {}
    },
    handleSuccessCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    allTooltips: {
      type: Array,
      required: false,
      default: () => []
    },
    tooltipIndex: {
      type: Number,
      required: false,
      default: 0
    },
    sportId: {
      type: Number,
      required: true,
      default: 0
    },
    websiteIds: {
      type: String,
      required: false,
      default: '[]'
    },
    listOfPhraseKeys: {
      type: Array,
      required: false,
      default: () => []
    },
    listOfTemplateIds: {
      type: Array,
      required: false,
      default: () => []
    },
    languages: {
      type: Array,
      required: true,
      default: () => []
    },
    tooltipSection: {
      type: Object,
      required: true,
      default: () => {}
    },
  },

  data() {
    return {
      singleTooltip: {
        phrase_key: '',
        template_ids: [''],
        tooltips: [
          {
            selection_position: '1'
          },
          {
            selection_position: '2'
          },
          {
            selection_position: '3'
          },
        ],
      },
      listOfOtherTemplateIds: [],
      listOfOtherPhraseKeys: [],
      duplicatedTemplateIds: '',
      duplicatedPhraseKey: '',
      duplicatedSelectionIds: '',
      translations: {},
      showTranslationPanel: false,
      selectedWord: {
        translation: []
      },
      listOfTemporaryWords: [],
      listOfDeletedSelections: [],
      translationKeysUpdated: false,
      sectionIndexForDeleteOnUpdate: null,
    };
  },

  computed: {
    ...mapGetters("response", ["success", "globalError"]),

    update() {
      return !this.fetchedTooltip ? 'Create' : 'Update';
    },
  },

  created() {
    this.setTooltip();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError"]),

    async setTooltip() {
      if (this.fetchedTooltip) {
        this.singleTooltip = this.fetchedTooltip;
        this.listOfOtherTemplateIds = this.listOfTemplateIds.filter((id) => {
          return !includes(this.fetchedTooltip.template_ids, id)
        })
        this.listOfOtherPhraseKeys = this.listOfPhraseKeys.filter((key) => key !== this.singleTooltip.phrase_key)
        await this.fetchTranslations(this.singleTooltip.phrase_key)
        return;
      }

      this.listOfOtherTemplateIds = this.listOfTemplateIds;
      this.listOfOtherPhraseKeys = this.listOfPhraseKeys;
    },

    addNewSelection() {
      this.singleTooltip.tooltips.push({
        selection_position: '',
        new_position: true,
      });
    },

    addNewTemplateId() {
      this.singleTooltip.template_ids.push('');
    },

    deleteSelection(index) {
      if (!this.fetchedTooltip) {
        const selection = this.singleTooltip.tooltips[index].selection_position;
        this.singleTooltip.tooltips.splice(index, 1);

        if (!selection) {
          return;
        }

        this.deleteTemproraryTranslationsForSelection(index);
        return;
      }

      this.sectionIndexForDeleteOnUpdate = index;
      const selection = this.singleTooltip.tooltips[index];
      this.openDeleteModal(selection);
    },

    openDeleteModal(selection) {
        this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: get(selection, 'selection_position', ''),
            deleteFunction: this.handleDeleteSectionOnUpdate
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
      },

    handleDeleteSectionOnUpdate() {
      if (this.singleTooltip.tooltips[this.sectionIndexForDeleteOnUpdate].new_position) {
        this.singleTooltip.tooltips.splice(this.sectionIndexForDeleteOnUpdate, 1);
        return;
      }

      this.singleTooltip.tooltips[this.sectionIndexForDeleteOnUpdate].delete_section = true;
      this.handleUpdateTooltip(false);
      this.singleTooltip.tooltips.splice(this.sectionIndexForDeleteOnUpdate, 1);
      this.allTooltips[this.tooltipIndex] = this.singleTooltip;
    },

    deleteTemplateId(index) {
      this.singleTooltip.template_ids.splice(index, 1);
    },

    async handleCreateTooltip() {
      const createTooltipsData = [...this.allTooltips, this.singleTooltip];

      const preparedTranslationsData = this.prepareTranslations();
      const createData = {
        sportId: this.sportId,
        websiteIds: this.websiteIds,
        tooltips: JSON.stringify(createTooltipsData)
      }
      let translationSavedKeysData;
      try {
        const response = await tooltipsService.checkTooltipAndTranslationAvailable(this.singleTooltip.phrase_key);
        if (get(response, 'data.key_exist') || get(response, 'data.translation_exist')) {
          this.duplicatedPhraseKey = this.singleTooltip.phrase_key;
          return;
        }

        if (preparedTranslationsData.length > 0) {
          translationSavedKeysData = await translationService.saveOnlineBatch(preparedTranslationsData);
        }
        await tooltipsService.update(this.sportId, createData);
        this.handleSuccess({ message: 'Successfully created Tooltip' });
        this.close();
      } catch (error) {
        if (this.getDeleteLastSavedTranslations(translationSavedKeysData)) {
          await translationService.deleteByTooltipKeys(get(translationSavedKeysData, 'data', []));
        }
        this.setGlobalError(error.data?.message);
      }
    },

    async handleUpdateTooltip(canCloseModal = true, showSuccessMessage = true) {
      if (!this.allTooltips || !this.allTooltips[this.tooltipIndex]) {
        this.close();

        return;
      }

      const needToCheckKey = this.doesKeyNeedToCheck();
      let dataOfChackedKey;

      if (needToCheckKey) {
        dataOfChackedKey = await tooltipsService.checkTooltipAndTranslationAvailable(this.singleTooltip.phrase_key);
      }

      if (needToCheckKey && (get(dataOfChackedKey, 'data.key_exist') || get(dataOfChackedKey, 'data.translation_exist'))) {
        this.duplicatedPhraseKey = this.singleTooltip.phrase_key;
        return;
      }

      const updateTooltipsData = [...this.allTooltips];
      updateTooltipsData[this.tooltipIndex] = this.singleTooltip;
      this.allTooltips[this.tooltipIndex] = this.singleTooltip;
      const JsonTooltipsData = JSON.stringify(updateTooltipsData)
      const updateData = {
        sportId: this.sportId,
        websiteIds: this.websiteIds,
        tooltips: JsonTooltipsData
      }

      try {
        await tooltipsService.update(this.sportId, updateData);
        showSuccessMessage && this.handleSuccess({ message: 'Successfully updated Tooltip' });
        this.resetSingleTooltip();

        if (canCloseModal) {
          this.close()
        }
      } catch (error) {
        this.setGlobalError(responseService.getErrorMessage(error.data?.message));
      }
    },

    async submit() {
      if (this.formInvalid()) {
        return;
      }

      this.duplicatedPhraseKey = '';
      this.duplicatedTemplateIds = '';
      this.duplicatedSelectionIds = '';
      const duplicatedTemplateIds = filter([...this.listOfOtherTemplateIds, ...this.singleTooltip.template_ids], (val, i, iteratee) => includes(iteratee, val, i + 1));
      const duplicatedPhraseKey = find(this.listOfOtherPhraseKeys, (key) => key === this.singleTooltip.phrase_key);
      const duplicatedSelectionIds = filter(map(this.singleTooltip.tooltips, (tooltip) => tooltip.selection_position), (val, i, iteratee) => includes(iteratee, val, i + 1));
      if (duplicatedTemplateIds.length || duplicatedPhraseKey || duplicatedSelectionIds.length) {
        this.duplicatedTemplateIds = duplicatedTemplateIds.join(', ');
        this.duplicatedPhraseKey = duplicatedPhraseKey || '';
        this.duplicatedSelectionIds = duplicatedSelectionIds.join(', ');

        return;
      }

      !this.fetchedTooltip ? await this.handleCreateTooltip() : await this.handleUpdateTooltip();
      this.handleSuccessCallback();
    },

    close() {
      this.resetError();
      this.$emit("close");
    },

    formInvalid() {
      return !this.singleTooltip.phrase_key ||
        this.singleTooltip.template_ids.some(id => !id) ||
        this.singleTooltip.tooltips.some(tooltip => !tooltip.selection_position);
    },

    async openTranslationPanel(index) {
      this.scrollToTop();
      if (this.translationKeysUpdated) {
        await this.handleUpdateTooltip(false, false);
        if (this.duplicatedPhraseKey) {
          return;
        }

        await this.setTooltip();
        this.translationKeysUpdated = false;
      }
      

      this.showTranslationPanel = !this.showTranslationPanel;

      this.fetchedTooltip ?
        this.setSelectedWordOnTranslationEditModalOpen(index) :
        this.setSelectedWordOnTranslationCreateModalOpen(index);

      this.handleSuccessCallback();
    },

    async fetchTranslations(term, currentPage = 1, limit = 200) {
      const response = await wordService.getOnlineAll(currentPage, limit, 'tooltips', term)

      const filteredTranslations = response.data.items.filter((single) => {
        let r = new RegExp(`^${term}$`);
        return single.key.match(r) || (includes(single.key, `_${term}_`));
      });

      this.translations = filteredTranslations.reduce((responseTranslation, single) => {
        responseTranslation[single.key] = single
        return responseTranslation
      }, {});
    },

    getTranslationPhraseKeyFromSelection(selection, key = this.singleTooltip.phrase_key) {
      return `${this.sportId}_${key}_${selection}`
    },

    deleteWord(id) {
      return wordService.deleteOnline(id);
    },

    async saveOrUpdate(translationData) {
      return translationData.wordId ?
        await translationService.updateOnline(translationData) :
        await translationService.saveOnline(translationData);
    },

    async saveOrUpdateTranslation(translationData) {
      try {
        delete translationData.languagesIds;
        await this.saveOrUpdate(translationData);
      } catch (error) {
        this.setGlobalError(responseService.getErrorMessage(error.data?.message));
      }
    },

    setTranslationsForGivenLanguages() {
      if (this.languages.length === 0) {
        return;
      }

      if (this.selectedWord.length > 0) {
        return;
      }

      this.languages.forEach(language => {
        if (!this.selectedWord.wordId) {
          return this.selectedWord.translation.push({ languageId: language.id, phrase: "" });
        }

        if (!this.selectedWord.languagesIds.includes(language.id)) {
          this.selectedWord.translation.push({ languageId: language.id, phrase: "" });
        }
      });
    },

    handlePositionChange(tooltip, newValue) {
      if (this.fetchedTooltip) {
        this.translationKeysUpdated = true;
      }

      if (this.fetchedTooltip && !tooltip.old_value) {
        tooltip.old_value = tooltip.selection_position;
      }

      tooltip.selection_position = newValue;
    },

    handlePhraseKeyChange(newValue) {
      if (this.fetchedTooltip) {
        this.translationKeysUpdated = true;
      }

      if (this.fetchedTooltip && !this.singleTooltip.old_value) {
        this.singleTooltip.old_value = this.singleTooltip.phrase_key;
      }

      this.singleTooltip.phrase_key = newValue;
      this.duplicatedPhraseKey = '';
    },

    async addToTemporaryTranslations() {
      if (this.fetchedTooltip) {
        this.selectedWord.sectionId = this.selectedWord.section.id
        await this.saveOrUpdateTranslation(this.selectedWord);
        this.showTranslationPanel = false;
        this.fetchTranslations(this.singleTooltip.phrase_key)
        return;
      }

      if (!this.listOfTemporaryWords.length) {
        this.listOfTemporaryWords.push(this.selectedWord);
        this.showTranslationPanel = false;

        return;
      }

      const index = findIndex(this.listOfTemporaryWords, (word) => {
        return word.word === this.selectedWord.word
      });

      if (index === -1) {
        this.listOfTemporaryWords.push(this.selectedWord);
        this.showTranslationPanel = false;

        return;
      }

      this.listOfTemporaryWords[index] = this.selectedWord;
      this.showTranslationPanel = false;
    },

    handleClose() {
      if (this.listOfTemporaryWords.length) {
        this.openConfirmationModal();

        return;
      }

      this.close();
    },

    openConfirmationModal() {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Your changes to translations will be lost,
              are you sure you want to continue?`,
            onAccept: this.close,
            onClose: () => {}
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },

    deleteTranslationByPhraseKey(selection) {
      const word = this.translations[this.getTranslationPhraseKeyFromSelection(selection)] ||
        find(this.listOfTemporaryWords, tempWord => tempWord.word === this.getTranslationPhraseKeyFromSelection(selection));
      const id = word?.wordId || word?.id;
      if (id) {
        this.deleteWord(id);
      }
    },

    prepareTranslations() {
      const translatinData = [];
      const translationPhraseKeyData = find(this.listOfTemporaryWords, {index: 0});

      translatinData.push({
        translation: translationPhraseKeyData ? translationPhraseKeyData.translation : [],
        word: `${this.singleTooltip.phrase_key}`,
      });

      each(this.singleTooltip.tooltips, (singleWord, index) => {
        const translationTooltipPhraseKeyData = find(this.listOfTemporaryWords, {index: (index + 1)});

        translatinData.push({
          translation: translationTooltipPhraseKeyData ? translationTooltipPhraseKeyData.translation : [],
          word: `${this.sportId}_${this.singleTooltip.phrase_key}_${singleWord.selection_position}`,
        });
      });

      return translatinData;
    },

    getDeleteLastSavedTranslations(translationSavedKeysData) {
     return translationSavedKeysData && get(translationSavedKeysData, 'data') && !get(translationSavedKeysData, 'message');
    },

    deleteTemproraryTranslationsForSelection(index) {
      const tempIndex = findIndex(this.listOfTemporaryWords, tempWord =>
        tempWord.index === (index + 1)
      );

      each(this.listOfTemporaryWords, (word) => {
        if ((index + 1) >= word.index) {
          return;
        }
        word.index--;
      })

      if (tempIndex === -1) {
        return;
      }

      this.listOfTemporaryWords.splice(tempIndex, 1);
    },

    setSelectedWordOnTranslationEditModalOpen(index) {
      const key = index === 0
          ? this.singleTooltip.phrase_key
          : `${this.sportId}_${this.singleTooltip.phrase_key}_${this.singleTooltip.tooltips[index - 1].selection_position}`;

      const newWord = {
        key: index,
        section: this.tooltipSection,
        translation: []
      };

      const testWord = this.translations[key] ? this.translations[key] : newWord;

      const formattedWord = translationService.formatData(testWord, this.languages)
      formattedWord.index = index;
      this.selectedWord = cloneDeep(formattedWord);
    },

    setSelectedWordOnTranslationCreateModalOpen(index) {
      const temporaryWordIndex = this.listOfTemporaryWords.findIndex(word => word.index === index);

      const newWord = {
          key: index,
          section: this.tooltipSection,
          translation: []
        };

      if (temporaryWordIndex !== -1) {
        this.selectedWord = cloneDeep(this.listOfTemporaryWords[temporaryWordIndex]);
        return;
      }

      const testWord = this.translations[index] ? this.translations[index] : newWord
      const formattedWord = translationService.formatData(testWord, this.languages)
      formattedWord.index = index;
      this.selectedWord = cloneDeep(formattedWord);
      this.translations[index] && this.setTranslationsForGivenLanguages();
    },

    resetSingleTooltip() {
      if (this.singleTooltip.old_value) {
        delete this.singleTooltip.old_value;
      }

      each(this.singleTooltip.tooltips, selectionTooltip => {
        if (!selectionTooltip.old_value) {
          return;
        }

        delete selectionTooltip.old_value;
      })
    },
    scrollToTop() {
      const scrollableElement = get(document.getElementsByClassName('scrollable'), '[0]');
      if (!scrollableElement) {
        return;
      }
    
      scrollableElement.scrollTo({ top: 0, behavior: 'smooth' })
    },
    doesKeyNeedToCheck() {
      return !isEmpty(this.singleTooltip.old_value) && (this.singleTooltip.old_value !== this.singleTooltip.phrase_key);
    }
  },
};
</script>

<style lang="scss" scoped>
.u-mr-md {
  margin-right: $md !important;
}

.u-mb-md {
  margin-bottom: $md !important;
}
</style>
