<template>
  <div style="display: flex; flex-direction: column; min-height: 0; flex: 1; height: 100%;">
    <template v-if="getSelectedDomainId">
      <template v-if="!showEditOrDeleteComponent">
        <div class="add-button">
          <div v-if="categories && categories.length" class="c-search">
            <md-field class="u-width-m u-mb-xs u-mr-md">
              <label>Search</label>
              <md-input v-model="searchQuery" />
            </md-field>
          </div>
          <div v-if="canWriteHelpWebsite(getSelectedWebsiteId)" class="add-section">
            <img src="@/assets/add-button.svg" class="add-button__new" alt="" @click="openCreateOrUpdateForm()">
            <span>Add New Category</span>
          </div>
        </div>

        <template v-if="tableData.length">
          <div class="c-table-wrapper">
            <base-table-component
              :prop-data="tableData"
              :draggable="!searchQuery && canWriteHelpWebsite(getSelectedWebsiteId)"
              @dragSort="handleDragSort"
            >
              <template v-slot:default="slotProps">
                <span class="c-table__btn-wrap">
                  <generic-button
                    icon
                    transparent
                    text-inherit
                    @click="handleEdit(slotProps.selected.category)"
                  >
                    <md-tooltip md-direction="top"> Edit </md-tooltip>
                    <img src="@/assets/edit-button.svg" alt="">
                  </generic-button>
                </span>

                <span class="c-table__btn-wrap">
                  <generic-button
                    icon
                    transparent
                    text-inherit
                    :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
                    @click="openDeleteModal(slotProps.selected.category)"
                  >
                    <md-tooltip md-direction="top"> Delete </md-tooltip>
                    <img src="@/assets/delete-button.svg" alt="">
                  </generic-button>
                </span>
              </template>
            </base-table-component>
          </div>
        </template>

        <template v-if="categories !== null && !tableData.length">
          <div class="l-empty-container u-full-height">
            <md-empty-state
              md-icon="announcement"
              md-label="No Data"
            />
          </div>
        </template>
      </template>
      <template v-if="showEditOrDeleteComponent">
        <edit-create-category-page :selected-category="selectedCategoryOnEdit" @onSuccessCreateUpdate="onSuccessCreateUpdate" @onBack="handleBack" />
      </template>
    </template>
    <template v-if="!getSelectedDomainId">
      <div class="l-empty-container u-full-height">
        <md-empty-state
          md-icon="announcement"
          md-label="You must create a domain first"
        />
      </div>
    </template>
  </div>
</template>

<script>

import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {helpService} from "@/services/help";
import GenericButton from "@/shared/buttons/GenericButton.vue";
import BaseTableComponent from "@/shared/table/BaseTableComponent.vue";
import {mapActions, mapGetters} from "vuex";
import DeleteModalComponent from "@/shared/delete-modal/DeleteModalComponent.vue";
import EditCreateCategoryPage from "./edit";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";

export default {
  name: "HelpCategoriesComponent",
  components: {
    BaseTableComponent,
    GenericButton,
    EditCreateCategoryPage
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],

  data() {
    return {
      searchQuery: '',
      categories: null,
      searchExpanded: true,
      showEditOrDeleteComponent: false,
      selectedCategoryOnEdit: null
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['getSelectedDomainId', 'getSelectedWebsiteId']),

    displayCategories() {
      return !this.searchQuery ? this.categories : this.categories.filter(category => category.label.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },

    tableData() {
      const categories = [];

      if (!this.displayCategories) {
        return [];
      }

      this.displayCategories.forEach(category => {
        categories.push({
          category: category,
          rowData: {
            'Category Name': category.label
          }
        });
      });

      return categories;
    }
  },

  watch: {
    getSelectedDomainId() {
      this.getCategories();
    }
  },

  async created() {
    this.deleteHelpCategory = await this.historyLoggerDecorator(helpService.deleteHelpCategory)

    this.getCategories();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),

    async getCategories() {
      if (!this.getSelectedDomainId) {
        return;
      }

      let response = await helpService.getHelpCategories(this.getSelectedDomainId);
      this.categories = response.data;
    },

    openDeleteModal(category) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: category.label,
          deleteDescription: 'Deleting category will also delete all pages defined for this category!',
          propData: category,
          deleteFunction: this.handleDelete,
        },
        {height: "auto", scrollable: false, width: "400px"}
      )
    },

    async handleDragSort(data) {
      try {
        data = data.map((categoryData, index) => ({categoryId: categoryData.category.id, order: index + 1}));
        await helpService.reorderHelpCategories(this.getSelectedDomainId, data);
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    handleEdit(categiry) {
      this.selectedCategoryOnEdit = categiry;
      this.openCreateOrUpdateForm();
    },

    async handleDelete(category) {
      try {
        await this.deleteHelpCategory(category)
        await this.getCategories();
        this.handleSuccess({message: 'Category deleted'});
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    openCreateOrUpdateForm() {
      this.showEditOrDeleteComponent = true;
      this.$emit('disableSelectDomain');
    },

    onSuccessCreateUpdate() {
      this.showEditOrDeleteComponent = false;
      this.selectedCategoryOnEdit = null;
      this.getCategories();
      this.$emit('enableDomains');
    },

    handleBack() {
      this.showEditOrDeleteComponent = false;
      this.selectedCategoryOnEdit = null;
      this.$emit('enableDomains');
    },
  },
};
</script>

<style lang="scss" scoped>
.l-empty-container {
  margin-bottom: 0;
}

.md-empty-state {
  max-width: 80% !important;
  justify-content: flex-start;
}
</style>
