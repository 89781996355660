<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToHome"
          > 
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Terminal Payments</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <template v-if="isFetchingCurrencies">
        <md-progress-spinner class="c-spinner" :md-diameter="60" :md-stroke="6" md-mode="indeterminate" />
      </template>
      <template v-if="!isFetchingCurrencies">
        <div class="c-header-container">
          <div class="u-flex-row-center">
            <div class="c-header-container__filters">
              <div class="c-input_group">
                <label>From date</label>
                <input v-model="filters.fromTime" onkeydown="return false" type="date" :max="maxFromDate">
              </div>
              <div class="c-input_group">
                <label>To date</label>
                <input v-model="filters.toTime" onkeydown="return false" type="date" :max="today" :min="minToDate">
              </div>
              <div class="c-filter-buttons-container">
                <generic-button
                  variation="grey"
                  @click="toggleFilters()"
                >
                  <md-icon style="color: white">
                    filter_list
                  </md-icon>
                </generic-button>
                <generic-button
                  variation="red"
                  :disabled="isClearFiltersDisabled"
                  @click="getDataPerPage(1)"
                >
                  <md-icon style="color: white">
                    search
                  </md-icon>
                </generic-button>
                <generic-button
                  variation="transparent"
                  :disabled="isClearFiltersDisabled"
                  @click="clearFilters()"
                >
                  Clear filters
                </generic-button>
              </div>
            </div>
          </div>
          <div class="c-header-container__tags">
            <div v-for="filter in formattedFilters" :key="filter.name" class="c-tag">
              {{ filter.name }}: {{ filter.value }}
              <generic-button
                variation="transparent"
                @click="clearFilter(filter.key)"
              >
                <md-icon style="color: white">
                  close
                </md-icon>
              </generic-button>
            </div>
          </div>
          <div class="c-filter-modal u-box" :class="filtersAreVisible ? '' : 'hidden'">
            <div class="c-filters-container">
              <div class="c-input_group_container">
                <div class="c-input_group">
                  <label>Minimal amount</label>
                  <input v-model="filters.minAmount" v-check-digit type="number">
                </div>
                <div class="c-input_group">
                  <label>Maximum amount</label>
                  <input v-model="filters.maxAmount" v-check-digit type="number">
                </div>
                <div class="c-input_group">
                  <label>Terminal ID</label>
                  <input v-model="filters.terminalId" v-check-digit type="number">
                </div>
              </div>
              <div class="c-input_group_container">
                <div class="c-input_group">
                  <label>Money receiver ID</label>
                  <input v-model="filters.receiverId" v-check-digit type="number">
                </div>
                <div class="c-input_group">
                  <label>Money sender ID</label>
                  <input v-model="filters.senderId" v-check-digit type="number">
                </div>
                <div class="c-input_group">
                  <label>Currency</label>
                  <select v-model="filters.currency">
                    <option
                      v-for="currency in currencies"
                      :key="currency.key"
                      :value="currency.key" 
                    >
                      {{ currency.name || currency.key }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="c-input_group_container">
                <div class="c-input_group">
                  <label>Old system payment ID</label>
                  <input v-model="filters.oldSystemTransactionId" v-check-digit type="number">
                </div>
                <div class="c-input_group">
                  <label>New system payment ID</label>
                  <input v-model="filters.newSystemTransactionId" v-check-digit type="number">
                </div>
                <div class="c-input_group">
                  <label>Status</label>
                  <select v-model="filters.status">
                    <option
                      v-for="paymentStatus in paymentStatuses"
                      :key="paymentStatus.key"
                      :value="paymentStatus.key"
                    >
                      {{ paymentStatus.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="!isFetchingPaymentsPage">
          <template v-if="getPaymentsForTable.length">
            <div class="c-table-wrapper l-terminal-payments__table">
              <base-table-component :prop-data="getPaymentsForTable" />
            </div>
          </template>
          <template v-if="!getPaymentsForTable.length">
            <md-empty-state
              md-icon="announcement"
              md-label="No payments found"
            />
          </template>
        </template>

        <template v-if="isFetchingPaymentsPage">
          <md-progress-spinner class="c-spinner" :md-diameter="30" :md-stroke="3" md-mode="indeterminate" />
        </template>
        
        <paginate
          v-if="shouldShowPagination"
          v-model="currentPage"
          :page-count="paymentPage.totalPages"
          :page-range="limitPerPage"
          :prev-text="'<'"
          :next-text="'>'"
          :click-handler="getDataPerPage"
          :container-class="'c-pagination'"
          :prev-link-class="'md-button prev md-elevation-1'"
          :next-link-class="'md-button next md-elevation-1'"
          :page-link-class="'md-button md-elevation-1'"
          :active-class="'active'"
          :disabled-class="'disabled'"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import GenericButton from '../../shared/buttons/GenericButton';
import moment from 'moment';
import isEmpty from "lodash/isEmpty";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  components: {
    BaseTableComponent,
    GenericButton,
  },

  directives: {
    checkDigit: {
      bind(el) {
        el.addEventListener('keydown', (event) => {
          const numberKeysToIgnore = ['+', '-', 'e'];
          if (numberKeysToIgnore.includes(event.key)) {
            event.preventDefault();
          }
        });
      }
    }
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      permissionName: `${PERMISSION_NAMES.TERMINAL_PAYMENTS.key}.${PERMISSION_NAMES.TERMINAL_PAYMENTS.key}`,
      paymentStatuses: [{name: "Any status", key: 'any'}, {name: 'Successful', key: 'SUCCESSFUL'}, {name: 'Failed', key: 'FAILED'}],
      currentPage: 1,
      limitPerPage: 10,
      today: moment(new Date()).format('YYYY-MM-DD'),
      filtersAreVisible: false,
      filters: {
        status: 'any',
        oldSystemTransactionId: undefined,
        newSystemTransactionId: undefined,
        minAmount: undefined,
        maxAmount: undefined,
        currency: 'any',
        receiverId: undefined,
        senderId: undefined,
        terminalId: undefined,
        fromTime: undefined,
        toTime: undefined
      },
      defaultValues: {
        currentPage: 1,
        limitPerPage: 10,
      },
      defaultFilters: {
        status: 'any',
        oldSystemTransactionId: undefined,
        newSystemTransactionId: undefined,
        minAmount: undefined,
        maxAmount: undefined,
        currency: 'any',
        receiverId: undefined,
        senderId: undefined,
        terminalId: undefined,
        fromTime: undefined,
        toTime: undefined
      },
      formattedFilters: []
    };
  },

  computed: {
    ...mapGetters('terminal', [
      'paymentPage',
      'getPaymentsForTable',
      'isFetchingPaymentsPage',
      'getCurrencies',
      'isFetchingCurrencies'
    ]),

    currencies() {
      return [{key: 'any', name: 'Any currency'}, ...Array.from(this.getCurrencies)];
    },

    shouldShowPagination() {
      return this.paymentPage && this.paymentPage.totalItems > this.limitPerPage;
    },

    isClearFiltersDisabled() {
      return JSON.stringify(this.defaultFilters) === JSON.stringify(this.filters);
    },

    maxFromDate() {
      const toTime = new Date(moment(this.filters.toTime)).getTime();
      const today = new Date(moment(this.today)).getTime();
      return (toTime && today > toTime) ? moment(toTime).format('YYYY-MM-DD') : this.today;
    },

    minToDate() {
      const fromTime = new Date(moment(this.filters.fromTime)).getTime();
      const today = new Date(moment(this.today)).getTime();
      return (fromTime && today > fromTime) ? moment(fromTime).format('YYYY-MM-DD') : '';
    },
  },

  watch: {
    'filters.status'(value) {
      if (isEmpty(value)) {
        this.filters.status = 'any';
      }
    },

    'filters.oldSystemTransactionId'(value) {
      if (isEmpty(value)) {
        this.filters.oldSystemTransactionId = undefined;
      }
    },

    'filters.newSystemTransactionId'(value) {
      if (isEmpty(value)) {
        this.filters.newSystemTransactionId = undefined;
      }
    },

    'filters.minAmount'(value) {
      if (isEmpty(value)) {
        this.filters.minAmount = undefined;
      }
    },

    'filters.maxAmount'(value) {
      if (isEmpty(value)) {
        this.filters.maxAmount = undefined;
      }
    },

    'filters.currency'(value) {
      if (isEmpty(value)) {
        this.filters.currency = 'any';
      }
    },

    'filters.receiverId'(value) {
      if (isEmpty(value)) {
        this.filters.receiverId = undefined;
      }
    },

    'filters.senderId'(value) {
      if (isEmpty(value)) {
        this.filters.senderId = undefined;
      }
    },

    'filters.terminalId'(value) {
      if (isEmpty(value)) {
        this.filters.terminalId = undefined;
      }
    },
  },

  async created() {
    this.$material.locale.dateFormat = 'dd.MM.yyyy.';
    this.getPaymentCurrencies();
    this.getDataPerPage();
  },

  methods: {
    ...mapActions('terminal', ['getPaymentPage', 'getPaymentCurrencies']),

    toggleFilters() {
      this.filtersAreVisible = !this.filtersAreVisible;
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async fetchPayments(page, pageSize) {
      this.filtersAreVisible = false;
      this.formatFilters();
      await this.getPaymentPage({ page, pageSize, ...this.getFilters() });
    },

    getDataPerPage(
      currentPage = 1,
      limit = this.defaultValues.limitPerPage,
    ) {
      this.currentPage = currentPage;
      this.fetchPayments(currentPage, limit);
    },

    getFilters() {
      return {
        page: this.currentPage,
        pageSize: this.limitPerPage,
        ...this.filters,
        status: this.filters.status === 'any' ? undefined : this.filters.status,
        currency: this.filters.currency === 'any' ? undefined : this.filters.currency,
        fromTime: this.filters.fromTime ? new Date(this.filters.fromTime).setHours(0, 0, 0) : undefined,
        toTime: this.filters.toTime ? new Date(this.filters.toTime).setHours(23, 59, 59) : undefined,
      }
    },

    clearFilters() {
      this.filters = {...this.defaultFilters};
      this.currentPage = this.defaultValues.currentPage;
      this.getDataPerPage(this.currentPage, this.defaultValues.limitPerPage);
    },

    clearFilter(filter) {
      this.filters[filter] = this.defaultFilters[filter];
      this.currentPage = this.defaultValues.currentPage;
      this.getDataPerPage(this.currentPage, this.defaultValues.limitPerPage);
    },

    formatFilters() {
      this.formattedFilters = [];
      const filterNames = {
        'status': 'Status',
        'currency': 'Currency',
        'oldSystemTransactionId': 'Old system ID',
        'newSystemTransactionId': 'New system ID',
        'minAmount': 'Min amount',
        'maxAmount': 'Max amount',
        'receiverId': 'Receiver ID',
        'senderId': 'Sender ID',
        'terminalId': 'Terminal ID'
      };
      for (let filter in this.filters) {
        if (!this.filters[filter] || this.filters[filter] === 'any') {
          continue;
        }
        const formattedFilter = {
          key: filter
        };
        switch (filter) {
          case 'status':
          case 'currency':
          case 'oldSystemTransactionId':
          case 'newSystemTransactionId':
          case 'minAmount':
          case 'maxAmount':
          case 'receiverId':
          case 'senderId':
          case 'terminalId':
            formattedFilter.name = filterNames[filter];
            break;
          default:
            continue;
        }
        formattedFilter.value = this.filters[filter];
        this.formattedFilters.push(formattedFilter);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$input-padding: 10px;
$container-padding: 8px;

.u-flex-row-center {
  width: 100%;
  padding-bottom: 20px;
}

.md-icon-button {
  height: calc(28px + 1.5rem);
  aspect-ratio: 1/1;
}

.c-header-container {
  padding: $container-padding;
  display: flex;
  flex-direction: column;
  position: relative;

  .md-button {
    min-width: 0 !important;
  }
}

.c-input_group_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 0.5rem;
  background-color: $grey-light-01;
  padding: $container-padding $container-padding calc(8px + 1.25rem);

  .meridian-theme-dark & {
    background-color: $grey-darker;
  }
}

.c-header-container__filters {
  flex-direction: row;
  background-color: transparent;
  align-items: flex-end;
  padding: 0;
  @extend .c-input_group_container;

  .md-button:not(:last-child) {
    @extend .md-icon-button;
  }
}

.c-header-container__filter-buttons {
  background-color: transparent;
  align-items: flex-end;
  height: 100%;
  @extend .c-header-container__filters;

  .md-button:first-child {
    @extend .md-icon-button;
  }
}

.c-filter-modal {
  position: absolute;
  top: calc(1.5rem + 60px); // button height + 2 * container padding
  right: $container-padding;
  background-color: $white;
  width: calc(100% - 16px);
  z-index: 11;
  padding: $container-padding;
  border-radius: 4px;

  &.hidden {
    visibility: hidden;
  }

  .md-button {
    transition: none;
  }

  .meridian-theme-dark & {
    background-color: $grey-darker;
  }
}

.c-header-container__tags {
  display: flex;
  gap: $container-padding;
  flex-wrap: wrap;
  flex: 1;
}

.c-tag {
  background-color: $grey;
  color: $white;
  display: flex;
  align-items: center;

  .md-button {
    box-shadow: none;
    height: fit-content;
    width: fit-content;
    box-sizing: border-box;
    min-width: fit-content;

    :first-child {
      padding: 0 0 0 2px;
    }

    .md-icon {
      font-size: 16px !important;
      min-width: none;
    }
  }
}

.c-filter-buttons-container {
  display: flex;
  gap: $container-padding;
  align-items: center;
  justify-content: flex-end;
}

.c-filters-container {
  display: flex;
  gap: $container-padding;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.c-input_group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 300px;
  max-width: 450px;

  label {
    color: $grey;
    font-size: 1.25rem;
    margin-left: 5px;
    
    .meridian-theme-dark & {
      color: $white;
    }
  }

  input, select {
    border: 1px solid $grey-01;
    padding: $input-padding;
    caret-color: $grey;
    font-size: 1.5rem;
    border-radius: 2px;
    background-color: transparent;
    color: $black;
    flex: 1;
    font-family: 'Roboto', sans-serif;

    .meridian-theme-dark & {
      caret-color: $white;
      color: $white;

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }    
    }
  }
}

.c-pagination {
  margin-top: auto;
}

.c-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}
</style>
