<template>
  <base-modal-component>
    <template v-slot:header>
      <span class="md-subheading u-typography-color u-color-white">
        Write comment about this change if needed
      </span>
    </template>

    <template v-slot:body>
      <div class="c-history-log-input">
        <label>Add comment (optional)</label>
        <textarea
          v-model="message"
          class="c-config-textarea"
          rows="5"
        />
      </div>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Cancel
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="accept()"
      >
        Confirm
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../modal/BaseModalComponent';
import GenericButton from "../../shared/buttons/GenericButton";
import {mapActions} from "vuex";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },

  props: {
    modalData: {
      required: false,
      default: null,
    },
    onAccept: {
      required: false,
      type: Function,
      default: () => {
      },
    },
    onClose: {
      required: false,
      type: Function,
      default: () => {
      },
    },
  },

  data() {
    return {
      message: ''
    }
  },
  methods: {
    ...mapActions("response", ["handleSuccess"]),

    close() {
      if (this.onClose) {
        this.onClose();
      }
      this.$emit('close');
    },

    accept() {
      if (this.onAccept) {
        this.onAccept(this.message);
      }
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.c-history-log-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  > label {
    color: #9A9A9A;
  }

  > textarea {
    border: 1px solid #9A9A9A;
    border-radius: 4px;
  }
}

</style>
