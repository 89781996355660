<template>
  <div class="c-config-input-type">
    <div class="c-config-input-info">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
    </div>

    <div class="c-config-input-actions">
      <md-radio
        v-for="option in options"
        :key="`${option.value}-${Math.random()}`"
        v-model="selected"
        class="md-primary"
        :value="option.value"
        :disabled="disabled"
        @change="setValue($event)"
      >
        {{ option.label }}
      </md-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioFormField',
  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },

  data() {
    return {
      options: [],
      selected: '0',
    };
  },

  created() {
    const options = JSON.parse(this.input.values)

    Object.entries(options).forEach(([value, label]) => {
      this.options.push({
        value,
        label,
      });
    });

    this.selected = String(this.input.value);
  },

  methods: {
    setValue($event) {
      this.$emit('update', {
        value: $event,
      })
    },
  }

};
</script>

<style lang="scss" scoped>


</style>
