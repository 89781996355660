<template>
  <div class="l-main">
    <div class="l-main__ctn">
      <div class="u-flex">
        <div class="c-table-wrapper l-tooltips__table">
          <div class="c-table-header">
            <div class="c-search">
              <md-field class="u-width-m u-mb-xs u-mr-md">
                <label>Search</label>
                <md-input v-model="searchQuery" @input="handleSearch" />
              </md-field>
            </div>
            <div class="add-button">
              <div
                v-if="canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.ICONS.key}`)"
                class="add-section"
                @click="createNewIcon()"
              >
                <img src="../../../../../assets/add-button.svg" class="add-button__new" alt=""> <span>Add new icon</span>
              </div>
            </div>
          </div>
          <base-table-component :prop-data="iconsForTableData">
            <template v-slot:default="slotProps">
              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.ICONS.key}`)"
                  @click="editIcon(slotProps.selected)"
                >
                  <md-tooltip md-direction="top">
                    Edit icon
                  </md-tooltip>
                  <img src="../../../../../assets/edit-button.svg" alt="">
                </generic-button>
              </span>
              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.ICONS.key}`)"
                  @click="openDeleteModal(slotProps.selected)"
                >
                  <md-tooltip md-direction="top">
                    Delete icon
                  </md-tooltip>
                  <img src="../../../../../assets/delete-button.svg" alt="">
                </generic-button>
              </span>
            </template>
          </base-table-component>
          <template v-if="searchQuery && (totalItems === 0)">
            <md-empty-state
              md-icon="announcement"
              md-label="No Data"
              class="diff-height"
            />
          </template>
        </div>
      </div>
    </div>

    <paginate
      v-if="totalPages > 1"
      v-model="currentPage"
      :page-count="totalPages"
      :prev-text="'<'"
      :next-text="'>'"
      :page-range="20"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </div>
</template>

<script>
  import BaseTableComponent from '@/shared/table/BaseTableComponent';
  import GenericButton from '@/shared/buttons/GenericButton';
  import EditIconsModal from './edit';
  import DeleteModalComponent from '@/shared/delete-modal/DeleteModalComponent.vue';
  import { iconsService } from '@/services/icons';
  import filter from 'lodash/filter';
  import each from 'lodash/each';
  import { mapActions } from 'vuex';
  import { responseService } from '@/services/response';
  import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
  import {PERMISSION_NAMES} from "@/const/online-permissions";

  export default {
    name: 'OnlineIconsList',

    components: {
      BaseTableComponent,
      GenericButton
    },

    mixins: [onlinePermissionsMixin],

    data() {
      return {
        searchQuery: '',

        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 10,

        fetchedResults: false,
        icons: [],
        listOfIconNames: [],
        listOfSlugNames: [],
      }
    },

    computed: {
      PERMISSION_NAMES() {
        return PERMISSION_NAMES
      },
      iconsForTableData() {
        if(!this.icons) {
          return [];
        }
        const currentPage = this.currentPage || 1;
        const unfilteredIcons = this.icons.map((icon, index) => ({
          iconIndex: index,
          icon: icon,
          rowData: {
            'Name': icon.name,
            'Slugs': icon.slugNames?.join(', '),
          }
        }))

        return this.filterIcons(unfilteredIcons)
          .slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
      }
    },

    async created() {
      await this.getAllIcons(true);
    },

    methods: {
      ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError"]),

      async handleCreatedIcon() {
        await this.getAllIcons();
        this.currentPage = 1;
      },

      createNewIcon() {
        this.$modal.show(
          EditIconsModal,
          {
            listOfIconNames: this.listOfIconNames,
            listOfSlugNames: this.listOfSlugNames,
            handleSuccessCallback: this.handleCreatedIcon,
          },
          {
            height: "auto", scrollable: false, width: "400px"
          }
        );
      },

      filterIcons(unfilteredIcons) {
        return filter(unfilteredIcons, icon => {
          if(icon.rowData) {
            return icon.icon.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              || icon.icon.slugNames?.join(',').includes(this.searchQuery)
          }

          return icon.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            || icon.slugNames?.join(',').includes(this.searchQuery)
        })
      },

      handleSearch() {
        const filteredIcons = this.filterIcons(this.icons);
        this.handlePaginationParameters(filteredIcons.length);
      },

      editIcon(icon) {
        this.$modal.show(
          EditIconsModal,
          {
            fetchedIcon: {
              name: icon.rowData.Name,
              slugNames: icon.rowData.Slugs ? icon.rowData.Slugs.split(', ') : [''],
              id: icon.icon.id
            },
            icon: icon,
            listOfIconNames: this.listOfIconNames,
            listOfSlugNames: this.listOfSlugNames,
            handleSuccessCallback: this.getAllIcons,
          },
          {
            height: "auto", scrollable: false, width: "400px"
          }
        )
      },

      async deleteIcon(icon) {
        try {
          await iconsService.delete(icon.icon.id)
          this.handleSuccess({ message: "Successfully deleted icon" })
        } catch (error) {
          this.setGlobalError(responseService.getErrorMessage(error.data?.message));
        }
      },

      openDeleteModal(icon) {
        this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: icon.icon.name,
            deleteMsgAlert: 'It will be deleted on all websites.',
            propData: icon,
            deleteFunction: this.deleteIcon,
            getDataFunction: this.getAllIcons,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
        )
      },

      async getAllIcons(isInit = false) {
        try {
          const icons = await iconsService.getAllIcons();
          this.icons = icons.data.map(icon => {
            return {
              ...icon,
              slugNames: JSON.parse(icon.slugNames)
            }
          });

          this.listOfIconNames = [];
          this.listOfSlugNames = [];

          each(this.icons, (icon) => {
            this.listOfIconNames.push(icon.name);
            icon.slugNames ? this.listOfSlugNames.push(...icon.slugNames) : ''
          })

          this.handlePaginationParameters();

          if(!isInit || this.icons.length) {
            return;
          }

        } catch (error) {
          this.setGlobalError(responseService.getErrorMessage(error.data.message));
        }
      },

      handlePaginationParameters(totalIconsCount = null) {
        const iconsCount = totalIconsCount !== null ? totalIconsCount : this.icons.length;

        this.totalPages = Math.ceil(iconsCount / this.itemsPerPage);
        this.totalItems = iconsCount;

        if(this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages;
        }
      }
    },
  }
</script>

<style>
.diff-height {
  height: auto;
}
</style>
