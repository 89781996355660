import axios from 'axios'
import config from '../../../config/local'
import assign from 'lodash/assign'

class RestApiService {

  constructor() {
    this.client = axios.create({});
  }

  async get(url) {
    try {
      await this.getAndSetToken();

      const response = await  this.client.get(`${config.REST_URL}${url}`)

      return response
    } catch(error) {
      throw error
    }
  }

  async getTokenFromRestApi() {
    try {
      const response = await axios.post(`${config.REST_URL}/oauth/token`, this.getParams(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
          username: 'backoffice-client',
          password: 'backoffice'
        }
      })

      return response.data;
    } catch(error) {
      throw error;
    }
  }

  async getAndSetToken() {
    const data = await this.getTokenFromRestApi();
    assign(this.client.defaults.headers, {
      'Authorization':  `Bearer ${data.access_token}`
    })
  }

  getParams() {
    const params = new URLSearchParams();
    params.append('grant_type', 'backoffice');
    params.append('username', 'vivify.ideas');
    params.append('password', 'vivify1234');

    return params;
  }
}

export const restApiService = new RestApiService()
