<template>
  <div v-if="error && error[fieldName]">
    <template v-for="message in error[fieldName]">
      <p
        :key="message"
        class="md-error"
      >
        * {{ message }}
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "ErrorResponseMessages",
  props: ['fieldName'],
  computed: {
    ...mapGetters('response', ['error'])
  },
}
</script>

<style lang="scss" scoped>
</style>
