<template>
  <div class="wrapper">
    <div v-if="imageUrl" class="image-wrapper">
      <img :src="imageUrl">
      <div v-if="!disabled" class="delete-image-button" @click="handleDeleteFile">
        <span>x</span>
      </div>
    </div>
    <md-field>
      <label v-if="!imageUrl">{{ placeholder }}</label>
      <md-file ref="fileInput" v-model="image" :accept="acceptTypes" :disabled="disabled" @md-change="handleFilePick" />
    </md-field>
  </div>
</template>

<script>
export default {
  name: 'ImageWithPreviewField',

  props: {
    imagePath: {
      type: String,
      required: false,
      default: '',
    },
    imageName: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select image file',
    },
    accept: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      image: null,
      imageUrl: '',
    }
  },

  computed: {
    acceptTypes() {
      return this.accept.join(',')
    },
  },

  watch: {
    imageName() {
      this.image = this.imageName;
    },

    imagePath() {
      this.imageUrl = this.imagePath
    }
  },

  created() {
    this.image = this.imageName;
    this.imageUrl = this.imagePath;
  },

  methods: {
    handleFilePick(files) {
      const file = files[0];
      this.imageUrl = URL.createObjectURL(file);
      this.$emit('onChange', file);
    },

    handleDeleteFile() {
      this.imageUrl = '';
      this.image = null;
      this.$emit('onChange', null);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  max-width: 200px;
  min-height: 64px;
}

.delete-image-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  background: red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  border: none;
}
</style>
