<template>
  <div>
    <base-modal-component>
      <template v-slot:header>
        <div class="u-flex-column">
          <h4 class="u-typography-color md-title">
            {{ update }} icon
          </h4>
        </div>
      </template>

      <template v-slot:body>
        <md-field class="u-mb-md">
          <label>Icon name</label>
          <md-input
          :value="singleIcon.name"
          name="iconName"
          type="text"
          class="md-input"
          autocomplete="off"
          @input="handleIconNameKeyChange($event)"
          />  
        </md-field>
        <p v-if="duplicateIconNames" class="md-error-icons">
          Icon name {{ duplicateIconNames }} already exists.
        </p>
        <md-field
          v-for="(slug, index) in singleIcon.slugNames"
          :key="'templateindex' + index"
          class="u-mb-md"
        >
          <label>Icon slug</label>
          <md-input
            v-model="singleIcon.slugNames[index]"
            name="slugName"
            type="text"
            class="md-input"
            autocomplete="off"
            @input="handleIconSlugKeyChange($event)"
          />
          <generic-button
            v-if="singleIcon.slugNames.length > 1"
            class="u-mr-md"
            icon
            transparent
            @click="deleteSlug(index)"
          >
            <img src="../../../../../../assets/delete-button.svg" alt="">
          </generic-button>
        </md-field>
        <p v-if="duplicateSlugNames" class="md-error-icons">
          Slug {{ duplicateSlugNames }} already exists.
        </p>
        <generic-button
          variation="grey"
          class="u-mr-md"
          :disabled="disableAddNewSlugButton"
          @click="addNewSlug"
        >
          Add new slug
        </generic-button>
      </template>

      <template v-slot:footer>
        <div class="u-text-align-right">
          <div class="u-flex">
            <generic-button
              variation="grey"
              class="u-mr-md"
              @click="handleClose"
            >
              Close
            </generic-button>
            <generic-button
              variation="red"
              :disabled="disableCreateButton"
              @click="submit"
            >
              {{ update }}
            </generic-button>
          </div>
        </div>
      </template>
    </base-modal-component>
  </div>
</template>

<script>
  import GenericButton from '@/shared/buttons/GenericButton';
  import BaseModalComponent from '@/shared/modal/BaseModalComponent';
  import filter from 'lodash/filter';
  import includes from 'lodash/includes';
  import find from 'lodash/find';
  import { iconsService } from '@/services/icons';
  import { mapActions } from 'vuex';

  export default {
    name: 'EditIconsModal',
    components: {
      GenericButton,
      BaseModalComponent
    },

    props: {
      fetchedIcon: {
        type: Object,
        required: false,
        default: () => {}
      },
      listOfSlugNames: {
        type: Array,
        required: false,
        default: () => []
      },
      listOfIconNames: {
        type: Array,
        required: false,
        default: () => []
      },
      handleSuccessCallback: {
        type: Function,
        required: false,
        default: () => {}
      },
      icon: {
        type: Object,
        required: false,
        default: () => {}
      },
    },

    data() {
      return {
        singleIcon: {
          name: '',
          slugNames: [''],
        },
        listOfOtherIconNames: [],
        listOfOtherSlugNames: [],
        duplicateIconNames: '',
        duplicateSlugNames: '',
        disableCreateButton: true,
        disableAddNewSlugButton: this.fetchedIcon ? false : true,
      }
    },  

    computed: {
      update() {
        return this.fetchedIcon ? 'Update' : 'Create';
      }
    },

    created() {
      this.setIcon();
    },

    methods: {
      ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError"]),

      async setIcon() {
        if (this.fetchedIcon) {
          this.singleIcon = this.fetchedIcon;
          this.singleHelperIcon = this.fetchedIcon;
          this.listOfOtherSlugNames = this.listOfSlugNames.filter((id) => {
            return !includes(this.fetchedIcon.slugNames, id);
          })
          this.listOfOtherIconNames = this.listOfIconNames.filter((key) => key !== this.singleIcon.name)
          return;
        }

        this.listOfOtherIconNames = this.listOfIconNames;
        this.listOfOtherSlugNames = this.listOfSlugNames;
      },

      arraysEqual(a, b) {
        a = [...a].sort();
        b = [...b].sort();
        
        if (a.length !== b.length) {
          return false;
        }

        for (let i = 0; i < a.length; i++) { 
          if (a[i] !== b[i]) {
            return false;
          } 
        }

        return true;
      },

      addNewSlug() {
        this.singleIcon.slugNames.push('');
        this.disableAddNewSlugButton = true;
        this.disableCreateButton = true;
      },

      deleteSlug(index) {
        this.singleIcon.slugNames.splice(index, 1);
        this.disableAddNewSlugButton = false;
        this.disableCreateButton = false;
        this.checkIfChangedUpdate();
      },

      async submit() {
        this.duplicateSlugNames = '';
        this.duplicateIconNames = '';
        const duplicateSlugNames = filter([...this.listOfOtherSlugNames, ...this.singleIcon.slugNames], (val, i, iteratee) => includes(iteratee, val, i + 1));
        const duplicateIconNames = find(this.listOfOtherIconNames, (key) => key === this.singleIcon.name);
        if (duplicateIconNames || (duplicateSlugNames.length && this.singleIcon.slugNames[0].length)) {
          this.duplicateIconNames = duplicateIconNames || '';
          this.duplicateSlugNames = duplicateSlugNames.join(', ');
          return;
        }

        this.fetchedIcon ? await this.handleUpdateIcon() : await this.handleCreateIcon();
        this.handleSuccessCallback();
      },

      async handleUpdateIcon() {
        const createData = this.singleIcon.slugNames.filter(name => name.trim()).length ? {
          id: this.singleIcon.id,
          name: this.singleIcon.name.toLocaleLowerCase(),
          slugNames: JSON.stringify(this.singleIcon.slugNames.filter(name => name.trim().toLocaleLowerCase()))
        } : {
          id: this.singleIcon.id,
          name: this.singleIcon.name.toLocaleLowerCase(),
        }

        try {
          await iconsService.update(this.singleIcon.id, createData)
          this.handleSuccess({ message: 'Succesfully updated Icon' });
          this.handleClose();
        } catch (error) {
          this.setGlobalError(error.data?.message);
        }
      },

      async handleCreateIcon() {
        const createData = this.singleIcon.slugNames.filter(name => name.trim()).length ? {
          name: this.singleIcon.name.toLocaleLowerCase(),
          slugNames: JSON.stringify(this.singleIcon.slugNames.filter(name => name.trim().toLocaleLowerCase()))
        } : {
          name: this.singleIcon.name.toLocaleLowerCase(),
        }

        try {
          await iconsService.create(createData);
          this.handleSuccess({ message: 'Succesfully created Icon' });
          this.handleClose();
        } catch (error) {
          this.setGlobalError(error.data?.message);
        }
      },

      handleIconNameKeyChange(newValue) {
        this.singleIcon.name = newValue;
        this.duplicateIconNames = '';
        this.disableCreateButton = false;

        this.checkIfChangedUpdate();
      },

      handleIconSlugKeyChange() {
        this.disableAddNewSlugButton = false;
        this.disableCreateButton = false;
        this.checkIfChangedUpdate();
      },

      checkIfChangedUpdate() {
        this.singleIcon.slugNames.forEach(slug => {
          if(!slug) {
            this.disableAddNewSlugButton = true;
            if(this.singleIcon.slugNames.length > 1) {
              this.disableCreateButton = true;
            }
          }
        })

        if (!this.singleIcon.name) {
          this.disableCreateButton = true;
        }

        if (!this.fetchedIcon) {
          return;
        }

        const singleIconSlugs = this.singleIcon.slugNames?.filter(name => name.trim())
        const fetchedIconSlugs = this.icon.icon.slugNames?.filter(name => name.trim())
        if (!this.singleIcon.name || (this.singleIcon.name === this.icon.icon.name && this.arraysEqual(singleIconSlugs, fetchedIconSlugs))) {
          this.disableCreateButton = true;
        }
      },
  
      handleClose() {
        this.resetError();
        this.$emit("close");
      }
    }
  }
</script>

<style lang="scss" scoped>
.u-mr-md {
  margin-right: $md !important;
}

.u-mb-md {
  margin-bottom: $md !important;
}
</style>