<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled">
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item" @click="goToWebsites">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Websites</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">{{ websiteName }}</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs v-if="selectedTab" class="c-tabs c-tabs--large" :md-active-tab="selectedTab.tab">
        <md-tab
          v-for="tab in filteredWebsiteTabs"
          :id="tab.tab"
          :key="`${$route.params.id}-${tab.tab}`"
          :md-label="tab.label"
          @click="changeActiveTab(tab)"
        />
      </md-tabs>

      <template v-if="website">
        <component
          :is="selectedTab.tab"
          v-if="!selectedTab.id"
          :website="website"
          class="c-website__component"
        />
        <generic-online-configuration
          v-else-if="website"
          :website="website"
          :section="selectedTab"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { websiteTabs } from '../../../../const';
import { websiteService } from "../../../../services/websites";
import SeoRules from '../../seo-rules';
import Tooltips from '../../tooltips';
import GenericOnlineConfiguration from '../../generic-online-configuration';
import CustomPages from '../../configurations/components/custom-pages'
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  name: "SingleWebsite",

  components: {
    CustomPages,
    SeoRules,
    Tooltips,
    GenericOnlineConfiguration
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      website: null,
      selectedTab: {
        tab: '',
      },
    };
  },

  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('onlineConfiguration', ['configurations', 'sections', 'subsections', 'types']),

    filteredWebsiteTabs() {
      const configurationTabs = this.canRead(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`)
        ? this.getSectionsFromConfigurations()
        : [];

      return websiteTabs.filter(websiteTab => this.canRead(websiteTab.permission)).concat(configurationTabs);
    },

    websiteName() {
      return this.website && this.website.name
    }
  },

  async created() {
    await this.getWebsite();
    await Promise.all([
      this.getOnlineConfigurationSections(),
      this.getOnlineConfigurationSubsections(),
      this.getOnlineConfigurationTypes(),
      this.getWebsiteConfiguration(this.website.id)
    ])

    this.setInitialTab()
  },

  methods: {
    ...mapActions('onlineConfiguration', [
      'getWebsiteConfiguration',
      'getOnlineConfigurationSections',
      'getOnlineConfigurationSubsections',
      'getOnlineConfigurationTypes'
    ]),

    goToWebsites() {
      this.$router.push({ name: "websites" });
    },

    async getWebsite() {
      const website = await websiteService.getSingleWebsite(this.$route.params.id)

      this.website = website.data
    },

    getSectionsFromConfigurations() {
      const sectionIds = this.configurations.map(configuration => configuration.sectionId)
      const uniqueIds = [...new Set(sectionIds)]

      return uniqueIds.map(id => {
        const section = this.sections.find(section => section.id === id);

        return {
          id: section.id,
          tab: section.name,
          label: section.name.replace('-', ' ')
        }
      })
    },

    setInitialTab() {
      this.selectedTab = this.filteredWebsiteTabs[0]
    },

    changeActiveTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
