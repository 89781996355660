import { companyService } from '../../services/company'

function getInitialState() {
  return {
    companies: [],
    markets: [],
    betshops: [],
    companyPaginationData: {},
    betshopPaginationData: {},
    companyName: null,
    marketName: null,
    marketsPaginationData: {},
    isFetchedCompanies: false,
    isFetchedMarkets: false,
    isFetchedBetShops: false,
    defaultMarketLanguage: null,
    availableMarketLanguages: [],
  }
}

const state = getInitialState()

const actions = {
  async getCompaniesFromRest({ commit, dispatch }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getCompaniesFromRest();
      if (data.length === 0) {
        return
      }
      const response = await companyService.saveCompanies(data)
      commit('GET_COMPANIES_SUCCESS', response)
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }

  },

  async getCompanies({ commit, dispatch }, { page, limit, term }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const response = await companyService.getCompanies(page, limit, term)
      commit('GET_COMPANIES_SUCCESS', response)
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },

  async getMarketsFromRest({ commit, dispatch }, marketId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getMarketsFromRest(marketId);
      if (data.length === 0) {
        return
      }
      const savedMarkets = await companyService.saveMarkets(data);
      commit('GET_MARKETS_SUCCESS',
        {
          companyId: marketId,
          markets: savedMarkets.data
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }

  },

  async getMarkets({ commit, dispatch }, options) {
    try {
      commit('SET_IS_FETCHING_MARKETS', false)
      dispatch('response/setGlobalError', null, { root: true });

      const markets = await companyService.getMarketsByCompanyId(options)

      commit('GET_MARKETS_SUCCESS',
        {
          companyId: options.companyId,
          markets: markets.data
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },

  async getBetShopsFromBe({ commit, dispatch }, marketId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getBetshopsFromRest(marketId);
      if (data.length === 0) {
        return
      }
      const savedBetshops = await companyService.saveBetshops(data);

      commit('GET_BETSHOPS_SUCCESS',
        {
          marketId: marketId,
          betshops: savedBetshops.data.items,
          paginationData: {
            totalItems: savedBetshops.data.totalItems,
            pageCount: savedBetshops.data.pageCount
          }
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async getMarketLanguages({ commit, dispatch }, params) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getMarketLanguages(params.companyId, params.marketId);
      if (data.length === 0) {
        return
      }
      await companyService.saveLanguages(params.marketId, { language: data.payload.language, additionalLanguages: data.payload.additionalLanguages });
      commit('GET_LANGUAGES_SUCCESS',
        {
          defaultMarketLanguage: data.payload.language,
          additionalLanguages: data.payload.additionalLanguages
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async getBetShops({ commit, dispatch }, { marketId, page, limit, term }) {
    try {
      commit('SET_IS_FETCHING_BETSHOPS', false)
      dispatch('response/setGlobalError', null, { root: true });

      const { data } = await companyService.getBetshopsByMarketId(marketId, page, limit, term);

      if (!data) {
        return;
      }

      commit('GET_BETSHOPS_SUCCESS',
        {
          marketId: marketId,
          betshops: data.items,
          paginationData: {
            totalItems: data.totalItems,
            pageCount: data.pageCount
          }
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },

  async getCompanyBredCrumps({ commit }, marketId) {
    const { data } = await companyService.getCompanyBredCrumps(marketId);
    if (!data) {
      return
    }
    commit('SET_COMPANY_NAME', data.name)
  },

  async getMarketBredCrumps({ commit }, marketId) {
    const { data } = await companyService.getMarketBredCrumps(marketId);
    if (!data) {
      return
    }
    commit('SET_MARKET_NAME', data.name)
    commit('SET_COMPANY_NAME', data.company.name)
  },

  clearBetshop({ commit }) {
    commit('CLEAR_BETSHOPS_SUCCESS')
  },
}

const getters = {
  getCompaniesForTable: state => state.companies.map(company => {
    return {
      rowData: {
        id: company.id,
        name: company.name
      }
    }
  }),

  getFilteredMarketsForTable: state => param => {
    const foundMarket = state.markets.filter(market => market.companyId == param);

    if (!foundMarket) {
      return [];
    }

    return foundMarket.map((market) => {
      return {
        rowData: {
          id: market.id,
          name: market.name,
        }
      }
    })
  },

  getBetShopsForTable: state => {
    if (!state.betshops.length) {
      return [];
    }
    return state.betshops.map((betShop) => {
      return {
        rowData: {
          id: betShop.id,
          name: betShop.name,
        }
      }
    })
  },

  getAllCompanies: state => state.companies.map(company => {
    return {
      id: company.id,
      name: company.name,
      selected: false
    }
  }),

  isFetchedCompanies: state => state.isFetchedCompanies,

  getMarketsPaginationData: state => state.marketsPaginationData,

  getCompanyPaginationData: state => state.companyPaginationData,

  getBetshopPaginationData: state => state.betshopPaginationData,

  getDefaultMarketLanguage: state => state.defaultMarketLanguage,
}

const mutations = {
  GET_COMPANIES_SUCCESS(state, data) {
    state.companies = data.data.items
    state.companyPaginationData = {
      totalItems: data.data.totalItems,
      pageCount: data.data.pageCount
    }
    state.isFetchedCompanies = true
  },
  GET_MARKETS_SUCCESS(state, data) {
    state.isFetchedMarkets = true
    state.marketsPaginationData = {
      totalItems: data.markets.totalItems,
      pageCount: data.markets.pageCount
    }
    state.markets = data.markets.items;
  },
  GET_BETSHOPS_SUCCESS(state, data) {
    state.isFetchedBetShops = true
    state.betshopPaginationData = data.paginationData
    state.betshops = data.betshops
  },
  CLEAR_BETSHOPS_SUCCESS(state) {
    state.isFetchedBetShops = false
    state.betshopPaginationData = {}
    state.betshops = []
  },
  GET_LANGUAGES_SUCCESS(state, data) {
    state.defaultMarketLanguage = data.defaultMarketLanguage
    state.availableMarketLanguages = data.additionalLanguages
  },
  SET_IS_FETCHING_BETSHOPS(state, data) {
    state.isFetchedBetShops = data
  },
  SET_IS_FETCHING_MARKETS(state, data) {
    state.isFetchedMarkets = data
  },
  SET_COMPANY_NAME(state, data) {
    state.companyName = data
  },
  SET_MARKET_NAME(state, data) {
    state.marketName = data
  },
}

export const company = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
