<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled" @click="goToHome">
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Websites</a>
        </li>
      </ul>
    </div>

    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-websites">
        <base-table-component :prop-data="websites">
          <template v-slot:action>
            <div class="header-table-section">
              Websites
            </div>
            <div class="add-button">
              <div>
                List of Websites
              </div>
              <div v-if="isAdmin()" class="add-section">
                <img src="../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateWebsite()"> <span>Add New Website</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <template v-if="canReadWebsite(slotProps.selected.rowData.id)">
              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  :disabled="!canWriteWebsite(slotProps.selected.rowData.id)"
                  @click="createOrUpdateWebsite(slotProps.selected.rowData)"
                >
                  <md-tooltip md-direction="top"> Edit </md-tooltip>
                  <img src="../../../assets/edit-button.svg" alt="">
                </generic-button>
              </span>

              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  :disabled="!canReadWebsite(slotProps.selected.rowData.id) || !canReadAnyWebsiteConfigurationTab()"
                  @click="configureWebsite(slotProps.selected.rowData)"
                >
                  <md-tooltip md-direction="top"> Config </md-tooltip>
                  <img src="../../../assets/settings-button.svg" alt="">
                </generic-button>
              </span>


              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  :disabled="!canWriteWebsite(slotProps.selected.rowData.id)"
                  @click="openDeleteModal(slotProps.selected.rowData)"
                >
                  <md-tooltip md-direction="top"> Delete </md-tooltip>
                  <img src="../../../assets/delete-button.svg" alt="">
                </generic-button>
              </span>
            </template>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>
import { websiteService } from "@/services/websites";
import { mapGetters } from "vuex";
import get from 'lodash/get';
import BaseTableComponent from "../../../shared/table/BaseTableComponent";
import GenericButton from "../../../shared/buttons/GenericButton";
import DeleteModalComponent from "../../../shared/delete-modal/DeleteModalComponent.vue";
import CreateWebsiteModal from "./create";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  name: "Websites",

  components: {
    BaseTableComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      websites: []
    };
  },

  computed: {
    ...mapGetters("website", ["getWebsiteForTable"]),
  },

  async created() {
    this.getWebsites();
  },

  methods: {
    deleteWebsite(id) {
      return websiteService.deleteWebsite(id);
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: selected.name,
          propData: selected.id,
          deleteFunction: this.deleteWebsite,
          getDataFunction: this.getWebsites,
          pageData: {}
        },
        { height: "auto", scrollable: false, width: "400px" }
      );
    },

    async getWebsites(page = 1, limit = 1) {
      const response = await websiteService.getWebsites(page, limit);
      this.websites = websiteService
        .prepareWebsitesDataFromResponse(response.data)
        .filter(preparedWebsiteData => this.canReadWebsite(preparedWebsiteData.rowData.id));
    },

    goToHome() {
      this.$router.push({ name: "home" });
    },

    configureWebsite(website) {
      this.$router.push({ name: "websites-edit", params: { id: website.id } });
    },

    createOrUpdateWebsite(website = {}) {
      this.$modal.show(
        CreateWebsiteModal,
        {
          websiteData: {
            name: get(website, 'name', ''),
            url: get(website, 'url', ''),
            id: get(website, 'id', null),
            betshopId: get(website, 'betshopId', ''),
          },
          getWebsites: this.getWebsites,
        },
        { height: "auto", scrollable: true }
      );
    },

    canReadAnyWebsiteConfigurationTab() {
      return [
        `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`,
        `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`,
        `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`,
      ].some(permission => this.canRead(permission));
    }
  },
};
</script>
