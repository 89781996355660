<template>
  <div class="l-main">
    <div class="l-main__ctn">
      <div class="u-flex">
        <div v-if="!isEditingSportWithTooltips" class="c-search">
          <md-field class="u-width-m u-mb-xs u-mr-md">
            <label>Search</label>
            <md-input v-model="searchQuery" @input="handleSearch" />
          </md-field>
        </div>
        <generic-button
          v-if="isEditingSportWithTooltips"
          icon
          transparent
          class="c-button__tooltips"
          style=""
          @click="toggleEditingMode"
        >
          <md-tooltip md-direction="top">
            Go to Tooltips
          </md-tooltip>
          <md-icon style="transform: rotate(180deg)">
            arrow_forward_ios
          </md-icon>
        </generic-button>

        <generic-button
          :class="{hidden: isEditingSportWithTooltips}"
          :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
          transparent
          variation="red"
          style="margin-left: auto !important;"
        >
          <md-tooltip
            md-direction="top"
          >
            Import Tooltips CSV
          </md-tooltip>
          <label
            class="u-cursor-pointer"
            for="file"
          >
            Import Tooltips
          </label>
          <input
            id="file"
            ref="file"
            class="md-file-input"
            accept=".csv"
            type="file"
            @change="handleOnFileChange($event)"
          >
        </generic-button>
        <generic-button
          :class="{hidden: isEditingSportWithTooltips}"
          :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
          transparent
          variation="red"
          class="u-justify-start u-fit-content c-back-button"
          style="margin-left: 5px !important;"
          @click="openImportTranslationsModal()"
        >
          <md-tooltip
            md-direction="top"
          >
            Import Translations CSV
          </md-tooltip>
          Import Translations
        </generic-button>
      </div>

      <template v-if="isEditingSportWithTooltips">
        <sport-tooltips
          :website="website"
          :sport="selectedSport"
          :go-back="toggleEditingMode"
          :languages="allOnlineLanguages"
          :tooltip-section="tooltipSection"
        />
      </template>

      <template v-else>
        <h2 v-if="fetchedResults && sportsWithTooltips && sportsWithTooltips.length === 0">
          No tooltips exist yet
        </h2>

        <div class="c-table-wrapper">
          <base-table-component :prop-data="sportsWithTooltipsTableData">
            <template v-slot:action>
              <div class="header-table-section">
                Tooltips
              </div>
              <div class="add-button">
                <div>
                  List of all Tooltips
                </div>
                <div v-if="canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)" class="add-section">
                  <div class="md-layout md-gutter">
                    <div class="md-layout-item u-max-width-35">
                      <md-field class="md-margin-zero">
                        <label for="sport">Create new sport</label>
                        <md-select id="sport" v-model="selectedSportId" name="sport" placeholder="Select a sport to add" md-dense>
                          <md-option
                            v-for="sport in sportsWithoutTooltipsDropdownData"
                            :key="sport.sportId"
                            :value="sport.sportId"
                          >
                            {{ sport.name }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item l-main__header" :class="[{ 'add-button-disabled': !selectedSportId}]">
                      <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createSportWithTooltips(selectedSportId)">
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:default="slotProps">
              <span class="c-table__btn-wrap">
                <generic-button
                  v-if="canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
                  icon
                  transparent
                  text-inherit
                  @click="openDeleteModal(slotProps.selected)"
                >
                  <md-tooltip md-direction="top">
                    Delete sport tooltips
                  </md-tooltip>
                  <img src="../../../../../assets/delete-button.svg" alt="">
                </generic-button>
                <generic-button
                  icon
                  transparent
                  @click="openEditSportWithTooltipsTable(slotProps.selected)"
                >
                  <md-tooltip md-direction="top">
                    Go to sport tooltips
                  </md-tooltip>
                  <md-icon>arrow_forward_ios</md-icon>
                </generic-button>
              </span>
            </template>
          </base-table-component>
          <template v-if="searchQuery && (totalItems === 0)">
            <md-empty-state
              md-icon="announcement"
              md-label="No Data"
            />
          </template>
        </div>

        <paginate
          v-if="totalPages > 1"
          v-model="currentPage"
          :page-count="totalPages"
          :prev-text="'<'"
          :next-text="'>'"
          :page-range="20"
          :container-class="'c-pagination'"
          :prev-link-class="'md-button prev md-elevation-1'"
          :next-link-class="'md-button next md-elevation-1'"
          :page-link-class="'md-button md-elevation-1'"
          :active-class="'active'"
          :disabled-class="'disabled'"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { tooltipsService } from '@/services/tooltips';
  import GenericButton from '@/shared/buttons/GenericButton';
  import BaseTableComponent from '@/shared/table/BaseTableComponent';
  import { mapActions } from 'vuex';
  import DeleteModalComponent from '@/shared/delete-modal/DeleteModalComponent.vue';
  import SportTooltips from './sub-pages/sport-tooltips';
  import { responseService } from '@/services/response';
  import { languageService } from '@/services/language';
  import { sectionService } from '@/services/section';
  import find from 'lodash/find';
  import get from 'lodash/get';
  import filter from 'lodash/filter';
  import ImportTranslationsModal from './import-translations-modal';
  import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
  import {PERMISSION_NAMES} from "@/const/online-permissions";

  export default {
    name: 'OnlineTooltipsSportList',

    components: {
      GenericButton,
      BaseTableComponent,
      SportTooltips
    },

    mixins: [onlinePermissionsMixin],

    props: {
      website: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        permissionName: `${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`,
        searchQuery: '',

        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 10,

        fetchedResults: false,
        showAllSportsWithTooltips: false,
        isEditingSportWithTooltips: false,
        sportsWithTooltips: [],
        filteredSportsWithTooltips: [],
        sports: [],
        selectedSport: {},
        selectedSportId: 0,
        allOnlineLanguages: [],
        tooltipSection: {},
      };
    },

    computed: {
      PERMISSION_NAMES() {
        return PERMISSION_NAMES
      },

      sportsWithTooltipsTableData() {
        if (!this.filteredSportsWithTooltips) {
          return [];
        }

        const currentPage = this.currentPage || 1;
        const unfilteredSports = this.filteredSportsWithTooltips.map(sportWithTooltip => ({
          sportWithTooltip: sportWithTooltip,
          rowData: {
            'Sport Id': sportWithTooltip.sportId,
            'Sport': sportWithTooltip?.name,
          }
        }))

        return this.filterSportsWithTooltips(unfilteredSports)
          .slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
      },

      sportsWithoutTooltipsDropdownData() {
        if(!this.sports) {
          return [];
        }

        return this.sports.filter((sport) => {
          return this.filteredSportsWithTooltips.filter((sportWithTooltips) => {
              return sportWithTooltips.sportId === sport.sportId;
          }).length === 0
        });
      }
    },

    async created() {
      await this.getSports();
      this.getSportsWithTooltips();
      this.getAvailableLanguages();
      this.getAvailableSections();
    },

    methods: {
      ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError", "enableFullscreenLoader", "disableFullscreenLoader", "handleError"]),

      async getSportsWithTooltips() {
        const sportsWithTooltipsResponse = await tooltipsService.getAllSportsWithTooltips();
        this.sportsWithTooltips = sportsWithTooltipsResponse?.data?.items;
        this.filteredSportsWithTooltips = this.sportsWithTooltips.map(sportWithTooltip => {
          return {
            ...sportWithTooltip,
            name: this.sports.find(sport => sport.sportId === sportWithTooltip.sportId)?.name
          }
        });
        this.fetchedResults = true;

        this.handlePaginationParameters();
      },

      async getSports() {
        if (this.sports.length) {
          return;
        }

        const sportsResponse = await tooltipsService.getSports();
        this.sports = sportsResponse?.data;
      },

      async deleteSportWithTooltips(id) {
        try {
          await tooltipsService.delete(id);
          this.handleSuccess({ message: 'Successfully deleted sport tooltips' })
        } catch (error) {
          this.setGlobalError(responseService.getErrorMessage(error.data.message));
        }
      },

      async createSportWithTooltips(sportId) {
        if (!sportId) {
          return;
        }

        try {
          const createData = {
              "websiteIds": "[]",
              "sportId": sportId,
              "tooltips": "[]"
          }
          const response = await tooltipsService.create(createData);
          this.handleSuccess({ message: 'Successfully created sport'});
          const newSport = find(this.sports, { sportId });
          this.addNewCreatedSportInFilteredSports(newSport, response);
          this.setSelectedSport(newSport, response);
          this.toggleEditingMode();
        } catch (error) {
          this.setGlobalError(responseService.getErrorMessage(error.data.message));
        }
      },

      openDeleteModal(selected) {
        this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: selected.rowData.Sport,
            deleteMsgAlert: `It will be deleted on all websites.`,
            propData: selected.sportWithTooltip.sportId,
            deleteFunction: this.deleteSportWithTooltips,
            getDataFunction: this.getSportsWithTooltips,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
      },

      openEditSportWithTooltipsTable(selected) {
        this.selectedSport = selected;
        this.toggleEditingMode();
      },

      toggleEditingMode() {
        this.isEditingSportWithTooltips = !this.isEditingSportWithTooltips;
      },

      openImportTranslationsModal() {
        this.$modal.show(
          ImportTranslationsModal,
          {},
          { height: "auto", scrollable: false, width: "600px" }
        );
      },

      async handleOnFileChange(event) {
        const formData = new FormData();
        formData.set('file', event.target.files[0]);
        this.enableFullscreenLoader();
        try {
          await tooltipsService.uploadTooltips(formData);
          this.handleSuccess({message: `Tooltips imported!`});
          this.getSportsWithTooltips();
        } catch (e) {
          this.setGlobalError(get(e, 'data.message', 'Error with importing!' ));
        } finally {
          this.disableFullscreenLoader();
        }
      },

      handleSearch() {
        const filteredSports = this.filterSportsWithTooltips(this.filteredSportsWithTooltips);
        this.handlePaginationParameters(filteredSports.length)
      },

      handlePaginationParameters(totalItemsCount = null) {
        const tooltipsCount = totalItemsCount !== null ? totalItemsCount : this.sportsWithTooltips.length;

        this.totalPages = Math.ceil(tooltipsCount / this.itemsPerPage)
        this.totalItems = tooltipsCount;

        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages;
        }
      },

      async getAvailableSections() {
        const data = await sectionService.onlineGetAllSections(0, 99999, false);
        this.tooltipSection = find(get(data, 'data.items', []), {key:'tooltips'});
      },

      async getAvailableLanguages() {
        const allLanguagesResponse = await languageService.getAllOnlineLanguages();
        this.allOnlineLanguages = allLanguagesResponse.data.items;
      },

      filterSportsWithTooltips(unfilteredSports) {
        return filter(unfilteredSports, sportWithTooltip => {
          if (sportWithTooltip.rowData) {
            return sportWithTooltip.sportWithTooltip.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              || sportWithTooltip.sportWithTooltip.sportId.toString().includes(this.searchQuery)
          }

          return sportWithTooltip.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            || sportWithTooltip.sportId.toString().includes(this.searchQuery)
        })
      },
      addNewCreatedSportInFilteredSports(newSport, response) {
        this.filteredSportsWithTooltips.push({
            "id": response.data.id,
            "sportId": response.data.sportId,
            "name": get(newSport, 'name', ''),
            "websiteIds": response.data.websiteIds
          });
      },
      setSelectedSport(newSport, response) {
        this.selectedSport = {
            sportWithTooltip: {
              "id": response.data.id,
              "sportId": response.data.sportId,
              "tooltips": response.data.tooltips,
              "websiteIds": response.data.websiteIds
            },
            rowData: {
              'Sport Id': response.data.sportId,
              'Sport': get(newSport, 'name', '')
            }
          }
        this.selectedSportId = 0;
      }
    },
  }
</script>

<style lang="scss" scoped>
.c-back-button {
  min-height: 36px;
}
.md-file-input {
  display: none;
}

.u-cursor-pointer {
  cursor: pointer;
  padding: 30px 0 30px 0;
}

.custom-file-upload {
  margin: 0;
  cursor: pointer;
  padding: 10px;
}

.md-button-file-upload {
  outline: none;
}

.hidden {
  display: none;
}
</style>
