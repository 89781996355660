import moment from "moment/moment";
import { terminalService } from "../../services/terminal";

function getInitialState() {
    return {
      currencies: [],
      paymentsPage: {
        items: []
      },
      isFetchingPaymentsPage: false,
      isFetchingCurrencies: false
    }
  }
  
  const state = getInitialState();
  
  const actions = {
    async getPaymentPage({ commit, dispatch }, filter) {
      try {
        commit('SET_IS_FETCHING_PAYMENT_PAGE', true);
        dispatch('response/setGlobalError', null, { root: true });
        const response = await terminalService.getPaymentReport(filter);
        commit('GET_PAYMENT_PAGE_SUCCESS', response.data);
      } catch (error) {
        dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
      } finally {
        commit('SET_IS_FETCHING_PAYMENT_PAGE', false);
      }
    },

    async getPaymentCurrencies({ commit, dispatch }) {
      try {
        commit('SET_IS_FETCHING_PAYMENT_CURRENCIES', true);
        dispatch('response/setGlobalError', null, { root: true });
        const response = await terminalService.getPaymentCurrencies();
        commit('GET_PAYMENT_CURRENCIES_SUCCESS', response.data);
      } catch (error) {
        dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
      }
      finally {
        commit('SET_IS_FETCHING_PAYMENT_CURRENCIES', false);
      }
    },
  }
  
  const getters = {
    paymentPage: state => state.paymentsPage,
    getPaymentsForTable: state => state.paymentsPage.items.map(item => {
        return {
          rowData: {
            'Old System ID': item.oldSystemTransactionId,
            'New System ID': item.newSystemTransactionId,
            time: moment(item.timeStamp).format('DD.MM.YY. HH:mm'),
            sender: item.senderId,
            'Sender Full Name': item.senderFullName,
            'Terminal ID': item.terminalId,
            receiver: item.receiverId,
            status: item.status,
            amount: `${item.amount.toFixed(2)} ${item.currency}`,
          }
        }
      }),
    isFetchingPaymentsPage: state => state.isFetchingPaymentsPage,
    getCurrencies: state => state.currencies.map(currency => {return {key: currency}}),
    isFetchingCurrencies: state => state.isFetchingCurrencies,
  }
  
  const mutations = {
    GET_PAYMENT_PAGE_SUCCESS(state, data) {
      state.paymentsPage = data
    },
    SET_IS_FETCHING_PAYMENT_PAGE(state, data) {
      state.isFetchingPaymentsPage = data
    },
    GET_PAYMENT_CURRENCIES_SUCCESS(state, data) {
      state.currencies = data
    },
    SET_IS_FETCHING_PAYMENT_CURRENCIES(state, data) {
      state.isFetchingCurrencies = data
    }
  }
  
  export const terminal = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
  }