<template>
  <div class="l-main">
    <div class="c-imgarena__component">
      <md-tabs
        class="c-tabs c-tabs--medium c-imgarena__sidebar"
        :md-active-tab="selectedSubsection"
      >
        <md-tab
          v-for="subsection in availableSubsections"
          :id="subsection"
          :key="subsection"
          :md-label="subsection"
          @click="changeSubsection(subsection)"
        />
      </md-tabs>

      <div class="l-main__ctn u-overflow-auto">
        <form
          v-if="selectedSubsection === 'configuration'"
          novalidate
          @submit.prevent="validateAndSave"
        >
          <md-field :class="getValidationClass($v.imgArenaConfiguration.stringSimilarityPercentage)">
            <label for="stringSimilarityPercentage">Names similarity percentage</label>
            <md-input
              v-model="imgArenaConfiguration.stringSimilarityPercentage"
              v-focus
              name="stringSimilarityPercentage"
              type="string"
              class="md-input"
              autocomplete="off"
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.IMG_ARENA.key}`)"
            />
            <div v-if="$v.imgArenaConfiguration.stringSimilarityPercentage.$error" class="md-error">
              Value must be a number between 0 and 100
            </div>
          </md-field>

          <md-field :class="getValidationClass($v.imgArenaConfiguration.excludedRegionIds)">
            <label for="excludedRegionIds">Excluded region ids</label>
            <md-input
              v-model="imgArenaConfiguration.excludedRegionIds"
              v-focus
              name="excludedRegionIds"
              type="string"
              class="md-input"
              autocomplete="off"
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.IMG_ARENA.key}`)"
            />
          </md-field>

          <div class="u-ml-auto u-mt-md">
            <generic-button
              variation="red"
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.IMG_ARENA.key}`)"
              @click="validateAndSave"
            >
              Save
            </generic-button>
          </div>
        </form>

        <template v-if="selectedSubsection === 'pairs'">
          <div class="c-modal-body--search">
            <md-field>
              <label>Search</label>
              <md-input v-model="searchQuery" @input="handleSearch" />
            </md-field>
          </div>

          <div class="md-layout sports-wrapper">
            <generic-button
              v-for="sport in sports"
              :id="sport.id"
              :key="sport.id"
              :variation="sport.id === activeSport.id ? 'orange' : ''"
              @click="changeSport(sport)"
            >
              {{ sport.name }}
            </generic-button>
          </div>

          <div class="c-table-wrapper l-history-logs__table">
            <md-table v-model="tableData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-card>
              <md-table-empty-state
                md-label="No events found"
                :md-description="`There are no events matching current criteria`"
              />

              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Stream Event" md-sort-by="rowData.streamEvent">
                  {{ item.rowData.streamEvent }}
                </md-table-cell>
                <md-table-cell md-label="Stream ID" md-sort-by="rowData.streamId">
                  {{ item.rowData.streamId }}
                </md-table-cell>
                <md-table-cell md-label="Feed Event" md-sort-by="rowData.feedEvent">
                  {{ item.rowData.feedEvent }}
                </md-table-cell>
                <md-table-cell md-label="Feed ID" md-sort-by="rowData.feedId">
                  {{ item.rowData.feedId }}
                </md-table-cell>
                <md-table-cell md-label="Start Time" md-sort-by="rowData.startTime">
                  {{ item.rowData.startTime }}
                </md-table-cell>
                <md-table-cell md-label="Match Index" md-sort-by="rowData.matchIndex">
                  {{ item.rowData.matchIndex }}
                </md-table-cell>
                <md-table-cell>
                  <generic-button
                    icon
                    transparent
                    @click="handleOpenEditModal(item.eventData)"
                  >
                    <md-tooltip md-direction="top">
                      Edit Img Arena stream pairing
                    </md-tooltip>
                    <md-icon>edit</md-icon>
                  </generic-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {PERMISSION_NAMES} from "@/const/online-permissions";
import {mapActions} from "vuex";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {validationBaseMixin} from '@/mixins/ValidationBaseMixin';
import GenericButton from "../../../../../shared/buttons/GenericButton";
import {imgArenaService} from "@/services/img-arena";
import {integer, maxValue, minValue, required} from "vuelidate/lib/validators";
import UpdateImgArenaPairModal from "./update"
import moment from "moment";
import {get} from "lodash";

export default {
  name: "ImgArenaConfiguration",

  components: {
    GenericButton
  },

  mixins: [onlinePermissionsMixin, validationBaseMixin],

  validations: {
    imgArenaConfiguration: {
      stringSimilarityPercentage: {
        required,
        integer,
        minValueValue: minValue(0),
        maxValueValue: maxValue(100),
      },
    }
  },

  data() {
    return {
      imgArenaConfiguration: {
        stringSimilarityPercentage: '',
        excludedRegionIds: ''
      },
      events: [],
      pairedEvents: [],
      selectedSubsection: 'pairs',
      availableSubsections: ['configuration', 'pairs'],
      currentSort: 'rowData.startTime',
      currentSortOrder: 'desc',
      searchQuery: '',
      activeSport: {},
      tableData: [],
      sports: [
        {name: 'Football', id: 58},
        {name: 'Basketball', id: 55},
        {name: 'Tennis', id: 56},
        {name: 'Table Tennis', id: 89},
        {name: 'Volleyball', id: 54},
        {name: 'Handball', id: 60},
        {name: 'Ice Hockey', id: 59},
        {name: 'Rugby', id: 94},
        {name: 'Cricket', id: 66},
        {name: 'E Sports', id: 122},
        {name: 'Baseball', id: 63},
        {name: 'Motor Racing', id: 67},
        {name: 'Snooker', id: 69},
        {name: 'Boxing', id: 76},
        {name: 'Golf', id: 85},
        {name: 'Martial Arts', id: 87},
        {name: 'Badminton', id: 90},
        {name: 'Shooting', id: 102},
        {name: 'Pool', id: 112},
        {name: 'Hockey', id: 126},
      ]
    }
  },

  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
  },

  async created() {
    this.activeSport = this.sports[0];

    await this.getImgArenaConfig();
    await this.getImgArenaPairs();
    await this.getImgArenaStreamData(this.sports[0].id);
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError"]),

    goToHome() {
      this.$router.push({name: 'home'});
    },

    async getImgArenaConfig() {
      try {
        const response = await imgArenaService.getImgArenaConfig();
        const data = response.data;

        const config = {};

        data.forEach(configItem => {
          if (this.imgArenaConfiguration[configItem.parameter] !== undefined) {
            config[configItem.parameter] = configItem.value;
          }
        })

        this.imgArenaConfiguration = config;
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    async getImgArenaStreamData(sportId = 55) {
      try {
        const response = await imgArenaService.getImgArenaStreamData(sportId);
        const data = response.data;

        let events = data[sportId] || [];
        events = events.map(event => {
          return {
            ...event,
            manuallyPairedEvent: this.pairedEvents.find(pairedEvent => pairedEvent.streamId === event.streamEvent.id)
          }
        })

        events.sort(this.sortEventsFunction)

        this.events = events;
        this.formatTableData();
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    sortEventsFunction(a, b) {
      // First, sort by manuallyPairedEvent presence
      if (a.manuallyPairedEvent && !b.manuallyPairedEvent) {
        return -1;
      } else if (!a.manuallyPairedEvent && b.manuallyPairedEvent) {
        return 1;
      }

      // Second, sort by pairedEvent presence
      if (a.pairedFeedEvent && !b.pairedFeedEvent) {
        return -1;
      } else if (!a.pairedFeedEvent && b.pairedFeedEvent) {
        return 1;
      }

      // Finally, sort by streamEvent.startTime
      return a.streamEvent.startTime - b.streamEvent.startTime;
    },

    async getImgArenaPairs() {
      const response = await imgArenaService.getPairedEvents();
      this.pairedEvents = response.data;
    },

    validateAndSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveImgArenaConfiguration();
      }
    },

    async saveImgArenaConfiguration() {
      try {
        const config = Object.keys(this.imgArenaConfiguration).map(configKey => {
          return {
            parameter: configKey,
            value: this.imgArenaConfiguration[configKey]
          }
        });

        await imgArenaService.updateImgArenaConfig(config);
        this.handleSuccess({message: "Img Arena configurations updated successfully !"});

      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    handleOpenEditModal(eventData) {
      this.$modal.show(
        UpdateImgArenaPairModal,
        {
          eventData: eventData,
          handleSuccessCallback: this.handleSuccessfulUpdate,
        },
        {height: "auto", scrollable: false, width: "80%"}
      );
    },

    async handleSuccessfulUpdate() {
      await this.getImgArenaPairs();
      await this.getImgArenaStreamData(this.activeSport.id);
    },

    changeSubsection(subsection) {
      this.selectedSubsection = subsection;

      if (subsection === 'configuration') {
        this.getImgArenaConfig();
      }
    },

    changeSport(sport) {
      this.activeSport = sport;
      this.searchQuery = '';
      this.getImgArenaStreamData(sport.id)
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        const aValue = get(a, sortBy) || '-';
        const bValue = get(b, sortBy) || '-';

        switch (sortBy) {
          case 'rowData.streamId':
            return this.sortNumbersFunction(aValue, bValue);

          case 'rowData.matchIndex':
            return this.sortPercentagesFunction(aValue, bValue);

          default:
            if (this.currentSortOrder === 'desc') {
              return aValue.localeCompare(bValue);
            }

            return bValue.localeCompare(aValue);
        }
      })
    },

    sortNumbersFunction(a, b) {
      return this.currentSortOrder === 'desc' ? a - b : b - a;
    },

    sortPercentagesFunction(a, b) {
      const aValue = +a.replace(/[%-]/, '');
      const bValue = +b.replace(/[%-]/, '');

      return this.sortNumbersFunction(aValue, bValue);
    },

    handleSearch() {
      this.formatTableData();
    },

    formatTableData() {
      const matchPairs = [];

      this.events
        .filter(event => {
          const searchQuery = this.searchQuery.toLowerCase();

          return event.streamEvent.oldTitle.toLowerCase().includes(searchQuery)
            || event.streamEvent.id.toString().includes(searchQuery)
            || event.pairedFeedEvent?.name.toLowerCase().includes(searchQuery)
            || event.pairedFeedEvent?.id.includes(searchQuery)
        })
        .forEach(eventData => {
          const matchedFeedEvent = eventData.pairedFeedEvent;

          matchPairs.push({
            eventData,
            rowData: {
              'streamEvent': eventData.streamEvent.oldTitle,
              'streamId': eventData.streamEvent.id,
              'feedEvent': eventData.manuallyPairedEvent ? eventData.manuallyPairedEvent.feedEventName : matchedFeedEvent ? matchedFeedEvent.oldName : '-',
              'feedId': eventData.manuallyPairedEvent ? eventData.manuallyPairedEvent.feedEvent.id : matchedFeedEvent ? matchedFeedEvent.id : '-',
              'startTime': moment(eventData.streamEvent.startDate).format('DD/MM/YYYY HH:mm'),
              'matchIndex': eventData.manuallyPairedEvent ? 'Manual' : matchedFeedEvent ? (matchedFeedEvent.rating * 100).toFixed(2) + '%' : '-',
            }
          });
        });

      this.tableData = matchPairs;
    }
  },
};
</script>

<style lang="scss" scoped>
.c-imgarena__component {
  display: flex;
  flex: 1;
  max-height: 100%;
}

.c-imgarena__sidebar {
  max-width: 200px;
}

.c-modal-body--search {
  padding: 0 16px;
}

.sports-wrapper {
  padding: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  gap: 10px;
  margin-bottom: 10px;

  button {
    min-width: auto;
    padding: 5px 5px;
    flex-grow: 1;
    max-width: 200px;
  }
}

</style>
<script setup>
</script>
