<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled"
            @click="goToHome"
          >
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToUsers"
          >Users</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">{{ userEmail }}</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs" :md-active-tab="selectedTab">
        <md-tab
          v-for="tab in userEditTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component
        :is="currentTab"
        @canChangeTab="canChangeTab"
      />
    </div>
  </div>
</template>

<script>
import { userEditTabs } from '../../../const';
import OnlinePermission from './online-permission';
import { mapGetters } from 'vuex';
import { findIndex, get } from 'lodash';
import { userService } from '../../../services/user';
import ConfirmationModalComponent from '../../../shared/confirmation-modal/ConfirmationModalComponent';

export default {
  components: {
    OnlinePermission
  },
  data() {
    return {
      currentTab: 'online-permission',
      selectedTab: 'online-permission',
      userEmail: '',
      canOpenModal: false
    };
  },
  computed: {
    ...mapGetters('user', ['users']),
    userEditTabs() {
      return userEditTabs;
    }
  },
  created() {
    this.setUserEmail();
  },
  beforeRouteLeave(to, from, next) {
    if (this.canOpenModal) {
      this.openConfirmationModal(() => { next() });
      return;
    }
    return next();
  },
  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
      if (this.currentTab === tab) {
        return;
      }

      if (this.canOpenModal) {
        this.openConfirmationModal(() => { this.previousTab = tab; this.currentTab = tab; this.canOpenModal = false; });
        return;
      }
      this.previousTab = tab;
      this.currentTab = tab;
    },

    openConfirmationModal(onAccept) {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Your changes will be lost,
              are you sure you want to continue?`,
            onAccept: onAccept,
            onClose: () => {
              setTimeout(() => {
                this.selectedTab = this.previousTab;
              });
            },
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },

    canChangeTab(canOpenModal) {
      this.canOpenModal = canOpenModal;
    },
    goToHome() {
      this.$router.push({ name: 'home' });
    },
    goToUsers() {
      this.$router.push({ name: 'users' });
    },
    async setUserEmail() {
      const index = findIndex([], user => user.id == this.$route.params.id);

      if (index > -1) {
        this.userEmail = this.users[index].email
      } else {
        await this.fetchUserAndSetEmail();
      }
    },
    async fetchUserAndSetEmail() {
      const data = await userService.getSingleUser(this.$route.params.id);
      this.userEmail = get(data, 'data.email', '');
    }
  }
};
</script>

<style></style>
