<template>
  <div class="l-main">
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-websites">
        <base-table-component :prop-data="tableData">
          <template v-slot:action>
            <div class="header-table-section">
              Configuration Section
            </div>

            <div class="add-button">
              <div>
                List of configuration sections
              </div>
              <div class="add-section">
                <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateSection()"><span>Add New Configration Section</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="isDeveloper"
                icon
                transparent
                text-inherit
                @click="createOrUpdateSection(slotProps.selected.section)"
              >
                <md-tooltip md-direction="top"> Edit </md-tooltip>
                <img src="../../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>

            <span class="c-table__btn-wrap">
              <generic-button
                v-if="isDeveloper"
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.section)"
              >
                <md-tooltip md-direction="top"> Delete </md-tooltip>
                <img src="../../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import BaseTableComponent from '../../../../../shared/table/BaseTableComponent'
import DeleteModalComponent from '../../../../../shared/delete-modal/DeleteModalComponent'
import CreateSectionModal from './create'
import get from 'lodash/get'
import GenericButton from '../../../../../shared/buttons/GenericButton'
import { onlinePermissionsMixin } from "@/mixins/onlinePermissionsMixin";

export default {
  name: "OnlineSectionsList",

  components: {
    BaseTableComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('onlineConfiguration', ['sections']),

    tableData() {
      return this.sections.map(section => ({
        section: section,
        rowData: {
          name: section.name,
        }
      }));
    },
  },

  created() {
    this.getOnlineConfigurationSections();
  },

  methods: {
    ...mapActions('onlineConfiguration', [
      'getOnlineConfigurationSections',
      'deleteOnlineConfigurationSection',
    ]),

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    openDeleteModal(selected) {
      this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: selected.name,
            propData: selected.id,
            deleteFunction: this.deleteOnlineConfigurationSection,
            getDataFunction: this.getOnlineConfigurationSections,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },

    createOrUpdateSection(section = {}) {
      this.$modal.show(
          CreateSectionModal,
          {
            section: {
              name: get(section, 'name', ''),
            },
            sectionId: get(section, 'id'),
          },
          { height: "auto", scrollable: true }
      );
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
