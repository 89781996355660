import { customPagesService } from '../../services/custom-pages'
import get from "lodash/get";

function getInitialState() {
  return {
    customPages: [],
    customPagesFetched: false,
  }
}

const state = getInitialState()

const actions = {
  // SECTIONS
  async getCustomPages({ commit, dispatch }) {
    try {
      commit('SET_CUSTOM_PAGES_FETCHED', true)
      const { data } = await customPagesService.getAll()

      commit('SET_CUSTOM_PAGES', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
      commit('SET_CUSTOM_PAGES_FETCHED', false)
    }
  },

  async createCustomPage({ commit, dispatch }, createData) {
    try {
      const { data } = await customPagesService.create(createData)
      commit('CREATE_CUSTOM_PAGE', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  async updateCustomPage({ commit, dispatch }, { id, updateData }) {
    try {
      const { data } = await customPagesService.update(id, updateData)
      commit('UPDATE_CUSTOM_PAGE', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  async deleteCustomPage({ commit, dispatch }, id) {
    try {
      await customPagesService.delete(id)
      commit('DELETE_CUSTOM_PAGE', id)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },
}

const getters = {
  customPages: customPagesState => customPagesState.customPages,
  customPagesFetched: customPagesService => customPagesService.customPagesFetched,
}

const mutations = {
  SET_CUSTOM_PAGES(state, data) {
    state.customPages = data
  },

  SET_CUSTOM_PAGES_FETCHED(state, data) {
    this.customPagesFetched = data
  },

  CREATE_CUSTOM_PAGE(state, data) {
    state.customPages.push(data)
  },

  UPDATE_CUSTOM_PAGE(state, data) {
    state.customPages = state.customPages.map(customPage => {
      if (customPage.id === data.id) {
        return data
      }
      return customPage
    })
  },

  DELETE_CUSTOM_PAGE(state, id) {
    state.customPages = state.customPages.filter(customPage => customPage.id !== id)
  },
}

export const customPages = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
