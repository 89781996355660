<template>
  <div v-if="showTab" class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled" @click="goToHome">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item" @click="goToCompanies">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Companies</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item" @click="goToMarket">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">{{ companyName }}</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">{{ marketName }}</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <component
        :is="currentTab"
        v-if="availableTabs.length"
        :type-id="marketId"
        :type="typeName"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import GenericButton from '../../shared/buttons/GenericButton';
import AllBetshops from '../betshops/all-betshops';

export default {
  components: {
    GenericButton,
    AllBetshops,
  },
  data() {
    return {
      currentTab: 'all-betshops',
      marketId: null,
      typeName: 'market',
      availableTabs: [
        {
          tab: 'all-betshops',
          label: 'Betshops',
          section: 'betShops',
          subItems: [],
        }
      ],
      selectedTab: 'all-betshops',
      previousTab: 'all-betshops',
      showTab: false,
      initApiConfigurations: {},
      canOpenModal: false
    };
  },
    
  computed: {
    ...mapState('company', ['companyName', 'marketName', 'errorFromRest']),
  },

  async created() {
    await this.getMarketBredCrumps(this.$route.params.marketId);
    this.setDefaultTab();
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'markets') {
      this.SET_COMPANY_NAME(null);
    }
    this.SET_MARKET_NAME(null);
    next();
  },

  methods: {
    ...mapActions('company', ['getMarketBredCrumps']),
    ...mapMutations('company', ['SET_COMPANY_NAME', 'SET_MARKET_NAME']),

    goToHome() {
      this.$router.push({ name: 'home' });
    },
    goToCompanies() {
      this.$router.push({ name: 'companies' });
    },

    goToMarket() {
      this.$router.push({
        name: 'markets',
        params: { marketId: this.$route.params.companyId },
      });
    },

    setDefaultTab() {
      const firstAvailableTab = this.availableTabs[0].tab

      this.currentTab = firstAvailableTab;
      this.selectedTab = firstAvailableTab;
      this.previousTab = firstAvailableTab;
      this.showTab = true;
    }
  },
};
</script>
