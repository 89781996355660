import {apiService} from '../api'
import {stringify} from "querystring";
import {replacePathParams} from "@/helpers/urlHelpers";

const ENDPOINTS = {
  HISTORY_LOG: '/history-log',
  HISTORY_LOG_SINGLE: '/history-log/:historyLogId',
}

class HistoryLogsService {
  async getHistoryLogs(queryParams) {
    let endpoint = ENDPOINTS.HISTORY_LOG;

    let query = stringify(queryParams);

    if (query) {
      endpoint += `?${query}`;
    }

    return apiService.get(endpoint)
  }

  async updateHistoryLog(historyLogId, data) {
    return apiService.put(replacePathParams(ENDPOINTS.HISTORY_LOG_SINGLE, {historyLogId}), data)
  }
}

export const historyLogsService = new HistoryLogsService()
