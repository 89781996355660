import {PERMISSION_NAMES} from "@/const/online-permissions";

export const logoFileExtensions = ['png', 'jpg', 'jpeg', 'gif'];

export const userEditTabs = [
  {
    tab: 'online-permission',
    label: 'Online'
  }
]

export const websiteTabs = [
  {
    tab: 'seo-rules',
    label: 'Seo rules',
    permission: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`
  },
  {
    tab: 'tooltips',
    label: 'Tooltips',
    permission: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`
  }
]
