<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        {{ createOrUpdate }} Sections
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
        @submit.prevent="validateUser"
      >
        <md-field :class="getValidationClass($v.section.name)">
          <label for="name">Name</label>
          <md-input
            v-model="section.name"
            v-focus
            name="name"
            type="name"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.section.name.required"
            class="md-error"
          >The name is required</span>
        </md-field>
        <error-response-messages :field-name="'name'" />
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <div v-if="section.role === 2" class="u-mr-xs">
        <generic-button
          variation="red"
          @click="validateUser(true)"
        >
          Create and set permissions
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateUser"
      >
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { validationBaseMixin } from '../../../../../../mixins/ValidationBaseMixin'
import GenericButton from '../../../../../../shared/buttons/GenericButton'
import ErrorResponseMessages from '../../../../../../shared/error-response-messages/ErrorResponseMessages'
import BaseModalComponent from '../../../../../../shared/modal/BaseModalComponent'
import { responseService } from '../../../../../../services/response'


export default {
  name: 'CreateSectionModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],

  props: {
    section: {
      type: Object,
      required: true,
    },
    sectionId: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
    };
  },

  computed: {
    ...mapGetters("response", ["success", "error"]),

    createOrUpdate() {
      return !this.sectionId ? 'Create' : 'Update';
    },
  },

  validations: {
    section: {
      name: {
        required
      },
    },
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),
    ...mapActions('onlineConfiguration', ['createOnlineConfigurationSection', 'updateOnlineConfigurationSection']),

    async createSection() {
      try {
        await this.createOnlineConfigurationSection(this.section);
        this.handleSuccess({ message: "Custom Page Created!" });
        this.close();
      } catch (err) {

        this.handleError(responseService.getErrorMessage(err.data.message[0]));
      }
    },

    async updateSection() {
      try {
        await this.updateOnlineConfigurationSection({ id: this.sectionId, updateData: this.section });
        this.handleSuccess({ message: "Section Updated!" });
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message[0]));
      }
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        !this.sectionId ? this.createSection() : this.updateSection();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
