import {store} from "@/store/store";

export const isDeveloper = (to, from, next) => {
  const user = store.getters['account/user'];

  if (!user.isDeveloper) {
    return next({name: 'home'});
  }

  next();
}

export const isAdmin = (to, from, next) => {
  const user = store.getters['account/user'];

  if (!(user.role === 1 || user.isDeveloper)) {
    return next({ name: 'home' });
  }

  next();
}

