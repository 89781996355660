import { helpService } from '../../services/help'
import get from "lodash/get";

function getInitialState() {
  return {
    helpLinks: [],
    helpLinksFetched: false,
  }
}

const state = getInitialState()

const actions = {
  async getHelpLinks({ commit, dispatch }) {
    try {
      commit('SET_HELP_LINK_FETCHED', true)
      const { data } = await helpService.getHelpLinks()

      commit('SET_HELP_LINKS', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
      commit('SET_HELP_LINK_FETCHED', false)
    }
  },

  async createHelpLink({ commit, dispatch }, createData) {
    try {
      const { data } = await helpService.createHelpLink(createData)
      commit('CREATE_HELP_LINK', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  async updateHelpLink({ commit, dispatch }, { id, updateData }) {
    try {
      const { data } = await helpService.updateHelpLink(id, updateData)
      commit('UPDATE_HELP_LINK', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  async deleteHelpLink({ commit, dispatch }, id) {
    try {
      await helpService.deleteHelpLink(id)
      commit('DELETE_HELP_LINK', id)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },
}

const getters = {
  helpLinks: helpLinksState => helpLinksState.helpLinks,
  helpLinksFetched: helpService => helpService.helpLinksFetched,
}

const mutations = {
  SET_HELP_LINKS(state, data) {
    state.helpLinks = data
  },

  SET_HELP_LINK_FETCHED(state, data) {
    this.helpLinksFetched = data
  },

  CREATE_HELP_LINK(state, data) {
    state.helpLinks.push(data)
  },

  UPDATE_HELP_LINK(state, data) {
    state.helpLinks = state.helpLinks.map(helpLink => {
      if (helpLink.id === data.id) {
        return data
      }
      return helpLink
    })
  },

  DELETE_HELP_LINK(state, id) {
    state.helpLinks = state.helpLinks.filter(helpLink => helpLink.id !== id)
  },
}

export const helpLinks = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
