import {apiService} from '@/services/api'
import config from '/config/local'
import axios from "axios";

const ENDPOINTS = {
  IMG_ARENA_CONFIGURATION: '/img-arena-stream/config',
  PAIRED_EVENTS: '/img-arena-stream/paired-events',
}

class ImgArenaService {
  constructor() {
    this.imgArenaClient = axios.create({});
  }

  async getImgArenaStreamData(sportId) {
    return this.imgArenaClient.get(`${config.IMG_ARENA_API}/api/events/backoffice?ids=${sportId}`)
  }

  async getImgArenaConfig() {
    return apiService.get(ENDPOINTS.IMG_ARENA_CONFIGURATION);
  }

  async updateImgArenaConfig(data) {
    return apiService.post(ENDPOINTS.IMG_ARENA_CONFIGURATION, data);
  }

  async getPairedEvents() {
    return apiService.get(ENDPOINTS.PAIRED_EVENTS);
  }

  async updatePairedEvents(data) {
    return apiService.post(ENDPOINTS.PAIRED_EVENTS, data);

  }
}

export const imgArenaService = new ImgArenaService()
