<template>
  <div class="l-main">
    <div class="l-main__header">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-70">
          <ul class="c-breadcrumbs">
            <li class="c-breadcrumbs__item">
              <a
                class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled"
                @click="goToHome"
              >
                <md-icon>home</md-icon>
                <span>Home</span>
              </a>
            </li>
            <li class="c-breadcrumbs__item--separator">
              /
            </li>
            <li class="c-breadcrumbs__item">
              <a class="c-breadcrumbs__link u-cursor-default">Help</a>
            </li>
            <template v-if="selectedDomain.website && selectedTab.key !== 'help-settings'">
              <li class="c-breadcrumbs__item--separator u-cursor-default">
                /
              </li>
              <li class="c-breadcrumbs__item is-current">
                <a class="c-breadcrumbs__link">{{ selectedDomain.website.name }}</a>
              </li>
            </template>
            <li class="c-breadcrumbs__item--separator">
              /
            </li>
            <li class="c-breadcrumbs__item">
              <a class="c-breadcrumbs__link u-cursor-default">{{ selectedTab.label }}</a>
            </li>
          </ul>
        </div>
        <div class="md-layout-item md-size-30">
          <md-field v-if="selectedTab.key !== 'help-settings' && availableHelpDomains.length" class="c-domain-dropdown">
            <md-select v-model="domainId" :disabled="disableSelectDomain" @md-selected="handleDomainChange">
              <md-option
                v-for="domain in availableHelpDomains"
                :key="domain.id"
                :value="domain.id"
              >
                {{ domain.website.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs v-if="selectedTab" class="c-tabs c-tabs--large" :md-active-tab="selectedTab.key">
        <md-tab
          v-for="tab in tabs"
          :id="tab.key"
          :key="tab.key"
          :md-label="tab.label"
          :md-disabled="tab.disabled"
          @click="changeActiveTab(tab)"
        />
      </md-tabs>

      <component
        :is="selectedTab.component"
        @domainUpdated="handleUpdateHelpDomains"
        @deleteDomain="handleDeleteDomain"
        @disableSelectDomain="handleDisableSelectDomain"
        @enableDomains="handleEnableDomains"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {PERMISSION_NAMES} from "@/const/online-permissions";
import HelpPagesComponent from './sub-pages/help-pages';
import HelpSettingsComponent from './sub-pages/help-settings';
import HelpCategoriesComponent from './sub-pages/help-categories';
import HelpContactSectionComponent from './sub-pages/help-contact-section';
import HelpContactFormComponent from './sub-pages/help-contact-form';
import HelpHistoryLogsComponent from './sub-pages/help-history-logs';
import find from 'lodash/find';
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";

export default {
  name: "HelpComponent",

  components: {
    HelpPagesComponent,
    HelpSettingsComponent,
    HelpCategoriesComponent,
    HelpContactSectionComponent,
    HelpContactFormComponent,
    HelpHistoryLogsComponent,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      permissionName: `${PERMISSION_NAMES.HELP.key}.${PERMISSION_NAMES.HELP.key}`,
      selectedTab: { label: 'Pages', component: 'HelpPagesComponent', key: 'help-pages' },
      tabs: [
        { label: 'Pages', component: 'HelpPagesComponent', key: 'help-pages' },
        { label: 'Categories', component: 'HelpCategoriesComponent', key: 'help-categories' },
        { label: 'Settings', component: 'HelpSettingsComponent', key: 'help-settings' },
        { label: 'Import', component: '', key: 'help-import', disabled: true },
        { label: 'Contact', component: 'HelpContactSectionComponent', key: 'help-contact-section' },
        { label: 'Contact Form', component: 'HelpContactFormComponent', key: 'help-contact-form' },
        { label: 'History Logs', component: 'HelpHistoryLogsComponent', key: 'help-history-logs' },
      ],
      domainId: undefined,
      disableSelectDomain: false
    }
  },

  computed: {
    ...mapGetters('helpDomains', ['helpDomains', 'getSelectedDomainId']),

    availableHelpDomains() {
      return this.helpDomains.filter(helpDomain => this.canReadHelpWebsite(helpDomain.websiteId));
    },

    selectedDomain() {
      return this.availableHelpDomains.find(domain => domain.id === this.domainId) || {};
    }
  },
  async created() {
    await this.getHelpDomains();
    await this.setInitSelectedDomain();
  },
  methods: {
    ...mapActions('helpDomains', [
      'getHelpDomains',
      'selectDomain',
      'selectWebsiteIdFromDomain'
    ]),
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    goToHome() {
      return this.$router.push({ name: 'home' });
    },

    handleDomainChange() {
      this.selectDomain(this.domainId);
      this.selectWebsiteIdFromDomain(this.selectedDomain.websiteId);
    },

    setInitSelectedDomain() {
      if (!this.availableHelpDomains || !this.availableHelpDomains[0]) {
        this.domainId = null;
        return this.selectDomain(this.domainId);
      }
      this.domainId = this.availableHelpDomains[0].id
      this.selectDomain(this.domainId);
      this.selectWebsiteIdFromDomain(this.availableHelpDomains[0].websiteId);
    },

    async handleUpdateHelpDomains() {
      await this.getHelpDomains();

      this.setInitSelectedDomain();
    },

    async handleDeleteDomain() {
      await this.getHelpDomains();
      const hasSelectedDomain = find(this.availableHelpDomains, {id: this.domainId});
      if (hasSelectedDomain) {
        return;
      }
      this.setInitSelectedDomain();
    },

    handleDisableSelectDomain() {
      this.disableSelectDomain = true;
    },

    handleEnableDomains() {
      this.disableSelectDomain = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
