<template>
  <div id="app">
    <router-view />
    <modals-container class="overflow-important" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters('theme', ['getCurrentTheme']),
  },
  mounted() {
    document.body.classList.add(this.getCurrentTheme);
  },
};
</script>

<style lang="scss">
@import './assets/styles/main';
</style>
