import { authService } from '../../services/auth'

const token = localStorage.getItem('token')

function getInitialState() {
  return {
    status: {},
    user: {},
    userOnlinePermissions: {},
    loginError: null,
    authToken: token || null,
    needsActivation: false,
    activationError: null,
  }
}

const state = getInitialState()

const actions = {
  async handleLogin({ commit }, credential) {
    try {
      commit('LOGIN_ERROR', null);
      const response = await authService.login(credential);
      if (!response.data) {
        commit('NEEDS_ACTIVATION', true);
      }

      return response;
    } catch(error) {
      console.info('Failed login', error)
      commit('LOGIN_ERROR', error.data.message)
      throw error
    }
  },

  async handleActivation({ commit }, data) {
    try {
      localStorage.setItem('activation-token', data.activationToken);

      commit('ACTIVATION_ERROR', null);
      return await authService.activate(data);
    } catch(response) {
      console.info(response)
      commit('LOGIN_ERROR', response.data.message)
    }
  }
}

const getters = {
  user: state => state.user,
  isLoggedIn: state => state.status.loggedIn,
  activationError: state => state.activationError,
  needsActivation: state => state.needsActivation,
  userAllowedOnLineSidebarItems: state => state.userAllowedOnLineSidebarItems,
  userOnlinePermissions: state => state.userOnlinePermissions,
}

const mutations = {
  LOGIN_SUCCESS(
    state,
    {
      access_token,
      userAllowedOnLineSidebarItems,
      userOnlinePermissions,
      user,
    }
  ) {
    state.status = { loggedIn: true };
    state.authToken = access_token;
    state.user = user;
    state.userAllowedOnLineSidebarItems = userAllowedOnLineSidebarItems;
    state.userOnlinePermissions = userOnlinePermissions;
  },
  NEEDS_ACTIVATION(state, data) {
    state.needsActivation = data;
  },
  LOGIN_ERROR(state, data) {
    state.loginError = data;
  },
  ACTIVATION_ERROR(state, data) {
    state.activationError = data;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
