import { isArray, each } from 'lodash'


class ResponseService {
  getErrorMessage(messages) {
    if (isArray(messages)) {
      const errors = {};
      each(messages, message => {
        errors[message.property] = [];
        each(message.constraints, (m) => {
          errors[message.property].push(m)
        })
      })
      return errors;
    }
    
    return { messages };
  }
}

export const responseService = new ResponseService()
