<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ updateOrCreate }} Translation
      </h4>
      <generic-button
        variation="yellow"
        @click="toggleDrawer($event, translationData)"
      >
        Add Exceptions
      </generic-button>
    </template>
    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>

      <div
        v-if="error && error.sectionId"
      >
        <p
          v-for="(error, index) in error.sectionId"
          :key="index"
          class="md-error"
        >
          {{ error }}
        </p>
      </div>
      <form
        class="u-width"
        novalidate
      >
        <md-field :class="getValidationClass($v.translationData.word)">
          <label>Key</label>
          <md-input
            v-model="translationData.word"
            v-focus
            :disabled="isTooltipTranslation"
          />
          <span
            v-if="!$v.translationData.word.required"
            class="md-error"
          >Key is required</span>
        </md-field>
        <div class="md-layout-item select-button">
          <div class="md-layout-item">
            <md-field :class="getValidationClass($v.translationData.sectionId)">
              <label for="linuxLink">Choose section</label>
              <md-select
                id="section"
                v-model="translationData.sectionId"
                name="section"
                placeholder="Section"
                :disabled="!sections.length || isTooltipTranslation"
                @md-selected="changedOption"
              >
                <md-option
                  v-for="section in translationData.wordId ? sections : sections.filter(section => section.key !== tooltipSectionKey)"
                  :key="section.id"
                  :value="section.id"
                >
                  {{ section.title }}
                </md-option>
              </md-select>
              <span
                v-if="!$v.translationData.sectionId.required"
                class="md-error"
              >Section is required</span>
            </md-field>
            <span
              v-if="!sections.length"
            >No sections available, in order to add traslation, first create section.</span>
          </div>
        </div>
        <div
          v-for="(language, index) in languages"
          :key="language.id"
        >
          <md-field>
            <label>{{ language.name }}</label>
            <md-input
              v-if="translationData.translation[index]"
              v-model="translationData.translation[index].phrase"
            />
          </md-field>
          <div v-if="exceptions[language.id]">
            <div v-for="(exception, exceptionIndex) in exceptions[language.id]" :key="exceptionIndex">
              <div v-if="!exception.isDeleting" class="c-input-group-exceptions">
                <div>Exception for: {{ exception.websiteUrl }}</div>
                <input
                  v-model="exception.phrase"
                  class="c-input-exception"
                >
                <generic-button
                  icon
                  md-menu-trigger
                  @click="removeExceptions(language.id, exceptionIndex, exception)"
                >
                  <img src="../../../../../../assets/delete-button.svg" alt="">
                </generic-button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="page-container md-layout-columnx">
        <md-drawer class="md-right" :md-active.sync="showExceptionPanel">
          <md-toolbar class="md-transparent u-p-lg u-bo u-margin-10" md-elevation="0">
            <div class="u-flex-column">
              <span class="md-title">Exception</span>
              <span class="c-card__description">Key: {{ translationData.word }}</span>
            </div>
            <md-field v-if="showExceptionPanel && websites.length" class="c-select-field">
              <label>Select website</label>
              <md-select
                v-model="exceptionDataForAdd.websiteId"
              >
                <md-option
                  v-for="website in websites"
                  :key="website.id"
                  :value="website.id"
                >
                  {{ website.url }}
                </md-option>
              </md-select>
            </md-field>
            <md-field v-if="languages.length" class="c-select-field">
              <label>Select language</label>
              <md-select
                v-model="exceptionDataForAdd.languageId"
              >
                <md-option
                  v-for="language in languages"
                  :key="language.id"
                  :value="language.id"
                >
                  {{ language.name }}
                </md-option>
              </md-select>
            </md-field>
            <div v-if="exceptionDataForAdd">
              <md-field>
                <label>Phrase</label>
                <md-input
                  v-model="exceptionDataForAdd.phrase"
                />
              </md-field>
              <div class="u-flex-row-center">
                <div style="margin-right: 5px">
                  <generic-button
                    variation="grey"
                    class="u-mr-md"
                    @click="closeExceptionPanel()"
                  >
                    Cancel
                  </generic-button>
                </div>
                <generic-button
                  variation="red"
                  class="u-mr-md"
                  :disabled="!exceptionDataForAdd.languageId || !exceptionDataForAdd.phrase || !exceptionDataForAdd.websiteId"
                  @click="addExceptionToList(exceptionDataForAdd)"
                >
                  Add Exception
                </generic-button>
              </div>
            </div>
          </md-toolbar>
        </md-drawer>
      </div>

      <div class="u-flex-row-center">
        <div class="u-mr-xs">
          <generic-button
            variation="grey"
            @click="close"
          >
            Close
          </generic-button>
        </div>
        <generic-button
          variation="red"
          @click="validateForm"
        >
          {{ updateOrCreate }}
        </generic-button>
      </div>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { translationService } from "../../../../../../services/translation";
import BaseModalComponent from "../../../../../../shared/modal/BaseModalComponent";
import GenericButton from "../../../../../../shared/buttons/GenericButton";
import { responseService } from "../../../../../../services/response/index";
import { validationBaseMixin } from '../../../../../../mixins/ValidationBaseMixin'
import { required } from "vuelidate/lib/validators";
import {websiteService} from "@/services/websites";
import each from "lodash/each";
import {findIndex, forEach} from "lodash";
import ConfirmationModalComponent from "@/shared/confirmation-modal/ConfirmationModalComponent.vue";
import AlertModalComponent from "@/shared/alert-modal/AlertModalComponent.vue";

export default {
  name: 'Index',
  components: {
    BaseModalComponent,
    GenericButton
  },
  mixins: [validationBaseMixin],
  props: {
    sections: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    fetchCurrentPage: {
      type: Function,
      required: true
    },
    selectedWord: {
      default: null
    },
    translationData: {
      required: true
    }
  },
  data() {
    return {
      showDropdown: false,
      tooltipSectionKey: 'tooltips',
      showExceptionPanel: false,
      websites: [],
      exceptionDataForAdd: {
        websiteId: 0,
        phrase: '',
        languageId: 0,
        isDeleting: false
      },
      exceptions: {}
    };
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),

    updateOrCreate() {
      return this.translationData.wordId ? 'Update' : 'Create';
    },
    selectedSection() {
      return this.translationData.wordId ? this.translationData.sectionTitle : "";
    },
    isTooltipTranslation() {
      return this.translationData && this.translationData.section && this.translationData.section.key === this.tooltipSectionKey
    }
  },
  validations: {
    translationData: {
      word: {
        required,
      },
      sectionId: {
        required
      }
    }
  },

  created () {
    this.setTranslationsForGivenLanguages();
    this.setUpExceptionsInArray()
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    changedOption() {
    },

    setUpExceptionsInArray() {
      each(this.translationData.exceptions, exception => {
        const language = this.languages.find(language => language.id === exception.languageId);
        if (language) {
          exception['languageCode'] = language.code;
        }
        exception['websiteUrl'] = exception.website.url;
        exception['isDeleting'] = false;
        this.exceptionDataForAdd = {...exception};
        this.addExceptionToArray(this.exceptionDataForAdd);
        this.restartExceptions();
      })
    },

    setSectionId(section) {
      this.translationData.sectionId = section.id;
      this.selectedSection = section.title;
      this.toggleDropdown();
    },

    async toggleDrawer() {
      if (!this.websites.length) {
        this.websites = await this.getWebsites();
      }
      this.showExceptionPanel = !this.showExceptionPanel;
    },

    closeExceptionPanel() {
      this.restartExceptions();
      this.showExceptionPanel = false;
    },

    addExceptionToList(translationException) {
      const website = this.websites.find(website => website.id === translationException.websiteId);
      translationException.websiteUrl = website.url

      if(this.validateExceptions(translationException)) {
        this.openAlertModal(`Exception for ${website.url} already exists`);

        return;
      }

      this.showExceptionPanel = false;

      const language = this.languages.find(language => language.id === translationException.languageId);

      if (language) {
        translationException['languageCode'] = language.code
      }

      this.addExceptionToArray(translationException);
      this.restartExceptions();
    },

    addExceptionToArray(translationException) {
      if (this.exceptions[translationException.languageId]) {
        this.exceptions[translationException.languageId].push(translationException)
      } else  {
        this.exceptions[translationException.languageId] = [translationException];
      }
    },

    validateExceptions(translationException) {
      return findIndex(this.exceptions[translationException.languageId], (oldException) =>
          oldException.websiteId === translationException.websiteId && !oldException.isDeleting
      ) > -1;
    },

    async getWebsites(page, limit) {
      const response = await websiteService.getWebsites(page, limit);

      return response.data;
    },

    isExceptionsArrayValid() {
      if (this.translationData.exceptions && this.translationData.exceptions.length) {
        return findIndex(this.translationData.exceptions,
            exception => !exception.phrase && !exception.isDeleting) == -1
      }
      return true;
    },

    validateForm() {
      this.$v.$touch();
      this.prepareExceptionsForSave();

      if (!this.isExceptionsArrayValid()) {
        this.openAlertModal(`Phrase cannot be empty`);
        return
      }

      if (!this.$v.$invalid) {
        this.saveOrUpdateTranslation();
      }
    },

    prepareExceptionsForSave() {
      let data = [];
      forEach(Object.values(this.exceptions), exports => {
        data.push(...exports);
      })

      this.translationData.exceptions = data;
    },

    async saveOrUpdateTranslation() {
      try {
        delete this.translationData.languagesIds;
        await this.saveOrUpdate()
        this.close();
        this.fetchCurrentPage();
        this.handleSuccess({ message: `Translation ${this.updateOrCreate}d!` });
        this.resetError();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
        const modalElement = document.getElementsByClassName('v--modal-box')[0];

        if (modalElement) {
          modalElement.scrollTop = 0;
        }
      }
    },

    async saveOrUpdate() {
      return this.translationData.wordId ?
        await translationService.updateOnline(this.translationData) :
        await translationService.saveOnline(this.translationData);
    },

    restartExceptions() {
      this.exceptionDataForAdd = {
        languageId: 0,
        websiteId: 0,
        phrase: '',
        isDeleting: false,
        languageCode: ''
      }
    },

    removeExceptions( languageId, index, exception) {
      this.openConfirmationModal(() => {
        exception.isDeleting = true;
        this.exceptions[languageId][index] = exception;
      });
    },

    close() {
      this.$emit('close');
      this.resetError();
    },

    setTranslationsForGivenLanguages() {
      if (this.languages.length === 0 || this.translationData.length > 0) {
        return;
      }

      this.languages.forEach(language => {
        if (!this.translationData.wordId || !this.translationData.languagesIds.includes(language.id)) {
          this.translationData.translation.push({ languageId: language.id, phrase: "", languageCode: language.code });
        }
      });
    },

    openAlertModal(message) {
      this.$modal.show(
          AlertModalComponent,
          {
            alertMessage: message,
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },

    openConfirmationModal(onAccept) {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Do you want to remove translation`,
            onAccept: onAccept,
            onClose: () => {},
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },
  },
};
</script>
