import { themeService } from '../../services/theme/index';

function getInitialState() {
  return {
    nightMode: themeService.isNightMode(),
  };
}

const state = getInitialState();

const actions = {
  handleThemeToggle({ commit }, data) {
    themeService.handleThemeToggle(data);
    commit('TOGGLE_THEME', data);
  },
};

const getters = {
  getCurrentTheme: () => themeService.getCurrentTheme(),
  isNightMode: state => state.nightMode
};

const mutations = {
  TOGGLE_THEME(state, data) {
    state.nightMode = data;
  },
};

export const theme = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
