export const defaultOnLineSidebarItemsDeveloper = [
  {
    key: "configurations-setup",
    pathName: null,
    icon: "settings",
    text: "Configurations Setup",
    expandedSync: false,
    subItems: [
      {
        key: "online-configuration-sections",
        pathName: "online-configuration-sections",
        icon: "description",
        text: "Sections",
        subItems: [],
      },
      {
        key: "online-configuration-types",
        pathName: "online-configuration-types",
        icon: "description",
        text: "Types",
        subItems: [],
      },
      {
        key: "online-configuration-settings",
        pathName: "online-configuration-settings",
        icon: "description",
        text: "Configuration settings",
        subItems: [],
      },
    ],
  },
];
