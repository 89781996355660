import { apiService } from '../api'

const ENDPOINTS = {
  WEBSITE_CONFIGURATIONS: '/website/:websiteId/website-online-configuration',
  CONFIGURATION_SECTIONS: '/online-configuration-sections',
  CONFIGURATION_SUBSECTIONS: '/online-configuration-subsections',
  CONFIGURATION_TYPES: '/online-configuration-types',
  ONLINE_CONFIGURATIONS: '/online-configurations',
}

class OnlineConfigurationService {
  async createOrUpdate(websiteId, data) {
    return apiService.post(ENDPOINTS.WEBSITE_CONFIGURATIONS.replace(':websiteId', websiteId), data)
  }

  async getWebsiteConfigurations(websiteId) {
    return apiService.get(ENDPOINTS.WEBSITE_CONFIGURATIONS.replace(':websiteId', websiteId))
  }

  // SECTIONS

  async getOnlineConfigurationSections() {
    return apiService.get(ENDPOINTS.CONFIGURATION_SECTIONS)
  }

  async createOnlineConfigurationSection(data) {
    return apiService.post(ENDPOINTS.CONFIGURATION_SECTIONS, data)
  }

  async updateOnlineConfigurationSection(id, data) {
    return apiService.put(ENDPOINTS.CONFIGURATION_SECTIONS + `/${id}`, data)
  }

  async deleteOnlineConfigurationSection(id) {
    return apiService.delete(ENDPOINTS.CONFIGURATION_SECTIONS + `/${id}`)
  }

  // TYPES

  async getOnlineConfigurationTypes() {
    return apiService.get(ENDPOINTS.CONFIGURATION_TYPES)
  }

  async createOnlineConfigurationType(data) {
    return apiService.post(ENDPOINTS.CONFIGURATION_TYPES, data)
  }

  async updateOnlineConfigurationType(id, data) {
    return apiService.put(ENDPOINTS.CONFIGURATION_TYPES + `/${id}`, data)
  }

  async deleteOnlineConfigurationType(id) {
    return apiService.delete(ENDPOINTS.CONFIGURATION_TYPES + `/${id}`)
  }

  // SUBSECTIONS

  async getOnlineConfigurationSubsections() {
    return apiService.get(ENDPOINTS.CONFIGURATION_SUBSECTIONS)
  }

  // ONLINE CONFIGURATIONS

  async getOnlineConfigurations() {
    return apiService.get(ENDPOINTS.ONLINE_CONFIGURATIONS)
  }

  async createOnlineConfiguration(data) {
    return apiService.post(ENDPOINTS.ONLINE_CONFIGURATIONS, data)
  }

  async updateOnlineConfiguration(id, data) {
    return apiService.put(ENDPOINTS.ONLINE_CONFIGURATIONS + `/${id}`, data)
  }

  async deleteOnlineConfiguration(id) {
    return apiService.delete(ENDPOINTS.ONLINE_CONFIGURATIONS + `/${id}`)
  }

}

export const onlineConfigurationService = new OnlineConfigurationService()
