<template>
  <div class="c-config-input-type u-block">
    <div class="c-config-input-info c-config-input-info-full">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
    </div>

    <md-field>
      <label for="customPage">Page</label>
      <md-select id="customPage" v-model="selectedPage" name="customPage">
        <md-option
          v-for="page in availablePages"
          :key="page.id"
          :value="page.name"
        >
          {{ page.name }}
        </md-option>
      </md-select>
    </md-field>

    <md-tabs
      class="c-tabs c-tabs--small"
      :md-active-tab="selectedLanguage"
    >
      <md-tab
        v-for="language in languages"
        :id="language"
        :key="`${input.id}-${language}`"
        :md-label="language"
        @click="changeLanguage(language)"
      />
    </md-tabs>

    <div class="inputs-wrapper">
      <div class="input">
        <label>HTML</label>
        <textarea
          :id="`${selectedPage}-${selectedLanguage}-html`"
          :value="getValue(selectedPage, selectedLanguage, 'html')"
          rows="20"
          :disabled="disabled"
          @input="setValue(selectedPage, selectedLanguage, 'html', $event)"
        />
      </div>

      <div class="input">
        <label>CSS</label>
        <textarea
          :id="`${selectedPage}-${selectedLanguage}-css`"
          :value="getValue(selectedPage, selectedLanguage, 'css')"
          rows="20"
          :disabled="disabled"
          @input="setValue(selectedPage, selectedLanguage, 'css', $event)"
        />
      </div>

      <div class="input">
        <label>Application CSS</label>
        <textarea
          :id="`${selectedPage}-${selectedLanguage}-application-css`"
          :value="getValue(selectedPage, selectedLanguage, 'application_css')"
          rows="20"
          :disabled="disabled"
          @input="setValue(selectedPage, selectedLanguage, 'application_css', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { get, setWith } from 'lodash'

export default {
  name: 'PageMultiLanguageHtmlCssApplicationCssFormField',

  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    languages: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      inputValue: {},
      selectedPage: 'default',
      selectedLanguage: this.languages[0],
    }
  },

  computed: {
    ...mapGetters('customPages', ['customPages', 'customPagesFetched']),

    availablePages() {
      return [
        { id: 0, name: 'default' },
        ...this.customPages
      ]
    },

    defaultInputValue() {
      try {
        return JSON.parse(get(this.input, 'defaultValue', {}))
      } catch (e) {
        return {}
      }
    },
  },

  created() {
    const inputValue = get(this.input, 'value', this.defaultInputValue)
    this.inputValue = inputValue || {}

    if (!this.customPagesFetched) {
      this.getCustomPages();
    }
  },

  methods: {
    ...mapActions('customPages', ['getCustomPages']),

    getValue(page, language, type) {
      return get(
          this.inputValue,
          `${ page }.${ language }.${ type }`,
          get(
              this.defaultInputValue,
              `${ page }.${ language }.${ type }`,
              '',
          ),
      );
    },

    setValue(page, language, type, event) {
      setWith(this.inputValue, `${ page }.${ language }.${ type }`, event.target.value, Object)
      this.$emit('update', {
        value: JSON.stringify(this.inputValue)
      })
    },

    changeLanguage(language) {
      this.selectedLanguage = language
    },
  },
}

</script>
<style lang="scss" scoped>
.inputs-wrapper {
  display: flex;
  padding: 10px;

  div {
    width: 100%;
    margin: 10px;
  }

  textarea {
    width: 100%;
  }
}

label {
  display: block;
}
</style>
