<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ historyLog.title }}
      </h4>
    </template>

    <template v-slot:body>
      <div v-if="historyLog.user.email" class="c-changelog-info-item">
        <span class="c-changelog-info-item--label">User:</span> <span class="c-changelog-info-item--value">{{ historyLog.user.email }}</span>
      </div>
      <div v-if="historyLog.description.message" class="c-changelog-info-item">
        <span class="c-changelog-info-item--label">Action:</span> <span class="c-changelog-info-item--value">{{ historyLog.description.message }}</span>
      </div>
      <div v-if="historyLog.message" class="c-changelog-info-item">
        <span class="c-changelog-info-item--label">Message:</span> <span class="c-changelog-info-item--value">{{ historyLog.message }}</span>
      </div>

      <div class="c-changelog-container">
        <div v-if="historyLog.description.old" class="c-changelog-item">
          <p class="c-changelog-item-label">
            Before action
          </p>
          <pre>{{ historyLog.description.old }}</pre>
        </div>
        <div v-if="historyLog.description.new" class="c-changelog-item">
          <p class="c-changelog-item-label">
            After action
          </p>
          <pre>{{ historyLog.description.new }}</pre>
        </div>

        <div v-if="historyLog.description.changed" class="c-changelog-item changed">
          <p class="c-changelog-item-label">
            Changed
          </p>
          <div class="c-changelog-table">
            <md-table>
              <md-table-row>
                <md-table-head>Key</md-table-head>
                <md-table-head>From</md-table-head>
                <md-table-head>To</md-table-head>
              </md-table-row>
              <md-table-row v-for="(item, index) in historyLog.description.changed" :key="index">
                <md-table-cell class="u-font-weight-bold">
                  {{ item.key }}
                </md-table-cell>
                <md-table-cell>{{ item.old }}</md-table-cell>
                <md-table-cell>{{ item.new }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Close
        </generic-button>
      </div>
    </template>
  </base-modal-component>
</template>

<script>

import GenericButton from "@/shared/buttons/GenericButton.vue";
import BaseModalComponent from "@/shared/modal/BaseModalComponent.vue";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    historyLog: {
      type: Object,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  }
};
</script>
<style lang="scss" scoped>
.c-changelog-container {
  display: flex;
  gap: 20px;
}

.c-changelog-item-label {
  font-weight: bold;
  font-size: 1.8rem;
}

.c-changelog-item {
  padding: 20px 50px;
  max-width: 50%;

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.2;
  }

  &:not(:last-child) {
    border-right: 1px solid #eee;
  }

  &.changed {
    width: 100%;
    max-width: 100%;
  }
}

.c-changelog-info-item {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.c-changelog-info-item--label {
  width: 100px;
}

.c-changelog-info-item--value {
  font-weight: bold;
}

</style>
