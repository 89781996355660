<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        Are you sure you want to delete:
        <div class="c-delete-msg__wrapper">
          <span class="c-delete-msg">{{ deleteMsg }}</span>?
        </div>
        <div v-if="deleteMsgAlert">
          {{ deleteMsgAlert }}
        </div>
      </h4>
    </template>

    <template v-slot:body>
      <p v-if="deleteDescription">
        {{ deleteDescription }}
      </p>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="deleteData()"
      >
        Delete
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../modal/BaseModalComponent';
import GenericButton from "../../shared/buttons/GenericButton";
import { mapActions } from "vuex";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    propData: {
      reqired: true,
      default: 1
    },
    deleteFunction: {
      required: true,
      type: Function
    },
    pageData: {
      required: false,
      type: Object,
      default: null
    },
    getDataFunction: {
      required: false,
      type: Function,
      default: () => {}
    },
    deleteMsg: {
      required: true,
      type: String
    },
    deleteDescription: {
      required: false,
      type: String,
      default: '',
    },
    deleteMsgAlert: {
      required: false,
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions("response", ["handleSuccess"]),

    close() {
      this.$emit('close');
    },

    deleteWithoutFetchingData() {
      this.deleteFunction(this.propData);
      this.close();
    },

    deleteWithFetchingData() {
      this.deleteFunction(this.propData)
        .then(() => {
          this.$emit('close');
          this.getDataFunction(this.pageData.currentPage, this.pageData.limit);
          this.handleSuccess({ message: 'Successfully deleted!' });
        });
    },

    deleteData() {
      this.pageData
        ? this.deleteWithFetchingData()
        : this.deleteWithoutFetchingData();
    },
  },
};
</script>
