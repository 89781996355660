import Vue from 'vue';
import Router from 'vue-router';
import { authService } from '../services/auth';
import ActivatePage from '../pages/activate';
import LoginPage from '../pages/login';
import Companies from '../pages/companies';
import Markets from '../pages/markets';
import Betshops from '../pages/betshops';
import AllUsers from '../pages/user';
import EditUSer from '../pages/user/update-user';
import TranslationPage from '../pages/on-line/translation';
import OnlinePage from "../pages/on-line";
import AllOnlineLanguage from '../pages/on-line/translation/language';
import AllOnlineSectionPage from '../pages/on-line/translation/section';
import AllOnlineTranslationsPage from '../pages/on-line/translation/all-translations';
import WebsitesPage from '../pages/on-line/websites';
import EditWebsite from '../pages/on-line/websites/single-website';
import OnlineConfigurationsPage from '../pages/on-line/configurations';
import OnlineConfigurationSectionsPage from '../pages/on-line/configuration-settings/components/sections';
import OnlineConfigurationTypesPage from '../pages/on-line/configuration-settings/components/types';
import OnlineConfigurationSettingsPage from '../pages/on-line/configuration-settings/components/settings';
import ConfigurationSettings from '../pages/on-line/configuration-settings';
import HelpPage from '../pages/on-line/help';
import TerminalPayments from '../pages/terminal-payments';
import { isDeveloper } from "@/guards/permissionGuard";
import { isAdmin } from "@/guards/permissionGuard";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: "*", redirect: '/home' },
    { path: '/', redirect: '/home' },
    {
      path: '/home',
      name: 'home',
      component: OnlinePage,
      meta: { authRequired: true },
      children: [
        { name: 'websites', path: 'websites', component: WebsitesPage },
        { name: 'websites-edit', path: 'websites/:id', component: EditWebsite },
        { name: 'users', path: 'all-users', component: AllUsers, beforeEnter: isAdmin },
        { name: 'users-edit', path: 'all-users/:id', component: EditUSer, beforeEnter: isAdmin },
        { name: 'companies', path: 'companies', component: Companies, beforeEnter: isAdmin },
        { name: 'markets', path: 'company/:companyId/markets', component: Markets, beforeEnter: isAdmin },
        { name: 'betshops', path: 'company/:companyId/markets/:marketId/betshops', component: Betshops, beforeEnter: isAdmin },
        { name: 'terminal-payments', path: 'terminal-payments', component: TerminalPayments },
        {
          name: 'translation', path: 'translation', component: TranslationPage,
          children: [
            {
              name: 'all-translations',
              path: 'all-translations',
              component: AllOnlineTranslationsPage,
            },
            {
              name: 'languages', path: 'language', component: AllOnlineLanguage,
            },
            {
              name: 'sections', path: 'section', component: AllOnlineSectionPage,
            },
          ],
        },
        {
          name: 'configurations',
          path: 'configurations',
          component: OnlineConfigurationsPage,
        },
        {
          name: 'configurations-settings',
          path: 'configurations-settings',
          component: ConfigurationSettings,
          beforeEnter: isDeveloper,
          children: [
            {
              name: 'online-configuration-sections',
              path: 'sections',
              component: OnlineConfigurationSectionsPage,
              beforeEnter: isDeveloper,
            },
            {
              name: 'online-configuration-types',
              path: 'types',
              component: OnlineConfigurationTypesPage,
              beforeEnter: isDeveloper,
            },
            {
              name: 'online-configuration-settings',
              path: 'settings',
              component: OnlineConfigurationSettingsPage,
              beforeEnter: isDeveloper,
            },
          ],
        },
        {
          name: 'on-line-help',
          path: 'on-line-help',
          component: HelpPage,
        },
      ],
    },
    { path: '/login', name: 'login', component: LoginPage, meta: { unauthenticated: true } },
    { path: '/activate', name: 'activate', component: ActivatePage, meta: { unauthenticated: true }}
  ]
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = authService.isLoggedIn();

  if (to.name === from.name) {
    return;
  }

  if (to.meta.authRequired && !isLoggedIn) {
    return next({ name: 'login' })
  }

  if (isLoggedIn && from.name !== 'login' && to.name !== 'login') {
    return authService.attachPermissions(to, next);
  }

  if (isLoggedIn && to.name === 'login') {
    return next({name: 'websites'})
  }

  next();
})

export default router
