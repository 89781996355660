<template>
  <div class="l-main">
    <div class="l-main__ctn u-box u-p-lg u-overflow-auto">
      <h4 class="u-typography-color md-title">
        Set up Big Jackpot configuration
      </h4>
      <form
        novalidate
        @submit.prevent="validateAndSave"
      >
        <md-field :class="getValidationClass($v.jackpotData.startValue)">
          <label for="start-value">Start Value</label>
          <md-input
            v-model="jackpotData.startValue"
            v-focus
            name="start-value"
            type="string"
            class="md-input"
            autocomplete="off"
          />
          <div v-if="!$v.jackpotData.startValue.required" class="md-error">
            Start value is required.
          </div>
          <div v-if="!$v.jackpotData.startValue.validateStartValuteLessOrEqualThanZero && $v.jackpotData.startValue.required" class="md-error">
            Start value cannot be zero.
          </div>
          <div v-if="!$v.jackpotData.startValue.validateStartValueOnlyNumbers && $v.jackpotData.startValue.required" class="md-error">
            Start value must be number.
          </div>
          <div v-if="!$v.jackpotData.startValue.validateStartValueBiggerThanMaxValue && $v.jackpotData.startValue.required" class="md-error">
            Start value cannot be bigger or equal than maximum value.
          </div>
        </md-field>

        <md-field :class="getValidationClass($v.jackpotData.maxValue)">
          <label for="url">Max Value</label>
          <md-input
            v-model="jackpotData.maxValue"
            name="max-value"
            type="string"
            class="md-input"
            autocomplete="off"
          />
          <div v-if="!$v.jackpotData.maxValue.required" class="md-error">
            Max value is required.
          </div>
          <div v-if="!$v.jackpotData.maxValue.validateMaxValueOnlyNumbers && $v.jackpotData.maxValue.required" class="md-error">
            Max value must be number.
          </div>
        </md-field>


        <md-field :class="getValidationClass($v.jackpotData.timeRange)">
          <label for="timeRange">Time Range in days (minimum 1)</label>
          <md-input
            v-model="jackpotData.timeRange"
            name="timeRange"
            type="text"
            class="md-input"
            autocomplete="off"
            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          />
          <div v-if="!$v.jackpotData.startValue.validateTimeRangeBiggerOrEqualThanZero" class="md-error">
            Time range is not valid.
          </div>
        </md-field>

        <div class="u-ml-auto u-mb-md add-button__new-wrapper" @click="addNewCurrency()">
          <img src="../../../../../assets/add-button.svg" class="add-button__new" alt=""><span>Add new currency</span>
        </div>

        <div v-for="(currency, index) in jackpotData.currencyRanges" :key="index" class="currency-ranges-wrapper">
          <md-field class="currency-ranges-item">
            <label for="start-value">Currency Name</label>
            <md-input
              v-model="currency.name"
              type="string"
              class="md-input"
            />
          </md-field>

          <md-field class="currency-ranges-item">
            <label for="start-value">From</label>
            <md-input
              v-model="currency.bigJackpotValueFrom"
              type="string"
              class="md-input"
            />
          </md-field>

          <md-field class="currency-ranges-item">
            <label for="start-value">To</label>
            <md-input
              v-model="currency.bigJackpotValueTo"
              type="string"
              class="md-input"
            />
          </md-field>

          <generic-button
            :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.BIG_JACKPOT.key}`)"
            icon
            transparent
            text-inherit
            @click="removeCurrency(index)"
          >
            <md-tooltip
              md-direction="top"
            >
              Delete
            </md-tooltip>
            <img src="../../../../../assets/delete-button.svg" alt="">
          </generic-button>
        </div>
      </form>

      <div class="u-ml-auto u-mt-md">
        <generic-button
          variation="red"
          @click="validateAndSave"
        >
          Save
        </generic-button>
      </div>

      <div class="u-separate-section">
        <generic-button
          variation="red"
          @click="handleFinishJackpotClick"
        >
          Finish jackpot
        </generic-button>
      </div>
    </div>
  </div>
</template>

<script>

import { PERMISSION_NAMES } from "@/const/online-permissions";
import { mapActions } from "vuex";
import { onlinePermissionsMixin } from "@/mixins/onlinePermissionsMixin";
import { validationBaseMixin } from '@/mixins/ValidationBaseMixin';
import GenericButton from "../../../../../shared/buttons/GenericButton";
import { bigJackpotService } from "../../../../../services/big-jackpot/index";
import { get } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import ConfirmationModalComponent from '@/shared/confirmation-modal/ConfirmationModalComponent';

export default {
  name: "BigJackpotConfiguration",

  components: {
    GenericButton
  },

  mixins: [onlinePermissionsMixin, validationBaseMixin],

  data() {
    return {
      onlyDevelopersAllowed: true,
      currentJackpotId: null,
      jackpotData: {
        startValue: '',
        maxValue: '',
        timeRange: '',
        currencyRanges: []
      }
    }
  },

  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },

    startValueBiggerThanMaxValue() {
      return Number(this.jackpotData.startValue) >= Number(this.jackpotData.maxValue);
    },

    startValueLessOrEqualThanZero() {
      return Number(this.jackpotData.startValue) <= 0;
    },

    timeRangeLessOrEqualThanZero() {
      return Number(this.jackpotData.timeRange) <= 0 || this.jackpotData.timeRange.toString().startsWith('0')
    },

    isStartValueNumber() {
      return !Number(this.jackpotData.startValue);
    },

    isMaxValueNumber() {
      return !Number(this.jackpotData.maxValue);
    },
  },

  async created() {
    await this.getBigJackpotConfiguration();
  },
  validations() {
    return {
      jackpotData: {
        startValue: {
          required,
          validateStartValueOnlyNumbers: this.validateStartValueOnlyNumbers,
          validateStartValueBiggerThanMaxValue: this.validateStartValueBiggerThanMaxValue,
          validateStartValuteLessOrEqualThanZero: this.validateStartValuteLessOrEqualThanZero,
        },
        maxValue: {
          required,
          validateMaxValueOnlyNumbers: this.validateMaxValueOnlyNumbers,
        },
        timeRange: {
          validateTimeRangeBiggerOrEqualThanZero: this.validateTimeRangeBiggerOrEqualThanZero
        }
      }
    }
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError"]),

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    addNewCurrency() {
      this.jackpotData.currencyRanges.push({
        name: '',
        bigJackpotValueFrom: '',
        bigJackpotValueTo: ''
      })
    },

    removeCurrency(index) {
      this.jackpotData.currencyRanges.splice(index, 1);
    },

    handleFinishJackpotClick() {
      this.$modal.show(
        ConfirmationModalComponent,
        {
          confirmationMsg: `
            You are about to finish current jackpot,
            are you sure you want to continue?`,
          onAccept: this.handleFinishJackpot,
          onClose: () => {

          },
        },
        { height: "auto", scrollable: false, width: "400px" }
      );
    },

    async handleFinishJackpot() {
      try {
        const response = await bigJackpotService.finishActiveJackpot();

        this.handleSuccess({ message: `Jackpot finished successfully status: ${get(response, 'data.status')}` });

      } catch (err) {
        this.setGlobalError(err);
      }
    },

    async saveJackpotConfiguration() {
      this.updateOrCreate();
    },

    async getBigJackpotConfiguration() {
      try {
        const response = await bigJackpotService.get();

        if (!get(response, 'data.id')) {
          return;
        }

        const currencyRanges = get(response, 'data.currencyRanges');

        this.currentJackpotId = get(response, 'data.id');
        this.jackpotData.startValue = Number(get(response, 'data.startValue'));
        this.jackpotData.maxValue = Number(get(response, 'data.maxValue'));
        this.jackpotData.timeRange = get(response, 'data.timeRange');
        this.jackpotData.currencyRanges = currencyRanges ? JSON.parse(currencyRanges) : []

      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    async updateOrCreate() {
      this.jackpotData.currencyRanges = this.jackpotData.currencyRanges.filter(currency => !!currency.name);

      const preparedJackpotData = {
        startValue: Number(this.jackpotData.startValue),
        maxValue: Number(this.jackpotData.maxValue),
        timeRange: Number(this.jackpotData.timeRange),
        currencyRanges: JSON.stringify(this.jackpotData.currencyRanges)
      };

      try {
        if (this.currentJackpotId) {
          await bigJackpotService.update({ ...preparedJackpotData }, this.currentJackpotId);
          this.handleSuccess({ message: "Jackpot configuration updated!" });

          return;
        }

        const createdConfiguration = await bigJackpotService.create(preparedJackpotData);
        this.currentJackpotId = get(createdConfiguration, 'data.id');

        this.handleSuccess({ message: "Jackpot configuration saved!" });
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    validateAndSave () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveJackpotConfiguration();
      }
    },

    validateTimeRange() {
      if (this.jackpotData.timeRange.toString().startsWith(0)) {
        return false;
      }

      return true;
    },

    validateStartValueBiggerThanMaxValue() {
      return !this.startValueBiggerThanMaxValue;
    },

    validateStartValuteLessOrEqualThanZero() {
      return !this.startValueLessOrEqualThanZero;
    },

    validateStartValueOnlyNumbers() {
      return !this.isStartValueNumber;
    },

    validateMaxValueOnlyNumbers() {
      return !this.isMaxValueNumber;
    },

    validateTimeRangeBiggerOrEqualThanZero() {
      return !this.timeRangeLessOrEqualThanZero;
    },
  },
};
</script>

<style lang="scss" scoped>

  .currency-ranges-wrapper {
    display: flex;
    align-items: center;
  }

  .currency-ranges-item {
    margin: 0 3px;
  }

  .add-button__new-wrapper  {
    cursor: pointer;
    display: inline-block;
  }

  .add-button__new {
    margin-right: 5px;
  }
</style>
