import axios from 'axios'
import assign from 'lodash/assign'
import router from '../../router/router.js'
import { apiService } from '../api'
import { account } from '../../store/modules/account.js'
import { store } from '../../store/store.js'
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { defaultOnLineSidebarItemsDeveloper } from '../../const/on-line-sidebar-items-developer'

const ENDPOINTS = {
  LOGIN: '/auth/login',
  LOGOUT:  '/auth/logout',
  CHECK_AUTH: '/auth/check-auth',
  ACTIVATE: '/auth/activate'
}

class AuthService {
  async login(data) {
    try {
      const response = await apiService.post(ENDPOINTS.LOGIN, data);

      if (!response.data) {
        return response;
      }

      this.handleLoginResponse(response)
      return response

    } catch(error) {
      throw error;
    }
  }

  async activate(data) {
    try {
      const response = await apiService.post(ENDPOINTS.ACTIVATE, data);

      if (!response.data) {
        return response;
      }

      this.handleLoginResponse(response)
      return response;
    } catch(error) {
      throw error;
    }
  }

  handleLoginResponse(response) {
    try {
      this.preparePermissionsAndRoutes(response.data);
      this.createSession(response.data);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async checkLogin() {
    if (!this.isLoggedIn()) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await apiService.post(ENDPOINTS.CHECK_AUTH, { token });
      return response.data;
    } catch(error) {
      this.logoutUserAndRedirectToLogin();
    }
  }

  async logout(email){
    try {
      await apiService.post(ENDPOINTS.LOGOUT, { email });
      this.logoutUserAndRedirectToLogin();
    } catch(error) {
      console.log(error);
    }
  }

  logoutUserAndRedirectToLogin() {
    if (router.currentRoute.name === 'login') {
      return;
    }

    this.setAuthHeaders('');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    router.push({ name: 'login' })
  }

  createSession(data) {
    localStorage.setItem('role', data.user.role)
    localStorage.setItem('token', data.access_token)

    this.setAuthHeaders(data.access_token)
  }

  setAuthHeaders(token) {
    assign(axios.defaults.headers, {
      'Authorization':  `Bearer ${token}`
    })
  }

  isLoggedIn() {
    return !!localStorage.getItem('token')
  }

  async fetchUserAndSetPermissions() {

    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.checkLogin();
        this.preparePermissionsAndRoutes(data);
        resolve();
      } catch (error) {
        store.dispatch('response/setGlobalError', 'Something went wrong');
        if (router.currentRoute.name === 'login') {
          return;
        }

        router.push({ name: 'login' })

        reject();
      }
    })
  }

  preparePermissionsAndRoutes(data) {
    const userOnlinePermissions = JSON.parse(get(data, "user.onlinePermissions"));
    const isDeveloper = get(data, 'user.isDeveloper');
    let userAllowedOnLineSidebarItems = [];

    if (isDeveloper) {
      userAllowedOnLineSidebarItems = userAllowedOnLineSidebarItems.concat(defaultOnLineSidebarItemsDeveloper);
    }

    account.mutations.LOGIN_SUCCESS(account.state, {
      ...data,
      userOnlinePermissions,
      userAllowedOnLineSidebarItems
    });
  }

  isAdmin() {
    return !isEmpty(account.state['user']) ? account.state['user'].role === 1 : null;
  }

  doesUserExist() {
    return !(account.state && account.state['user'].email);
  }

  async attachPermissions(to, next) {
    await this.fetchUserAndSetPermissions();

    next();
  }
}

export const authService = new AuthService()
