import Vue from 'vue'
import App from './App.vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import { store } from './store/store'
import router from './router/router'
import VModal from 'vue-js-modal'
import Paginate from 'vuejs-paginate'
import VueClipboard from 'vue-clipboard2'
import vClickOutside from 'v-click-outside'
import VueQuillEditor from 'vue-quill-editor'
import wysiwyg from "vue-wysiwyg";

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { editorOption } from './const/wyiwyg'

Vue.use(VueQuillEditor, editorOption)

Vue.component('paginate', Paginate)
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false }, scrollable: true })
Vue.use(VueMaterial)
Vue.use(VueClipboard)
Vue.use(vClickOutside)
Vue.config.productionTip = false
Vue.use(wysiwyg, {})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

// TODO: Remove this after https://github.com/vuematerial/vue-material/pull/2287 is merged.
Vue.config.errorHandler = (err) => {
  if (err.message !== "Cannot read property 'badInput' of undefined") {
    console.error(err);
  }
};
//
