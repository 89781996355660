<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        Change password
      </h4>
    </template>

    <template v-slot:body>
      <form
        novalidate
        @submit.prevent="validatePassword"
      >
        <p
          v-if="error"
          class="md-error"
        >
          {{ error }}
        </p>
        <md-field :class="getValidationClass('password')">
          <label>Password</label>
          <md-input
            v-model="password"
            type="password"
          />
          <span
            v-if="!$v.password.required"
            class="md-error"
          >The password is required</span>
        </md-field>

        <md-field :class="getValidationClass('newPassword')">
          <label>New password</label>
          <md-input
            v-model="newPassword"
            type="password"
          />
          <span
            v-if="!$v.newPassword.required"
            class="md-error"
          >The new password is required</span>
        </md-field>

        <md-field :class="getValidationClass('newPasswordConfirmation')">
          <label>New password confirmation</label>
          <md-input
            v-model="newPasswordConfirmation"
            type="password"
          />
          <span
            v-if="!$v.newPasswordConfirmation.required"
            class="md-error"
          >The password confirmation is required</span>
          <span
            v-if="$v.newPasswordConfirmation.required && !$v.newPasswordConfirmation.sameAsPassword"
            class="md-error"
          >Must be same as password</span>
        </md-field>
      </form>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validatePassword"
      >
        Submit
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { userService } from '../../services/user'
import { validationBaseMixin } from '../../mixins/ValidationBaseMixin'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import BaseModalComponent from '../../shared/modal/BaseModalComponent'
import GenericButton from "../../shared/buttons/GenericButton";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  mixins: [validationBaseMixin],
  data: () => {
    return {
      password: null,
      newPasswordConfirmation: null,
      newPassword: null,
      error: null
    }
  },
  validations: {
    password: {
      required
    },
    newPassword: {
      required,
    },
    newPasswordConfirmation: {
      required,
      sameAsPassword: sameAs('newPassword')
    }
  },
  computed: {
    ...mapGetters('account', ['user'])
  },
  methods: {
    ...mapActions('response', ['handleSuccess']),

    close() {
      this.$emit('close');
    },

    getValidationClass (fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    async validatePassword () {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.changePassword();
      }
    },

    async changePassword () {
      const data = {
        password: this.password,
        newPassword: this.newPassword,
        newPasswordConfirmation: this.newPasswordConfirmation,
        id: this.user.id
      }
      try {
        await userService.changePassword(data);
        this.error = null;
        this.handleSuccess({ message: 'Successful password changed!'});
        this.close();
      } catch (err) {
        this.error = err.data.message;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>