import { helpService } from '@/services/help'
import get from "lodash/get";

function getInitialState() {
  return {
    helpDomains: [],
    selectedDomainId: null,
    selectedWebsiteId: null
  }
}

const state = getInitialState()

const actions = {
  async getHelpDomains({ commit, dispatch }) {
    try {
      const { data } = await helpService.getHelpDomains()

      commit('SET_HELP_DOMAINS', data)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  selectDomain({ commit, dispatch }, id) {
    try {
      commit('SELECT_HELP_DOMAIN', id)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },

  selectWebsiteIdFromDomain({ commit, dispatch }, websiteId) {
    try {
      commit('SELECT_WEBSITE_FROM_DOMAIN', websiteId)
    } catch (e) {
      dispatch('response/setGlobalError', get(e, 'data.message[0]', 'Problem with REST'), { root: true });
    }
  },
}

const getters = {
  helpDomains: helpDomainsState => helpDomainsState.helpDomains,
  getSelectedDomainId: helpDomainsState => helpDomainsState.selectedDomainId,
  getSelectedWebsiteId: helpDomainsState => helpDomainsState.selectedWebsiteId,
}

const mutations = {
  SET_HELP_DOMAINS(state, data) {
    state.helpDomains = data
  },

  SELECT_HELP_DOMAIN(state, id) {
    state.selectedDomainId = id;
  },

  SELECT_WEBSITE_FROM_DOMAIN(state, id) {
    state.selectedWebsiteId = id;
  },
}

export const helpDomains = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
