<template>
  <div>
    <div v-if="!isCreteOrUpdatePage && getSelectedDomainId">
      <div class="add-button">
        <div v-if="categories && categories.length" class="c-search">
          <md-field class="u-width-m u-mb-xs u-mr-md">
            <label>Search</label>
            <md-input v-model="searchQuery" />
          </md-field>
        </div>
        <div v-if="canWriteHelpWebsite(getSelectedWebsiteId)" class="add-section">
          <img src="@/assets/add-button.svg" class="add-button__new" alt="" @click="setCreateOrEditPage()">
          <span>Add New Page</span>
        </div>
      </div>
      <md-list>
        <template v-if="!displayCategories.length && categories !== null">
          <div class="l-empty-container u-full-height">
            <md-empty-state
              md-icon="announcement"
              md-label="No Data"
            />
          </div>
        </template>

        <md-list-item
          v-for="category in displayCategories"
          :key="category.id"
          class=".u-cursor-pointer"
          :md-disabled="true"
          :md-expanded="!category.id"
          md-expand
        >
          <span class="md-list-item-text">{{ category.label }}</span>

          <md-list slot="md-expand">
            <div class="c-table-wrapper c-table--online-websites c-config-column-content">
              <base-table-component
                :prop-data="formatPagesTableData(category)"
                :draggable="!!category.id && canWriteHelpWebsite(getSelectedWebsiteId)"
                @dragSort="handleDragSort($event, category.id)"
              >
                <template v-slot:default="slotProps">
                  <span class="c-table__btn-wrap">
                    <generic-button
                      icon
                      transparent
                      text-inherit
                      :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
                      @click="openDuplicateModal(slotProps.selected.page)"
                    >
                      <md-tooltip md-direction="top"> Duplicate </md-tooltip>
                      <img src="@/assets/duplicate.svg" alt="">
                    </generic-button>
                  </span>

                  <span class="c-table__btn-wrap">
                    <generic-button
                      icon
                      transparent
                      text-inherit
                      @click="handleEdit(slotProps.selected.page)"
                    >
                      <md-tooltip md-direction="top"> Edit </md-tooltip>
                      <img src="@/assets/edit-button.svg" alt="">
                    </generic-button>
                  </span>

                  <span class="c-table__btn-wrap">
                    <generic-button
                      icon
                      transparent
                      text-inherit
                      :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
                      @click="openDeleteModal(slotProps.selected)"
                    >
                      <md-tooltip md-direction="top"> Delete </md-tooltip>
                      <img src="@/assets/delete-button.svg" alt="">
                    </generic-button>
                  </span>
                </template>
              </base-table-component>
            </div>
          </md-list>
        </md-list-item>
      </md-list>
    </div>
    <div v-if="isCreteOrUpdatePage">
      <edit-create-help-page :selected-page="selectedPageOnEdit" @onBack="handleBack" @onSuccessCreateUpdate="onSuccessCreateUpdate" />
    </div>
    <template v-if="!getSelectedDomainId">
      <div class="l-empty-container u-full-height">
        <md-empty-state
          md-icon="announcement"
          md-label="You must create a domain first"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {helpService} from "@/services/help";
import GenericButton from "@/shared/buttons/GenericButton.vue";
import BaseTableComponent from "@/shared/table/BaseTableComponent.vue";
import EditCreateHelpPage from "./edit";
import {get} from 'lodash'
import DeleteModalComponent from "@/shared/delete-modal/DeleteModalComponent.vue";
import ConfirmationModalComponent from "@/shared/confirmation-modal/ConfirmationModalComponent.vue";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";

export default {
  name: "HelpPagesComponent",
  components: {
    BaseTableComponent,
    GenericButton,
    EditCreateHelpPage
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],

  data() {
    return {
      searchQuery: '',
      categories: null,
      searchExpanded: true,
      isCreteOrUpdatePage: false,
      selectedPageOnEdit: null
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['getSelectedDomainId', 'getSelectedWebsiteId']),

    displayCategories() {
      if (!this.searchQuery) {
        return this.categories || []
      }

      if (!this.categories) {
        return []
      }

      const categories = this.categories
        .flatMap(
          category => category.helpPageHelpCategory.flatMap(helpPageHelpCategory => helpPageHelpCategory.helpPage)
        )
        .filter(page => page.label.toLowerCase().includes(this.searchQuery.toLowerCase()))
        .map(data => ({
          helpPage: data,
          helpPageId: data.id
        }))

      if (!categories || !categories.length) {
        return [];
      }

      return [
        {
          label: 'Search Results',
          helpPageHelpCategory: categories,
        }
      ]
    },
  },

  watch: {
    getSelectedDomainId() {
      this.getCategories();
    }
  },

  async created() {
    this.deleteHelpPage = await this.historyLoggerDecorator(helpService.deleteHelpPage);

    await this.getCategories();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),

    async getCategories() {
      if (!this.getSelectedDomainId) {
        return;
      }

      const response = await helpService.getHelpCategories(this.getSelectedDomainId);
      const categories = get(response, 'data', []);

      this.categories = categories && categories.filter(category => category.helpPageHelpCategory.length);
    },

    async handleDragSort(data, categoryId) {
      try {
        data = data.map((pageData, index) => ({pageId: pageData.page.helpPageId, order: index + 1}));
        await helpService.reorderHelpPages(this.getSelectedDomainId, categoryId, data);
      } catch (err) {
        this.setGlobalError(err.data.message);
        await this.getCategories();
      }
    },

    async handleDuplicate(helpPageHelpCategory) {
      try {
        await helpService.duplicateHelpPage(this.getSelectedDomainId, helpPageHelpCategory.helpCategoryId, helpPageHelpCategory.helpPageId);
        this.handleSuccess({message: `Page "${helpPageHelpCategory.helpPage.label}" successfully duplicated`});
        await this.getCategories();
      } catch (err) {
        this.setGlobalError(err.data.message);
        await this.getCategories();
      }
    },

    handleEdit(page) {
      this.selectedPageOnEdit = page;
      this.setCreateOrEditPage();
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: selected.page.helpPage.label,
          propData: selected.page.helpPageId,
          deleteFunction: this.handleDelete,
        },
        {height: "auto", scrollable: false, width: "400px"}
      )
    },

    openDuplicateModal(helpPageHelpCategory) {
      this.$modal.show(
        ConfirmationModalComponent,
        {
          confirmationMsg: `Are you sure you want to duplicate page "${helpPageHelpCategory.helpPage.label}"?`,
          modalData: helpPageHelpCategory,
          onAccept: this.handleDuplicate,
          onClose: () => {},
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    async handleDelete(helpPageId) {
      try {
        await this.deleteHelpPage(this.getSelectedDomainId, helpPageId)
        await this.getCategories();
        this.handleSuccess({message: 'Page deleted'});
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    setCreateOrEditPage() {
      this.isCreteOrUpdatePage = true;
      this.$emit('disableSelectDomain');
    },

    formatPagesTableData(category) {
      const pages = [];
      category.helpPageHelpCategory.forEach(helpPageHelpCategory => {
        pages.push({
          page: helpPageHelpCategory,
          rowData: {
            'Name': helpPageHelpCategory.helpPage.label,
            'Active Languages': Object.keys(helpPageHelpCategory.helpPage.content)
            .filter(key => helpPageHelpCategory.helpPage.content[key])
            .join('/'),
          }
        });
      });

      return pages;
    },

    handleBack() {
      this.selectedPageOnEdit = null;
      this.isCreteOrUpdatePage = false;
      this.$emit('enableDomains');
    },

    onSuccessCreateUpdate() {
      this.getCategories();
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}

.l-empty-container {
  margin-bottom: 0;
}

.md-empty-state {
  max-width: 80% !important;
  justify-content: flex-start;
}

</style>
