<template>
  <div class="l-main">
    <md-tabs v-if="selectedTab" class="c-tabs c-tabs--small" :md-active-tab="selectedTab.key">
      <md-tab
        v-for="tab in bulkDataActionTabs"
        :id="tab.key"
        :key="tab.key"
        :md-label="tab.label"
        :md-disabled="tab.disabled"
        @click="changeActiveTab(tab)"
      />
    </md-tabs>

    <component
      :is="selectedTab.component"
      :website="website"
      :languages="languages"
      :default-language-id="defaultLanguageId"
    />
  </div>
</template>

<script>
import ImportSeoRulesComponent from './bulk-actions/import'
import ExportSeoRulesComponent from './bulk-actions/export'
import { languageService } from '@/services/language'
import { jsonParse } from '@/helpers/transformers'
import get from 'lodash/get'

export default {
  name: "BulkDataComponent",

  components: {
    ImportSeoRulesComponent,
    ExportSeoRulesComponent,
  },

  props: {
    website: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      languages: [],
      defaultLanguageId: null,
      selectedTab: { label: 'Import', component: 'ImportSeoRulesComponent', key: 'import-seo-rules-component' },
      bulkDataActionTabs: [
        { label: 'Export', component: 'ExportSeoRulesComponent', key: 'export-seo-rules-component' },
        { label: 'Import', component: 'ImportSeoRulesComponent', key: 'import-seo-rules-component' },
      ]
    };
  },

  created() {
    this.getAvailableLanguages();
  },

  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    async getAvailableLanguages() {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguages = jsonParse(get(this, 'website.betshop.market.additionalLanguages')) || [];
      const defaultLanguage = get(this, 'website.betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguage, ...additionalLanguages])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code))
      this.defaultLanguageId = this.languages.find(language => language.code === 'en')?.id
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
